// CardForm.js
import React, { useState } from 'react';
import {Mutation} from "react-apollo";
import gql from 'graphql-tag';
import {injectStripe} from 'react-stripe-elements';
import Loading from "./Loading";
import getProvinceData from "../data/province-data";
import {
  Form,
  Button,
  Row,
  Col,
  FormInput,
  FormSelect
} from "shards-react";

import { toast } from 'react-toastify';

import CardSection from './CardSection';
import _ from "underscore";

const ADD_CARD = gql`
  mutation addCard( $supporter: Selector, $token: String ){
    addCard(supporter: $supporter, token: $token) {
      id
      brand
    }
  }`;

const  CardForm = ({stripe, supporter, handleCloseCard, customerId }) => {

  const provinces = getProvinceData();
  const [ submitting, setSubmitting ] = useState(false);
  const [customerData, setCustomerData] = useState({
    name          : `${supporter.firstName} ${supporter.lastName}`,
    address_line1 : supporter.address1 ? supporter.address1 : undefined,
    address_line2 : supporter.address2 ? supporter.address2 : undefined,
    address_city  : supporter.city ? supporter.city : undefined,
    address_state : supporter.province ? supporter.province : undefined,
    address_zip   : supporter.postalCode ? supporter.postalCode : undefined
  });

  const getProvince = (value) => {
    return !!_.findWhere(provinces, {value}) && _.findWhere(provinces, {value}).label || "";
  };

  const setProvince = (value) => {
    return !!_.findWhere(provinces, {label : value}) && _.findWhere(provinces, {label : value}).value || "";
  };

    return(
      <Mutation
        mutation={ADD_CARD}
        onCompleted={({addCard}) => {
          toast.success(`Card created`);
          handleCloseCard();
          setSubmitting(false);
        }}
        onError={e => {

        for (const error of e.graphQLErrors) {
          toast.error(error.extensions.response.body.error.message);
        }
          setSubmitting(false);
        }}
        refetchQueries={['getSupporter']}
      >
      {(addCard, {data, loading, error}) => {
        if ( !!loading ) {
          {/* <Loading/> */}
            return (
              <Loading />
            )
        }
        return (
          <Form onSubmit={async(ev) => {
            ev.preventDefault();

            const token = await stripe.createToken(customerData);
            if (!!token.error) {
              toast.error(token.error.message)
            } else {
              //sets the customerId in the right field for the api
              supporter.customerId = !!supporter.customerInfo ? supporter.customerInfo.id : null
              setSubmitting(true);
              addCard({
              variables : {
                token : token.token.id,
                supporter
              },
              refetchQueries: ['getSupporter', 'getActions']
            });
            }

            }}>
            <Row form>
              <Col md="12" className="form-group">
                <label htmlFor="name">Name (required)</label>
                <FormInput
                  id="name"
                  type="text"
                  value={`${supporter.firstName} ${supporter.lastName}`}
                  readOnly
                />
              </Col>
            </Row>
            <Row>
              <Col md="12" className="form-group">
              <label htmlFor="name">Address (required)</label>
                <FormInput
                  id="address1"
                  type="text"
                  value={customerData.address_line1}
                  onChange={(e) => setCustomerData({...customerData, address_line1: e.target.value})}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12" className="form-group">
                <FormInput
                  id="adress2"
                  type="text"
                  value={customerData.address_line2 || ""}
                  placeholder="Address line 2 (Optional)"
                  onChange={(e) => setCustomerData({...customerData, address_line2 : e.target.value})}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4" className="form-group">
                <FormInput
                  id="city"
                  type="text"
                  value={customerData.address_city || ""}
                  placeholder="city"
                  onChange={(e) => setCustomerData({...customerData, address_city : e.target.value})}
                />
              </Col>
              <Col md="4" className="form-group">
              <FormSelect
                    id="province"
                    defaultValue={getProvince(customerData.address_state)}
                    onChange={(e) => setCustomerData({...customerData, address_state : setProvince(e.target.value)})}
                    >
                    <option></option>
                    <option>Alberta</option>
                    <option>British Columbia</option>
                    <option>Prince Edward Island</option>
                    <option>Manitoba</option>
                    <option>New Brunswick</option>
                    <option>Nova Scotia</option>
                    <option>Ontario</option>
                    <option>Quebec</option>
                    <option>Saskatchewan</option>
                    <option>Newfoundland and Labrador</option>
                    <option>Nunavut</option>
                    <option>Northwest Territories</option>
                    <option>Yukon</option>
                  </FormSelect>
              </Col>
              <Col md="4" className="form-group">
                <FormInput
                  id="postalCode"
                  type="text"
                  value={customerData.address_zip || ""}
                  placeholder="postalCode"
                  onChange={(e) => setCustomerData({...customerData, address_zip: e.target.value})}
                />
              </Col>
            </Row>
              <CardSection />
            <Button disabled={submitting}>Add Card</Button>
          </Form>
        );
      }}
    </Mutation>
  )
}

export default injectStripe(CardForm);
