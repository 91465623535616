import React, { Component } from "react";
import _ from 'underscore';
import {
  Container,
  Row,
  Card
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SupporterForm from "../friendsComponents/SupporterForm";


class CreateSupporter extends Component {

  constructor(props) {
    super(props)
    const defaults = {
      prefix               : '',
      firstName            : '',
      lastName             : '',
      address1             : '',
      address2             : '',
      city                 : '',
      province             : '',
      postalCode           : '',
      mobilePhone          : '',
      homePhone            : '',
      emails               : [{
        address         : '',
        suppressed      : false,
        verified        : false,
        isPrimary       : true,
        optInEn         : false,
        optInFr         : false,
        okNewletter     : false,
        okFund          : false
      }],
      language             : '',
      source               : '',
      "contactPreferences" : {
        "okEmail"            : true,
        "okCall"             : true,
        "okMail"             : true,
        "okEvents"           : true,
        "okSMS"              : true,
        "okTrade"            : true,
        "okSurveys"          : true,
        "okContact"          : true,
        },
      message : {

      },
      messageEmails : []
    };

    this.state = _.extend( defaults, props.supporter || {} );

  }

  onChange = ({input = null, field, error = ""}) => {
    //sets the value of the input

    this.setState({ [field] : input });

     //stores the error messages to display
     let message = this.state.message;
     message[field] = error;
     this.setState({message})
  }

  onChangePrimary = (i) => {
    //shallow copy of emails
    let emails = this.state.emails;
    let email = emails[i];

    !!email.isPrimary ? email.isPrimary = false : email.isPrimary = true;

    emails[i] = email;

    this.setState({ emails })
  }

  onChangeEmail = ({input, field, error, index}) => {

    //shallow copy of emails
    let emails = this.state.emails;
    //shallow copy of email and change property
    let email = {
      ...emails[index],
      [field] : input
    }
    //put the modified email back in the array
    emails[index] = email;
    this.setState({emails});

    //stores the error messages to display
    const messageEmails = this.state.messageEmails;
    messageEmails[index] = error;
    this.setState({messageEmails})
  }

  setEmails = (emails) => {
    this.setState({emails});
  }

  static testing() {
    console.log('testing');
  }

  onSubmit = () => {

  }

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Create Supporter" className="text-sm-left" />
        </Row>
        <Card small className="mb-4">
          <SupporterForm
            supporter={this.state}
            onChange={this.onChange}
            onChangePrimary={this.onChangePrimary}
            onChangeEmail={this.onChangeEmail}
            setEmails={this.setEmails}
            allowRedirect={true}
            setSupporterAfterCreate={ this.props.setSupporterAfterCreate || null }
            preventRedirect={ !!this.props.preventRedirect }
          />
        </Card>
      </Container>
    )
  }
};

export default CreateSupporter;
