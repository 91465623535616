import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Badge
} from "shards-react";
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Dispatcher, Constants } from "../../../flux";
import { Fade } from "react-bootstrap";

export const GET_INBOX_COUNT = gql`
query getInboxCount($status: String) {
  getInboxCount(status: $status) {
    status
    count
  }
}
`;

class SidebarNavItem extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown(item) {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR_DROPDOWN,
      payload: item
    });
  }

  render() {
    const { item } = this.props;
    const hasSubItems = item.items && item.items.length;


    //query for the inbox unread badge

    const badge = <Query
      query={GET_INBOX_COUNT}
      variables={{status: "unread"}}
      fetchPolicy="network-only"
      pollInterval={5 * 1000}
      >
      {({data,loading,error}) => {
        if(!!error) {
          console.log({error})
        }

        return(
          <Fade in={ !!data &&!!data.getInboxCount && data.getInboxCount.count > 0} >
          <Badge
            theme="danger"
            >
            {!!data && !!data.getInboxCount ? data.getInboxCount.count : "!" }</Badge>
          </Fade>
          
        )
      }}

    </Query>



    return (
      <NavItem style={{ position: "relative" }}>
        <NavLink
          className={hasSubItems && "dropdown-toggle"}
          tag={hasSubItems ? "a" : RouteNavLink}
          to={hasSubItems ? "#" : item.to}
          onClick={() => this.toggleDropdown(item)}
        >
          {item.htmlBefore && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlBefore }}
            />
          )}
          {item.title && <span>{item.title}</span>}
          {item.htmlAfter && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
            />
          )}
          {item.badgeInbox && (
            <div
              className="d-inline-block item-icon-wrapper float-right" 
            >{badge}</div>
          )}
        </NavLink>
        {hasSubItems && (
          <Collapse tag={DropdownMenu} small open={item.open} style={{ top: 0 }}>
            {item.items.map((subItem, idx) => (
              <DropdownItem key={idx} tag={RouteNavLink} to={subItem.to}>
                {subItem.title}
              </DropdownItem>
            ))}
          </Collapse>
        )}
      </NavItem>
    );
  }
}

SidebarNavItem.propTypes = {
  /**
   * The item object.
   */
  item: PropTypes.object
};

export default SidebarNavItem;
