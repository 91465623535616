import React from "react";
import {Mutation} from "react-apollo";
import EmailForm from "./EmailForm";
import Loading from './Loading';
import SupporterContactTags from './SupporterContactTags';
import { toast } from 'react-toastify';
import validator from "validator";
import postcode from "postcode-validator";
import { Redirect } from "react-router-dom";
import gql from 'graphql-tag';
import _ from "underscore";
import "./SupporterForm.scss";
import {
  Row,
  Col,
  Button,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormFeedback
} from "shards-react";
import CanadaPostAddress from './CanadaPostAddress';

const CREATE_SUPPORTER = gql`
  mutation createSupporter( $supporter: GenericObject ){
    createSupporter( supporter: $supporter ) {
     _id
    }
  }`


const removeSpaces = (input) => {
  return input.split("").map( str => str.replace(/\s/g, '')).join('');
}



class SupporterForm extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.supporter,
      redirect : false,
      url : ""
    };
  }

  setEmails = (emails) => {
    this.setState({emails});
  }


  // this toggles the noCall, noSMS ... values
  onToggle = (field) => {
    const allPrefs  = this.state.contactPreferences;
    //switch 'em
    allPrefs[field] = !allPrefs[field];
    this.setState({'contactPreferences': allPrefs});
  };

  setMergeViewClass(fieldName) {
    let baseClass = !this.props.disableOverride && 'm-2 ml-0 p-2' || '';
    let fieldSelected = this.props.selections && this.props.selections[fieldName] == this.props.supporter._id;
    let hideField     = !!this.props.fields && !this.props.fields.includes(fieldName);
    let fieldsWeAlwaysShow = ['firstName', 'lastName', 'PIN'];

    //little hack for consolidated address field. If it's one of these, just call it address
    let addressFields = ['address1', 'address2', 'province', 'city', 'postalCode'];
    let disableField = false;
    if ( fieldName === 'address' && !!_.intersection( addressFields, this.props.fields ).length ) {
      hideField = false;
    }


    if ( !!hideField && fieldsWeAlwaysShow.includes(fieldName) ) {
      disableField = true;
      hideField = false;
    }

    if ( !!hideField ) {
      baseClass += ' d-none';
    }


    if ( !!fieldSelected && !disableField ) {
      baseClass += ' merge-selected';
    } else if (!!disableField && !this.props.disableOverride) {
      baseClass += ' disable merge-unselected';
    } else {
      baseClass += ' merge-unselected';
    }

    return baseClass;
  }

  render() {

    if(!!this.state.redirect) {
      return <Redirect to={this.state.url} />
    }

    return (

      <Form className={!!this.props.mergeView && "supporter-form merge" || ""}>
        <CardBody className="p-0">
          {/* Supporter Data */}
          <div className="user-details__user-data border-bottom p-4">
            <Row form>
              <Col md="2"
                className={!!this.props.merge && this.setMergeViewClass('prefix') || "p-2 merge-unselected"}
                onClick={() => {
                  if(!this.props.onSelectField) {
                    return;
                  }
                  if ( !!this.props.fields && !this.props.fields.includes('prefix') ) {
                    return;
                  }

                  this.props.onSelectField("prefix", this.props.supporter.prefix, this.props.supporter._id);
                }
              }>
                <FormGroup>
                  <label>Prefix</label>
                  <FormInput
                    id="first Name"
                    type="text"
                    value={this.props.supporter.prefix || ""}
                    onChange={e => {
                      if ( !!this.props.mergeView && !!this.props.disableOverride ) {
                        this.props.onSelectField("prefix", e.target.value, this.props.supporter._id)
                      } else {
                        this.props.onChange({input : e.target.value, field : "prefix"})
                      }

                    }}
                    disabled={!!this.props.mergeView && !this.props.disableOverride}
                  />
                </FormGroup>
              </Col>
              <Col 
                className={!!this.props.merge && this.setMergeViewClass('firstName') || "p-2 merge-unselected"}
                onClick={() => {
                  if(!this.props.onSelectField) {
                    return;
                  }
                  if ( !!this.props.fields && !this.props.fields.includes('firstName') ) {
                    return;
                  }

                  this.props.onSelectField("firstName", this.props.supporter.firstName, this.props.supporter._id);
                }
              }>
                <FormGroup>
                  <label>First Name</label>
                  <FormInput
                    id="first Name"
                    type="text"
                    value={this.props.supporter.firstName || ""}
                    onChange={e => {
                      if ( !!this.props.mergeView && !!this.props.disableOverride ) {
                        this.props.onSelectField("firstName", e.target.value, this.props.supporter._id)
                      } else {
                        this.props.onChange({input : e.target.value, field : "firstName"})
                      }

                    }}
                    disabled={!!this.props.mergeView && !this.props.disableOverride}
                  />
                </FormGroup>
              </Col>
              <Col
                className={!!this.props.merge && this.setMergeViewClass('lastName') || "p-2 merge-unselected"}
                onClick={() => {
                  if(!this.props.onSelectField) {
                    return;
                  }
                  if ( !!this.props.fields && !this.props.fields.includes('lastName') ) {
                    return;
                  }
                  this.props.onSelectField("lastName", this.props.supporter.lastName, this.props.supporter._id)}
                }
              >
                <FormGroup>
                  <label>Last Name</label>
                  <FormInput
                      id="lastName"
                      type="text"
                      value={ !!this.props.supporter && this.props.supporter.lastName || ''}
                      onChange={e => {
                        if ( !!this.props.mergeView && !!this.props.disableOverride ) {
                          this.props.onSelectField("lastName", e.target.value, this.props.supporter._id)
                        } else {
                          this.props.onChange({input : e.target.value, field : "lastName"})
                        }
                      }}
                      disabled={!!this.props.mergeView && !this.props.disableOverride}
                    />
                </FormGroup>
              </Col>
            </Row>

            {!!this.props.mergeView ?
              <Row form>
                <Col md="6"
                  className={!!this.props.merge && this.setMergeViewClass('customerId') || "p-2 merge-unselected"}
                  onClick={() => {
                    if(!this.props.onSelectField) {
                      return;
                    }

                    if ( !!this.props.fields && !this.props.fields.includes('customerId') ) {
                      return;
                    }
                    this.props.onSelectField("customerId", this.props.supporter.customerId, this.props.supporter._id);
                  }
                }>
                  <FormGroup>
                  <label>Stripe Customer ID</label>
                    <FormInput
                      id="customerId"
                      type="text"
                      value={this.props.supporter.customerId || ""}
                      onChange={e => {
                        this.props.onChange({input : e.target.value, field : "customerId"})
                      }}
                      disabled={!!this.props.mergeView}
                    />
                  </FormGroup>
                </Col>
                <Col
                  className={this.props.merge && this.setMergeViewClass('PIN') || "p-2 merge-unselected"}
                  onClick={() => {
                    if(!this.props.onSelectField) {
                      return;
                    }
                    if ( !!this.props.fields && !this.props.fields.includes('PIN') ) {
                      return;
                    }
                    this.props.onSelectField("PIN", this.props.supporter.PIN, this.props.supporter._id);
                  }
                }>
                  <FormGroup >
                    <label>PIN</label>
                    <FormInput
                      id="PIN"
                      type="text"
                      value={ !!this.props.supporter && this.props.supporter.PIN || ''}
                      onChange={e => {
                        if ( !!this.props.mergeView && !!this.props.disableOverride ) {
                          this.props.onSelectField("PIN", e.target.value, this.props.supporter._id)
                        } else {
                          this.props.onChange({input : e.target.value, field : "PIN"})
                        }
                      }}
                      disabled={!!this.props.mergeView && !this.props.disableOverride}
                    />
                  </FormGroup>
                </Col>
              </Row>
              : ""
              }
              <div
                className={!!this.props.merge && !!this.props.mergeView && this.setMergeViewClass('emails') || "p-2 merge-unselected"}
                onClick={() => {
                  if(!this.props.onSelectField) {
                    return;
                  }
                  if ( !!this.props.fields && !this.props.fields.includes('emails') ) {
                    return;
                  }
                  this.props.onSelectField("emails", this.props.supporter.emails, this.props.supporter._id)}} >

                  <EmailForm
                    emails={this.props.supporter.emails}
                    onChangeEmail={this.props.onChangeEmail}
                    setEmails={this.props.setEmails}
                    mergeView={!!this.props.mergeView}
                  />
              </div>

                  <Row className="mb-3">
                    <Col
                      className={!!this.props.merge && this.setMergeViewClass('mobilePhone') || "p-2 merge-unselected"}
                      onClick={() => {
                        if(!this.props.onSelectField) {
                          return;
                        }
                        if ( !!this.props.fields && !this.props.fields.includes('mobilePhone') ) {
                          return;
                        }
                        this.props.onSelectField("mobilePhone", this.props.supporter.mobilePhone, this.props.supporter._id)}}>
                      <FormGroup>
                        <label>Mobile Phone</label>
                        <FormInput
                          id="mobilePhone"
                          type="text"
                          value={this.props.supporter.mobilePhone || ""}
                          placeholder="Mobile Phone"
                          disabled={!!this.props.mergeView && !this.props.disableOverride}
                          onChange={e => {
                            const input = removeSpaces(e.target.value)
                            if ( !!this.props.mergeView && !!this.props.disableOverride ) {
                                this.props.onSelectField("mobilePhone", e.target.value, this.props.supporter._id)
                            } else {
                              this.props.onChange({
                                input,
                                field : "mobilePhone",
                                error : ""
                              })
                            }
                          }}
                          invalid={!!this.props.supporter.message && !!this.props.supporter.message.mobilePhone}
                        />
                        <FormFeedback>{!!this.props.supporter.message && this.props.supporter.message.mobilePhone}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col
                      className={this.props.selections && this.setMergeViewClass('homePhone') || "p-2 merge-unselected"}
                      onClick={() => {
                        if(!this.props.onSelectField) {
                          return;
                        }
                        if ( !!this.props.fields && !this.props.fields.includes('homePhone') ) {
                          return;
                        }
                        this.props.onSelectField("homePhone", this.props.supporter.homePhone, this.props.supporter._id)}} >
                      <FormGroup>
                        <label>Home Phone</label>
                        <FormInput
                          id="homePhone"
                          type="text"
                          value={!!this.props.supporter && this.props.supporter.homePhone || ''}
                          placeholder="Home Phone"
                          disabled={!!this.props.mergeView && !this.props.disableOverride}
                          onChange={e => {
                            
                            const input = removeSpaces( e.target.value );
                            console.log(input, this.props.mergeView,this.props.disableOverride, this.props.onChange)
                            if ( !!this.props.mergeView && !this.props.disableOverride ) {
                              this.props.onSelectField("homePhone", e.target.value, this.props.supporter._id)
                            } else {
                              console.log(this.props.onChange)
                              this.props.onChange({
                                input,
                                field : "homePhone",
                                error : ""
                              })
                            }
                          }}
                          invalid={!!this.props.supporter.message && !!this.props.supporter.message.homePhone}
                        />
                        <FormFeedback>{!!this.props.supporter.message && this.props.supporter.message.homePhone}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col
                      className={!!this.props.merge && this.setMergeViewClass('language') || "p-2 merge-unselected"}
                      onClick={() => {

                        if(!this.props.onSelectField) {
                          return;
                        }
                        if ( !!this.props.fields && !this.props.fields.includes('Language') ) {
                          return;
                        }

                        this.props.onSelectField("language", this.props.supporter.language, this.props.supporter._id)}} >
                    <FormGroup>
                      <label>Language</label>
                      <FormSelect
                        id="language"
                        disabled={!!this.props.mergeView && !this.props.disableOverride}
                        defaultValue={!!this.props.supporter && this.props.supporter.language || 'English'}
                        onChange={e => {
                          if ( !!this.props.mergeView && !!this.props.disableOverride ) {
                            this.props.onSelectField("language", e.target.value, this.props.supporter._id)
                          } else {
                            this.props.onChange({input : e.target.value, field : "language"})
                          }
                        }}
                        >
                        <option>English</option>
                        <option>French</option>
                      </FormSelect>
                    </FormGroup>
                    </Col>
                  </Row>
                    <div
                      className={!!this.props.selections && this.setMergeViewClass('address') || "p-2 merge-unselected"}

                      >
                  <CanadaPostAddress
                    supporter={this.props.supporter}
                    selections={this.props.selections}
                    errorMessage={this.state.errorMessages}
                    onChange={this.props.onChange}
                    supporterId={ this.props.supporterId }
                    onSelectField={ this.props.onSelectField }
                    fields = { this.props.fields }
                    mergeView={!!this.props.mergeView}
                    disableOverride={ !!this.props.disableOverride }

                  />
                  </div>

                </div>
                {/* User Tags */}

                <div
                  className={!!this.props.merge &&  !!this.props.mergeView && this.setMergeViewClass('contactPreferences') || "p-2 merge-unselected"}
                      onClick={() => {

                        if(!this.props.onSelectField) {
                          return;
                        }
                        if ( !!this.props.fields && !this.props.fields.includes('contactPreferences') ) {
                          return;
                        }
                        this.props.onSelectField("contactPreferences", this.props.supporter.contactPreferences, this.props.supporter._id)}} >
                  <SupporterContactTags
                      tags={this.props.supporter.contactPreferences}
                      edit={!this.props.mergeView && true}
                      onToggle={this.onToggle}
                      mergeView={!!this.props.mergeView}
                  />
                </div>

              </CardBody>
              <CardFooter>

                <Mutation
                  mutation={CREATE_SUPPORTER}
                  onCompleted={({createSupporter}) => {
                    toast.success(`Supporter created!`);
                    if ( !this.props.preventRedirect ) {
                      this.setState({
                        redirect : true,
                        url : `/supporter/${createSupporter._id}`
                      });
                    }

                    if ( !!this.props.setSupporterAfterCreate  ) {
                      this.props.setSupporterAfterCreate( createSupporter );
                    }
                  }}>
                  {(createSupporter, {loading}) => {
                    if (loading) return(
                      <Loading />
                    );
                    return (
                      //if we render the component under mergeView, hide the button
                      !this.props.mergeView ?
                      <Button
                      theme="primary"
                      className ="mx-2"
                      onClick={() => {
                        console.log({mod : this.props.supporter})
                        let errorMessages = 0;
                        // counts the number of errors,
                        for( const field in this.props.supporter.message) {
                          if (this.props.supporter.message[field].length > 0) errorMessages += 1;
                        }

                        if (errorMessages) {
                          toast.warning(`Please correct the ${errorMessages} incorrect field(s)`)
                        } else {

                          //removes the unecessary fields for the customer object
                          const modifier = _.omit(this.props.supporter, ["message", "messageEmails"]);

                          /** if fields are empty, replace them by null in order
                          to facilitate search after */
                          for( const field in modifier){
                            if(modifier[field] !== null) {
                              if(modifier[field].length === 0) {

                                modifier[field] = null
                              }
                            }
                          }
                          
                          //sanitize email if the one present is an empty string
                          if(!!modifier.emails && !!modifier.emails.length && modifier.emails[0].address === "") {
                            modifier.emails = [];
                          }
                          
                          createSupporter({
                            variables : {
                              supporter : modifier 
                            }
                          })

                        }
                      }}
                    >Create Supporter</Button>
                    : ""

                    )
                  }}


                </Mutation>

                {!this.props.mergeView ?
                  <Button
                  outline
                  theme="danger"
                  onClick={() => {
                    toast.warn("Modifications cancelled");
                  }}>
                  Cancel
                </Button>
                : ""}
              </CardFooter>
            </Form>
          );
  }
}



export default SupporterForm;
