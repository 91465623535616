import React from 'react';
import Tagify from '@yaireo/tagify';

import getRidingData from "../data/riding-data";

// setup some basic Tagify settings object
class Tags extends React.Component{
    constructor( props ){
        super(props);
        this._handleRef = this._handleRef.bind(this);
    }

    componentDidMount(){
        this.tagify = new Tagify(this.component, this.props.settings || {});
    }

    shouldComponentUpdate(nextProps, nextState){
        // check if value has changed
        if( nextProps.value && nextProps.value.join() != this.props.value.join() ){
            this.tagify.loadOriginalValues(nextProps.value);
            // this.tagify.addTags(nextProps.value, true, true)
        }

        this.tagify.settings.whitelist = nextProps.settings.whitelist;

        if( nextProps.showDropdown )
            this.tagify.dropdown.show.call(this.tagify, nextProps.showDropdown);

        // do not allow react to re-render since the component is modifying its own HTML
        return false;
    }

    _handleRef(component){
        this.component = component;
    }

    render(){
        const attrs = {
            ref         : this._handleRef,
            name        : this.props.name,
            className   : this.props.className,
            placeholder : this.props.class,
            autoFocus   : this.props.autofocus
        }

        return React.createElement(this.props.mode, Object.assign({}, attrs, {defaultValue: this.props.value || null }));
    }
}

Tags.defaultProps = {
    value: [],
    mode: "input"
}

// Demo "App" component that is using the Tagify React component (<Tags/>)
class RidingTagSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      whitelist        : Array.from( getRidingData().keys() ),
      enforceWhitelist : true,
      transformTag     : this.ridingNameToCode,
      callbacks        : {
        add: (tag) => {
          const value = this.ridingNameToCode(tag.detail.data.value);
          if ( !!value ) {
            this.props.addRidingCode( value );
          }
        },
        remove: (tag) => {
          const value = this.ridingNameToCode(tag.detail.data.value);
          if ( !!value ) {
            this.props.removeRidingCode( value );
          }
        }
      }
    }
  }

  ridingCodesToName( codes ) {
    let names = []

    Array.from( getRidingData().entries() ).forEach( function ([ key, val ]) {
      // console.log(key, val);
      if ( codes.includes(val) ) {
        names.push( key );
      }
    });


    return names;
  }

  ridingNameToCode(val) {
    const ridingMap = getRidingData();
    return ridingMap.get(val);
  }

  componentDidMount() {}

  // Render <Textarea> element and applies Tagify on it
  render() {
    return (
      <Tags
        autofocus={true}
        mode='textarea'
        className='form-control'
        name='riding'
        settings={this.state}
        placeholder={'Select one or more ridings'}
        value={ this.ridingCodesToName(this.props.value) }
      />
    )
  }
}

export default RidingTagSelect;
