import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import validator from "validator";
import { Mutation } from "react-apollo";
import ResetPassword from "../friendsComponents/ResetPassword";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormCheckbox,
  Button,
  ButtonGroup
} from "shards-react";

import FormSectionTitle from "../components/edit-user-profile/FormSectionTitle";
import { without, isEmpty } from "underscore";
import { toast } from "react-toastify";
import gql from 'graphql-tag';

export const UPDATE_USER = gql`
  mutation updateUser($_id: ID, $modifier: Modifier) {
    updateUser(_id: $_id, modifier: $modifier){
      n
      nModified
      ok
    }
  }
`;

const services = [
  {
    name         : "SMS",
    officialName : 'sms',
    admin  : [
      "View message blasts",
      "Send message blasts"
    ],
    basic: [
      "Read and send individual SMS",
      "View inbox"
    ],
    superAdmin: [
      'Same as admin'
    ]
  },
  {
    name         : "Payments",
    officialName : 'payment',
    admin  : [
      "Can cancel payments"
    ],
    basic: [
      "-"
    ],
    caller: [
      "Can makes payments, add cards",
      "Create subscriptions"
    ],
    superAdmin: [
      'Can access and create Bank Runs'
    ]
  },
  {
    name         : "Supporters",
    officialName : 'supporter',
    admin  : [
      "View message blasts",
      "Send message blasts"
    ],
    caller: [
      "Read and send individual SMS",
      "View inbox"
    ],
    basic: [
      "Read and send individual SMS",
      "View inbox"
    ],
    superAdmin: [
      'Same as admin'
    ]
  },
  {
    name         : "Campaigns",
    officialName : 'campaign',
    admin  : [
      "Update Campaign"
    ],
    caller: [
      "Can view campaigns",
      "Can enter donations in the campaign form"
    ],
    basic: [
      "Can view campaigns",
    ],
    superAdmin: [
      'Create Campaign'
    ]
  },
  {
    name         : "Emails",
    officialName : 'email',
    admin  : [],
    basic: [
      "validate emails"
    ],
    superAdmin: [
      'send emails'
    ]
  }
]

const UserAccountDetailsForm = ({ user, createUser }) => {

  const userInStorage = JSON.parse(localStorage.getItem('user'));
  const [newUser, setNewUser] = useState(user || {
    firstName   : "",
    lastName    : "",
    password    : "",
    gatewayRole : "basic",
    emails      : [
    {address    : ""}
    ],
    roles       : {
    sms         : [],
    payment     : [],
    supporter   : [],
    prospect    : []
    }
  });

  const [modifier, setModifier] = useState({});

  const updateField = ({field, e}) => {
    //if the user is not a gateway super-admin, and a different user they should not be able to update
    if(!!user && userInStorage._id !== user._id && userInStorage.gatewayRole !== "super-admin") {
      return;
    }

    if(!!user) {
      setModifier({... modifier, [field] : e.target.value})
    }

    setNewUser({...newUser, [field] : e.target.value});
  };

  const updateAdmin = ({e}) => {
    if (userInStorage.gatewayRole !== "super-admin") {
      return;
    }
    if(!!user) {
      setModifier({... modifier, gatewayRole : e.target.value})
    }
    setNewUser({...newUser, gatewayRole : e.target.value});
  }

  const updateEmail = ({index, field, e}) => {
    //if the user is not a gateway super-admin, and a different user they should not be able to update
    if(!!user && userInStorage._id !== user._id && userInStorage.gatewayRole !== "super-admin") {
      return;
    }
    let emails = newUser.emails;
    emails[index][field] = e.target.value;
    if(!!user) {
      setModifier({... modifier, emails})
    }
    setNewUser({...newUser, emails});
  };

  const updateRoles = ({service, role, actualValue}) => {
    //if the user is not a gateway super-admin, they should not be able to update roles
    if(userInStorage.gatewayRole !== "super-admin") {
      return;
    }
    let roles = newUser.roles;

    if(!roles[service]) {
      roles[service] = []
    }

    if(!actualValue ) {
      roles[service] = [...roles[service], role];
    } else {
      roles[service] = without(roles[service], role)
    };

    if(!!user) {
      setModifier({... modifier, roles})
    }
    setNewUser({...newUser, roles});
  };

  const updateAccount = !!user && !createUser ?
    <Mutation
      mutation={UPDATE_USER}
      onError={(e) => {
        console.log({e})
      }}
      onCompleted={() => {
        toast.success("User updated!");
      }}
      >
      {(updateUser, {data, loading, error}) => {
        return (
          <Fragment>
            <Button
              theme="accent"
              onClick={() => {

                //if the user is not a gateway super-admin, and a different user they should not be able to update
                if(userInStorage._id !== user._id && userInStorage.gatewayRole !== "super-admin") {
                  return toast.warning("You don't have the rights to update this user")
                }
                updateUser({
                  variables : {
                  _id       : user._id,
                  modifier
                  }
                })
              }}
              >Update Account
            </Button>
            <ResetPassword
              user={user}
              userInStorage={userInStorage}
            />

          </Fragment>
        )
      }}

    </Mutation>
    : "";

  const passwordField = !!user ?
    ""
  : <Col md="6" className="form-group">
      <label htmlFor="fePassword">Password</label>
      <FormInput
        type="password"
        id="fePassword"
        placeholder="Password"
        value={newUser.password || '' }
        onChange={(e) => {updateField({field : "password", e})}}
      />
    </Col>


  //shows the create account button in case the component is used for creating users
  const createAccount = !!createUser && !user ?
    <Button
      theme="accent"
      onClick={() => {

        let missingfields = "";

        for(const field in newUser) {
          if (isEmpty(newUser[field])) {
            missingfields += field + " ";
          };
        };
        if(!isEmpty(missingfields)) {
          toast.warning(`${missingfields} are missing to create the user`)
        } else if(!validator.isEmail(newUser.emails[0].address)){
          toast.warning("You need to enter en email address for this user")
        }
        else {
          createUser({variables : {
            user : newUser
          }})
        }

      }}>Create Account</Button>
    : "";

  return (
    <Card small className="mb-4">
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  {/* First Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feFirstName">First Name</label>
                    <FormInput
                      id="feFirstName"
                      placeholder="First Name"
                      value={newUser.firstName ||  '' }
                      onChange={(e) => {updateField({field : "firstName", e})}}
                    />
                  </Col>
                  {/* Last Name */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feLastName">Last Name</label>
                    <FormInput
                      id="feLastName"
                      placeholder="Last Name"
                      value={newUser.lastName || ''}
                      onChange={(e) => {updateField({field : "lastName", e})}}
                    />
                  </Col>
                </Row>
                <Row form>
                  {/* Email */}
                  <Col md="6" className="form-group">
                    <label htmlFor="feEmail">Email</label>
                    <FormInput
                      type="email"
                      id="feEmail"
                      placeholder="Email Address"
                      value={newUser.emails[0].address || '' }
                      onChange={(e) => {updateEmail({index : 0, field : "address", e})}}
                    />
                  </Col>
                  {passwordField}
                </Row>
                {updateAccount}

              </Form>
            </Col>
          </Row>

          <hr />

          {/* Form Section Title :: Notifications */}
          <Row form className="">
            <Col className="mb-3">
              <h6 className="form-text m-0">Permissions</h6>
              <p className="form-text text-muted m-0">Which services should this user have access to?</p>
            </Col>
          </Row>
          <Row form className="">
            <Col md="4" className="mb-3">
              <label>Admin role / Gateway</label>
              <FormSelect
                id="language"
                defaultValue={newUser.gatewayRole || ""}
                onChange={e => {updateAdmin({e})}}
                >
                <option>basic</option>
                <option>admin</option>
                <option>super-admin</option>
              </FormSelect>
            </Col>
          </Row>

          {/* Notifications :: Conversations */}
          { services.map( s => {
            return <ServicePermissionRow
              service={s}
              roles={newUser.roles[s.officialName]}
              key={s.name}
              updateRoles={updateRoles} />
          }) }
        </ListGroupItem>
      </ListGroup>
      <Row form className="p-3">
        <Col className="mb-3">
          {createAccount}
        </Col>
      </Row>
    </Card>
  )
};


const ServicePermissionRow = ({ service, roles, updateRoles }) => {

  const displayCaller = !!service.caller ?
    <Col
    className="col-form-label d-flex flex-row align-items-center align-content-around justify-content-around"
     >
    <ServicePermissionDescriptionList title='Caller' items={service.caller}/>
    <FormGroup className='align-self-center mb-0'>
      <FormCheckbox
        toggle
        checked={!!roles && roles.includes('caller')}
        className="ml-auto my-auto"
        id="conversationsEmailsToggle"
        onChange={() => {updateRoles({service : service.officialName, role : 'caller', actualValue : !!roles && roles.includes('caller')  })}}
      />
    </FormGroup>
  </Col> :
  <Col
  className="col-form-label d-flex flex-row align-items-center align-content-around justify-content-around"
   >
   </Col>;

  return (
    <div>
    <Row form className='pt-3'>
      <Col>
        {service.name}
      </Col>
    </Row>
    <Row form className='pb-3'>
      {displayCaller}
      <Col
        className="col-form-label d-flex flex-row align-items-center align-content-around justify-content-around"
      >
        <ServicePermissionDescriptionList title='Basic' items={service.basic}/>
        <FormGroup className='align-self-center mb-0'>
          <FormCheckbox
            toggle
            checked={!!roles && roles.includes('basic')}
            className="ml-auto my-auto"
            id="conversationsEmailsToggle"
            onChange={() => {updateRoles({service : service.officialName, role : 'basic', actualValue : !!roles && roles.includes('basic')  })}}
          />
        </FormGroup>
      </Col>
      <Col
        className="col-form-label d-flex flex-row align-items-center align-content-around justify-content-around"
      >
        <ServicePermissionDescriptionList title='Admin' items={service.admin}/>
        <FormGroup className='align-self-center mb-0'>
          <FormCheckbox
            toggle
            checked={!!roles && roles.includes('admin')}
            className="ml-auto my-auto"
            id="conversationsEmailsToggle"
            onChange={() => {updateRoles({service : service.officialName, role : 'admin', actualValue : !!roles && roles.includes('admin')  })}}
          />
        </FormGroup>
      </Col>
      <Col
        className="col-form-label d-flex flex-row align-items-center align-content-around justify-content-around"
      >
        <ServicePermissionDescriptionList title='Super Admin' items={service.superAdmin}/>
        <FormGroup className='align-self-center mb-0'>
          <FormCheckbox
            toggle
            checked={!!roles && roles.includes('super-admin')}
            className="ml-auto my-auto"
            id="conversationsEmailsToggle"
            onChange={() => {updateRoles({service : service.officialName, role : 'super-admin', actualValue : !!roles && roles.includes('super-admin')  })}}
          />
        </FormGroup>
      </Col>
    </Row>
  </div>
  )
}


const ServicePermissionDescriptionList = ({ title, items }) => (
  <small className="text-muted form-text">
    <strong>{title}</strong>
    <ul className='pl-1'>
      { items.map( i => <li key={i.split(' ').join() }>{i}</li> ) }
    </ul>
  </small>
)

UserAccountDetailsForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetailsForm.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetailsForm;
