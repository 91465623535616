import React from "react";
import { Container, Row, Col } from "shards-react";
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import _ from 'underscore';

import Loading from "../friendsComponents/Loading";
import PageTitle from "../components/common/PageTitle";
import SetPageMetas from '../utils/SetPageMetas';


import UserDetailsForm from "../components/user-profile-lite/UserDetails";
import UserAccountDetailsForm from "../views/UserAccountDetailsForm";
export const GET_USER = gql`
  query getSupporter($_id: ID) {
    getUser(_id: $_id) {
      _id
      firstName
      lastName
      emails {
        _id
        address
        isPrimary
      }
      roles
      gatewayRole
    }
  }
`;


const UserDetails = ({match, location, history}) => {
  const userId = match.params.id;

  return (
    <Query
      query={GET_USER}
      variables={{_id: userId}}
      onError={(e) => {
        console.log(e);
      }}
      >
      {({data, loading, error}) => {
        if (loading) {
          return <Loading />
        }

        if (error) return <p>ERROR: {error.message}</p>;
        const user = data.getUser;

        SetPageMetas({title: `User: ${user.firstName} ${user.lastName}`})

        return (
          <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={ `${user.firstName} ${user.lastName}` } subtitle="User" md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            <Col >
              <UserAccountDetailsForm
                user={user}
              />
            </Col>
          </Row>
        </Container>
      )
     }}

    </Query>
  )
  return null;
}

export default UserDetails;
