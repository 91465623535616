// CardSection.js
import React from 'react';
import {CardNumberElement, CardExpiryElement} from 'react-stripe-elements';

import {
  Row,
  Col
} from "shards-react";

class CardSection extends React.Component {
  render() {
    return (
      <Row>
        <Col md="8" className="form-group">
          <label>Card details</label>
          <CardNumberElement className="form-control" style={{base: {fontSize: '18px'}}} />
        </Col>
        <Col md="4" className="form-group">
          <label>Expiry</label>
          <CardExpiryElement className="form-control" style={{base: {fontSize: '18px'}}} />
        </Col>
      </Row>
    );
  }
}

export default CardSection;