import React, {useState, Fragment} from "react";
import {Modal} from "react-bootstrap";
import { toast } from 'react-toastify';
import {
  Button,
  FormCheckbox,
  FormTextarea
} from "shards-react";



const CommentAction = ({ householdMembers = null, showModal, setShowModal, cancelAction, mutation, opts, action, loadingToast, loadingMessage, onHide, confirmation = false }) => {

  const [comment, setComment] = useState('')
  const [updateHousehold, setUpdateHousehold] = useState(true);

  const handleClose = () => {
    setShowModal(false);
  };
  const hide = () => {
    handleClose()
    if(!!onHide) {
      onHide()
    }
  }
  return (
    <Modal
    size="md"
    // centered
    show={showModal}
    onHide={hide}>
      <Modal.Header 
        closeButton>
        <Modal.Title>{!!householdMembers && `Attention before you update and comment` || `Add a comment and ${action}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {
        !!householdMembers && <Fragment>
          <h6> This supporter is part of a household: </h6>
          <FormCheckbox
            checked={!!updateHousehold}
            onChange = {() => { setUpdateHousehold(!updateHousehold)}}>
            update other supporters
          </FormCheckbox>
          <p>By selecting this checkbox, the modified address / home phone will be reflected to all the members of the household.</p>
          </Fragment>
        || ""
      }
      <h6> You can add a comment to this action (optional)</h6>
      
      <FormTextarea
        className="mb-1"
        value={comment}
        onChange={(e) => {setComment(e.target.value)}}/>
      <div className="my-1 d-flex justify-content-between">
        <Button
          onClick={() => {

            if(!!confirmation) {
              const conf = window.confirm(`Are you sure you want to ${action}?`);

              if ( !conf )  {
                if(!!cancelAction) {
                  cancelAction();
                }
              return toast.warning(`${action} canceled`);
              }
            }

            if(!!loadingToast && !!loadingMessage){
              loadingToast(loadingMessage);
            }
            
            if(!!updateHousehold) {
              opts.variables.householdMembers = householdMembers;
            }

            if(!!comment.length) {
              if(!!opts.variables) {
                opts.variables.opts.comment = comment;
              }else {
                opts.opts.comment = comment;
              }      
            };
            mutation(opts);
            handleClose();
          }}
          >Continue
        </Button>
        <Button
          outline
          theme="danger"
          onClick={() => {
            if(!!cancelAction) {
                  cancelAction();
                }
            toast.warning(`${action} canceled`)
            handleClose()}}
          >Cancel
        </Button>
      </div>
      </Modal.Body>
      </Modal>
  )

}

export default CommentAction;
