/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, {useState} from 'react';
import Loading from "../friendsComponents/Loading";
import { Mutation, ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { Store } from "../flux";

// import { Form } from "react-bootstrap";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardBody,
  FormGroup,
  FormInput,
  Button
} from "shards-react";

const LOGIN_USER = gql`
mutation login( $email: String!, $password: String! ){
  login(
    email: $email,
    password: $password
  ){
    _id
    firstName
    lastName
    emails {
      address
    }
    token
    timeStamp
    roles
    gatewayRole
  }
}`;


const Login = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [redirect, setRedirect] = useState(false)

  return (
    <ApolloConsumer>
      {client => (
          <Mutation
            mutation={LOGIN_USER}
            onError={e => {
              console.log(e);
              return toast.error(e.message || "Error logging in");
            }}
            onCompleted={({ login }) => {
              // store the user in the local Storage
              localStorage.setItem('user', JSON.stringify(login));
              //change the local variable isLoggedIn to true
              client.writeData({ data: { isLoggedIn: true, currentUser: JSON.stringify(login) } });

              // set the redirect value to true in order to trigger the redirect once the user is logged
              setRedirect(true);
            }}
        >
        {(login, { data, loading }) => {
          //if the redirect variable has been set to true, triggers the redirect
          if( !!redirect ) {
            //TODO fix this hack so that the trigger is dynamic

            //change navbar to reflect permissions of current user
            //fixes bug where user had to refresh after login to see nav
            window.setTimeout(function () {
              Store.forceChange();
            }, 500);
            const { from } = props.location && props.location.state || { from: { pathname: '/' } };
            return <Redirect to={from}/>
          }

          // if (loading) return(
          //   <Loading />
          // );

        //return the login form by default
        return (<Container fluid className="main-content-container h-100 px-4">
        <Row noGutters className="h-100">
          <Col lg="3" md="5" className="auth-form mx-auto my-auto">
            <Card>
              <CardBody>
                {/* Logo */}
                <img
                  className="auth-form__logo d-table mx-auto mb-3"
                  src={require("../images/friends-logo.svg")}
                  alt="Friends of Canadian Broadcasting  - Login"
                />

                {/* Title */}
                <h5 className="auth-form__title text-center mb-4">
                  Please login
                </h5>

                {/* Form Fields */}
                <Form >
                  <FormGroup>
                    <label htmlFor="email">Email address</label>
                    <FormInput
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      placeholder="Enter email"
                      autoComplete="email"
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password">Password</label>
                    <FormInput
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      placeholder="Password"
                      autoComplete="current-password"
                    />
                  </FormGroup>
                  <Button
                    pill
                    type="submit"
                    theme="accent"
                    className="btn-submit d-table mx-auto"
                    onClick={e => {
                      e.preventDefault();
                      const credentials = {
                        email,
                        password
                      };
                      return login({variables : credentials});
                      }
                    }>
                    Login
                  </Button>
                </Form>
              </CardBody>

            </Card>

            {/* Meta Details */}
            <div className="auth-form__meta d-flex mt-4">
              <Link to="/forgot-password">Forgot your password?</Link>
            </div>
          </Col>
        </Row>
      </Container>)



      }
      }
      </Mutation>
    )}
    </ApolloConsumer>
  );
};
export default Login;
