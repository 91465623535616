import _ from 'underscore';
import React, { useState, useEffect } from "react";
import { Query } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import ReactTable from 'react-table';
import InboxTable from '../friendsComponents/InboxTable';
import PageTitle from "../components/common/PageTitle";
import Loading from "../friendsComponents/Loading";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,

} from "shards-react";
import {Form} from 'react-bootstrap';

import SmallStats from "./../components/common/SmallStats";

export const GET_BLAST = gql`
  query getBlast($_id: String, $blastMessageLimit: Int, $blastMessageSkip: Int, $blastMessageSort: GenericObject, $opts: GenericObject) {
    getBlast(_id: $_id){
      _id
      message
      from
      positiveResponse
      positiveLink
      negativeResponse
      query
      sentDate
      successCount
      failCount
      positive
      negative
      neutral
      stop
      messages(opts: $opts,limit: $blastMessageLimit, sort: $blastMessageSort, skip: $blastMessageSkip) {
        _id
        message
        supporterId
        date
        from
        direction
        status
        supporter {
          _id
          firstName
          lastName
          contactPreferences{
            okSMS
            okContact
          }
        }
        prospect {
          _id
          askedSTOP
        }
      }
    }
  }
`;

const BlastDetails = ({match, location, history}) => {
  const blastId = match.params.id || null;

  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState('');


  const columns = [{
    Header    : 'To',
    className : "text-center",
    accessor  : 'to'
  },
  {
    Header    : 'Status',
    className : "text-center",
    accessor  : 'status'
  },
   {
    Header   : 'Actions',
    accessor : "actions",

    sortable : false,
    Cell     : row => {
      const isProspect = !!row.original.prospectId;
      const urlFrag = !!isProspect && `/prospect/${row.original.prospectId}` || `/supporter/${row.original.supporterId}`;
      return(
      <ButtonGroup size="sm" className="d-table mx-auto">
        <Button theme="white">
          <Link to={`${urlFrag}?view=conversation'`}>Conversation</Link>
        </Button>
       </ButtonGroup>
      )}
  }]

  return (
    <Container fluid className="main-content-container p-4">
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Message Blast" className="text-sm-left"/>
      </Row>
      <Query
        query       = {GET_BLAST}
        variables   = {{
          _id: blastId, 
          blastMessageLimit: limit, 
          opts: {
            direction: "incoming",
            status
          }
        }}
        fetchPolicy = "network-only"
        pollInterval={10 * 1000}
        onError     = {(e) => {
          console.log(e);
          toast.error("There was a problem");
        }}>
        {({ data, loading, error, refetch }) => {
          console.log({data, loading, error})
          if (loading) {
            return <Loading />
          }

          if (error) return <p>ERROR: {error.message}</p>;

          const blast = data.getBlast;
          
          return (
            <div>
              <Row>
                { [ 'successCount', 'failCount', 'positive', 'negative', 'neutral', 'stop' ].map( (val) => (
                  <Col className="col-lg mb-4" md="6" sm="6" key={val}>
                    <SmallStats
                      id={`small-stats-${val}`}
                      variation="1"
                      label={val}
                      value={!!blast && blast[val] || 0}
                      percentage={null}
                    />
                  </Col>
                )) }
              </Row>
              <Card className="p-0 mb-4">
                {/* <CardHeader>
                  <h6 className="text-center">Details Blasts</h6>
                </CardHeader> */}
                <CardBody className="p-0 mt-2">

                    <Row  className="m-2">
                      <Col>
                        <Form.Label>Message sent</Form.Label>
                        <Form.Control disabled as="textarea" rows="5" value={!!blast &&blast.message} />
                      </Col>
                      <Col>
                        <Form.Label>Positive Response</Form.Label>
                        <Form.Control disabled as="textarea" rows="5" placeholder="no postive response" value={!!blast && !!blast.positiveResponse && blast.positiveResponse || ""} />
                      </Col>
                      <Col>
                        <Form.Label>Negative Response</Form.Label>
                        <Form.Control disabled as="textarea" rows="5" placeholder="no negative response" value={!!blast && !!blast.negativeResponse && blast.negativeResponse || ""} />
                      </Col>
                      
                    </Row>
                    <Row className="m-2">
                      <Col>
                          <Form.Label>Message Link</Form.Label>
                          <Form.Control disabled as="input" rows="5" placeholder="no Link" value={!!blast && !!blast.link && blast.link || ""} />
                      </Col>
                      <Col>
                          <Form.Label>Positive Response Link</Form.Label>
                          <Form.Control disabled as="input" rows="5" placeholder="no positive link" value={!!blast && !!blast.positiveLink && blast.positiveLink || ""} />
                      </Col>
                    </Row>
                    <Row className="m-2 mb-4">
                      <Col>
                        <Form.Label>Query</Form.Label>
                        <Form.Control disabled as="input" placeholder="no query to dislay" value={!!blast && !!blast.query && blast.query|| ""} />
                      </Col>
                    </Row>
               
                </CardBody>
              </Card>
              
              <Card className="p-0">
                  <CardHeader>
                  <h6 className="text-center">Message Blasts</h6>
                  </CardHeader>
                <CardBody className="p-0">
                  <InboxTable
                    messages={!!blast && blast.messages || []}
                    limit={limit}
                    setLimit={setLimit}
                    status={status}
                    setStatus={setStatus}
                  />
                </CardBody>
              </Card>
            </div>
          );
        }}
      </Query>
    </Container>
  )

}


export default BlastDetails;
