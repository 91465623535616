import React, {useState, Fragment} from "react";
import {Modal} from "react-bootstrap";
import Loading from "./Loading";
import {Mutation} from "react-apollo";
import CommentAction from "./CommentAction";
import ConfirmationEmail from "./ConfirmationEmail";
import { toast } from 'react-toastify';
import _ from "underscore";
import MailTrackingCodeSelector from "./MailTrackingCodeSelector";


import numeral from 'numeral';

import gql from 'graphql-tag';


import {
  Row,
  Col,
  CardBody,
  Button,
  FormInput,
  FormCheckbox,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import { FieldsOnCorrectTypeRule } from "graphql";


const validator = require("validator");

export const PROCESS_CHARGE = gql`
  mutation processCharge($supporterId: String, $cardId: String, $type: String, $amount: Int, $opts: GenericObject) {
    processCharge(supporterId: $supporterId, cardId: $cardId, type: $type, amount: $amount, opts: $opts) {
      id
      amount
      paid
      description
      created
      status
    }
  }
`;


const ProcessCharge = ({supporterId, campaign, default_source, sources = [], handleCloseCharge, setGifts, handleShowEmailConfirmation, setPendingAmount, wizardRegisterGift}) => {

  //this modal is for the optional comment
  const [showModal, setShowModal] = useState(false);

  //if the campaign passed is a mail campaign, we want to show the mailTrackingCode input in the form
  const mailTrackForm = !!campaign && !!campaign.mode && campaign.mode === "house mail" || !!campaign && !!campaign.mode && campaign.mode === "prospect mail";

  const [mailTrackingCode, setMailTrackingCode] = useState("");

  const user = JSON.parse(localStorage.getItem('user'));
  const isCaller = _.contains(user.roles.campaign, "caller");

  const [amount, setAmount] = useState('');
  const [stripeAmount, setStripeAmount] = useState(0);
  const [card, setCard] = useState(!!default_source && default_source || !isCaller && "cheque" || null);

  const [processing, setProcessing] = useState(false);

  const displayMailTrackForm = !!mailTrackForm && <Row>
      <MailTrackingCodeSelector
        campaign={campaign}
        mailTrackingCode={mailTrackingCode}
        setMailTrackingCode={setMailTrackingCode}
      />
</Row>|| "";

  return (
    <Mutation
      mutation={PROCESS_CHARGE}
      onError={e => {

        for (const error of e.graphQLErrors) {
          //check if the error is within GraphQL or sent back from the server
          // in order to retrieve the error message at the right place
          const errorMessage = error.extensions.response ?
          error.extensions.response.body.error.message :
          error.message;
          toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER
        });
        }
        setAmount('');
        setCard(default_source);
        setProcessing(false);
      }}
      onCompleted={({processCharge, charge})=> {
        console.log({amount, stripeAmount })
        // toast.dismiss();
        setProcessing(false);
        setShowModal(false);
        
        if ( !!wizardRegisterGift ) {
          wizardRegisterGift({type: "otg", amount: stripeAmount, method: card === "cheque" && "cheque" || "card"});
        }
        
        if(!!handleCloseCharge) {
          setGifts([{type : 'otg', amount: stripeAmount, method: card === "cheque" && "cheque" || "card" }]);
          handleCloseCharge();
          handleShowEmailConfirmation();
        }
        //Once the payment completed, remove the amount value and set the value of pendingAmount to false
        if(!!setPendingAmount) {
          setPendingAmount(false);
        }
        console.log("Gift : ", {type : 'otg', amount: stripeAmount, method: card === "cheque" && "cheque" || "card" })
        setAmount("");
        
        
        toast.success(`${!!stripeAmount && `${numeral(stripeAmount / 100).format("$0,0.00")}`|| ""} one time donation processed`,{
          autoClose : false,
          position: toast.POSITION.TOP_CENTER,
          delay : 1000,
          closeOnClick: false, 
          draggable: false
        });

      }}
      refetchQueries = {['getSupporter', 'getSupporters', 'getActions', 'getCampaign']}>
      {(charge, {loading, error}) => {

        if (loading) return(
          <Loading />
        );
        {/* if (error) return <p>ERROR: {error.message}</p>; */}


        const cards = !!sources && sources.map(((card, i) => {

          const primary = (card.id === default_source) ? "Primary" : "";

          return (
            <option
              key={i}
              value={card.id}>
              {`${card.brand} **** ${card.last4} - ${primary}`}
            </option>
          )
        })) || [];
        
        if(!isCaller) {
          cards.push(
            <option
              key={"cheque"}
              value={"cheque"}>
              {`Cheque`}
            </option>
        )
        }
      

        return (
          <Fragment>
          {displayMailTrackForm}
          <Row>  
            <Col md="12" lg="6" >
            <label>Please enter the amount :</label>
            <InputGroup className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
              <FormInput
                id="month"
                type="text"
                value={amount}
                placeholder="00.00"
                onChange={e => {
                  let amountToPay = e.target.value;
                  if(!!setPendingAmount) {
                    setPendingAmount(!!amountToPay);
                  }
                  return setAmount(amountToPay)
                }}
              />
              </InputGroup>
            </Col>
            <Col md="12" lg="6">
            <label>Select the payment method:</label>
              <FormSelect
                className="mb-3"
                id="card"
                defaultValue={!!default_source && default_source || "cheque"}
                onChange={e => setCard(e.target.value)}
              >
                {cards}
              </FormSelect>
            </Col>
          </Row>

       
        <Row>
          <Col>
              <Button
              disabled={!!processing || !cards.length}
              onClick={() => {
                if(!!mailTrackForm && !mailTrackingCode.length) {
                  const conf = window.confirm("You did not enter a Mail Tracking Code, are you sure you want to continue and process this donation?");

                  if ( !conf )  {
                    return null;
                  }
                }

                const validAmount = validator.isCurrency(amount + "", {
                  allow_negatives : false,
                });

                if(!validAmount) {
                  toast.error("Please enter a valid amount.");
                  setAmount('');
                } else {
                  setStripeAmount(Math.ceil(parseFloat(amount) *100));
                  setShowModal(true);
                }
              }
              }>Process</Button>
              <CommentAction
                action={`charge $${amount}`}
                confirmation={true}
                mutation={charge}
                // waitingMessage={`Please wait while the system process the $${amount} charge`}
                showModal={showModal}
                setShowModal={setShowModal}
                opts={
                  {
                    variables      : {
                      supporterId,
                      cardId         : !!card && card || default_source || null,
                      type           : card === "cheque" && "cheque" || null,
                      amount         : stripeAmount,
                      opts           : {
                        campaign   : !!campaign && campaign || null,
                        mailTrackingCode : !!mailTrackingCode && mailTrackingCode || null
                      }},
                    refetchQueries : ['getSupporter', 'getActions', 'getSupporters', 'getCampaign']
                  }
                } />
            </Col>
          </Row>


           {/* Add Confirmation Email */}
            {/* <Modal
              size="md"
              // centered
              show={showConfirmation}
              onHide={handleCloseConfirmation}>
                <Modal.Header closeButton>
                  <Modal.Title>Add a Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ConfirmationEmail />
                </Modal.Body>
              </Modal> */}
          </Fragment>
        )
      }}
    </Mutation>

  )
}

export default ProcessCharge;
