import React from "react";
import RetrySubscription from "./RetrySubscription";
import UpdateSubscription from "./UpdateSubscription";
import PauseSubscription from "./PauseSubscription";
import CustomerMismatchWarning from './CustomerMismatchWarning'
import moment from "moment";
import CancelSubscription from "./CancelSubscription";
import {
  Badge,
  Col,
  Row
} from "shards-react";

const Subscription = ({subscription, supporterId, customerId, bankAccounts, existingCreditCard, campaign = null}) => {
  const customerMismatch = !!subscription.customerId && subscription.customerId !== customerId;
  const status = <Badge
                    outline
                    pill
                    theme={ (subscription.status === "active") ?
                      "success" :
                      "warning"
                    }>
                    {subscription.status}
                  </Badge>
  
  const pauseInfo = !!subscription.pause_collection && 
    <div className="mr-2">
      <strong>
      Will resume on {moment.unix(subscription.pause_collection.resumes_at).format("ll")}
      </strong> 
    </div>
    || "";


  //find the bank account associated with this subscription
  const bankAccount = !!bankAccounts && !!bankAccounts.length && bankAccounts.filter(account => account._id === subscription.bankAccountId) || null;

  const displayDetails = !!bankAccount && !!bankAccount.length && !!subscription.bankAccountId ?
    <div className="go-stats__meta">
      <span className="mr-2">
        <strong>{`Bank Account ${bankAccount[0].bank} ****${bankAccount[0].last4}`}</strong>
      </span>
    </div>
    :
    <h6 className="go-stats__label mb-1">Credit Card</h6>;

  return (
    <div className="d-flex row px-0" style={!!customerMismatch && {"border" : "5px solid orange"} || {}}>
      { !!customerMismatch ?
        <CustomerMismatchWarning />
      : "" }
      <Col className="col-10" lg="10" md="10" sm="10">
        <h6 className="go-stats__label mb-1">{subscription.plan.nickname}  {status}</h6>
        {displayDetails}
        <div className="go-stats__meta">
          <span className="mr-2">
            <strong>{`Created : ${moment.unix(subscription.created).format('ll')}`}</strong>
          </span>
          {pauseInfo}
          <div className="d-flex">
            {!customerMismatch ? <UpdateSubscription
              subscription={subscription}
              supporterId={supporterId}
              customerId={subscription.customerId}
              bankAccounts={bankAccounts}
              existingCreditCard={existingCreditCard}
              campaign = {campaign} /> : ""}
              
            {!!subscription.customerId && <PauseSubscription
              subscription={subscription}
              supporterId={supporterId}
            /> || ""}

            <CancelSubscription
              subscriptionId={subscription.id}
              customerId={subscription.customerId}
              nickname={subscription.plan.nickname}
              bankAccount = {!!subscription.bankAccountId}
              supporterId={supporterId}
            />
          
          
        </div>
        </div>

        

      </Col>

      <Col className="col-5" lg="5" md="5" sm="5">
        

        {/* {retryButton} */}
      </Col>
    </div>
  )

}

export default Subscription;
