import React, {useState} from "react";
import CommentAction from "./CommentAction";

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { Col } from "shards-react";

export const REMOVE_BANK_ACCOUNT = gql`
  mutation deleteBankAccount($_id: ID, $supporterId: ID) {
    deleteBankAccount(_id : $_id, supporterId: $supporterId) {
      n
      nModified
      ok
    }
  }
`;


const BankAccount = ({bankAccount, activeSubscription, supporterId}) => {

  const [showModal, setShowModal] = useState(false)

  const opts = {
    variables : {
    _id : bankAccount._id,
    supporterId,
    opts : {}
  },
    refetchQueries : ['getSupporter'],
    onCompleted :  () => toast.success("bank account deleted successfully"),
    onError : (e) => {
      for (const error of e.graphQLErrors) {
        //check if the error is within GraphQL or sent back from the server
        // in order to retirce the error message at the right place
        const errorMessage = error.extensions.response ?
        error.extensions.response.body.error.message :
        error.message;
        toast.error(errorMessage);
      }
    }
  }

  const [deleteBankAccount, { error }] = useMutation(REMOVE_BANK_ACCOUNT , opts );
  if(error) {
    console.log({error})
  }

  return (

    <div className="d-flex row px-0">
      <Col>
        <h6 className="go-stats__label mb-1">{bankAccount.bank}</h6>
        <div className="go-stats__meta">
          <span className="mr-2">
            <strong>{`${bankAccount.firstName} ${bankAccount.lastName}`}</strong>
          </span>
          <div className="mr-2">
            <strong>{`**** **** ******** ${bankAccount.last4}`}</strong>
          </div>
          <div>
            <a
              href="#"
              style={{color : "red", cursor: "pointer"}}
              onClick={(e) => {
                e.preventDefault();
                if(!!activeSubscription) {
                  return toast.warning("You can't remove this account, there is an active subscription using it.")
                }
                setShowModal(true);
              }}
              >Delete</a>
          </div>
        </div>
      </Col>
      <CommentAction
        action={"delete bank account"}
        confirmation={true}
        mutation={deleteBankAccount}
        showModal={showModal}
        setShowModal={setShowModal}
        opts={opts} />
    </div>
  )

}

export default BankAccount;
