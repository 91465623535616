'use strict';

import React from 'react';
import _ from 'underscore';

import { FormSelect } from "shards-react";


const SelectOperator = ({field, keyName, selector, setSelector, operator, setOperator, operators}) => {

  return (
    <FormSelect style={{maxWidth: 150}} className='d-block mb-2' size='sm' value={operator} multiple={false} onChange={(e) => {

      //if the selector already has a value for this key, change it to use the new operator
      if ( _.has( selector, keyName ) ) {
        //get the raw value (i.e. strip the operator)
        const rawVal = operators[operator].rawValue( selector[keyName] );

        //update the selector to use the new operator format
        const addToSelector = operators[e.target.value].handler.apply(this, [keyName, rawVal]);
        setSelector({ ...selector, ...addToSelector});
      }

      //set the operator locally
      setOperator(e.target.value);
    }}>
      { field.operators.map( (op, i) => {
        return (<option key={`op-${i}`} value={op}>{!!operators[op] && operators[op].generateLabel(field, op)}</option>);
      })}
    </FormSelect>
  );
}


export default SelectOperator;
