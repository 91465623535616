import React, {useState, Fragment} from 'react';
import { ChargesRow, ChargesHeader, ChargesBody} from "./ChargesRow";
import {Modal} from "react-bootstrap";
import Loading from "./Loading";
import CommentAction from "./CommentAction";
import { toast } from 'react-toastify';
import CollectionTable from "./CollectionTable/index";
import ReactTable from 'react-table';
import moment from "moment";
import RetryInvoice, {retryInvoice} from "./retryInvoice";
import EditCheque from "./EditCheque";
import gql from 'graphql-tag';
import { Link } from "react-router-dom";
import EditCampaign  from "./EditCampaign";
import Refund from "./Refund";
import _, { identity } from "underscore";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  Button,
  ButtonGroup,
  Badge
} from "shards-react";
import { useQuery } from '@apollo/react-hooks';

export const GET_CHARGES_ACTIONS = gql`
  query getActions($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject) {
    getActions(selector: $selector, limit: $limit, skip: $skip, sort: $sort) {
      count
      actions {
        _id
        type
        campaign
        supporterId
        date
        data
        description
      }
    }
  }
`;




export default function ChargesComponent({supporterId, supporter, charges, campaignView = false, hideHeader}) {

  const user = JSON.parse(localStorage.getItem("user"));
  let hideForCaller;

  if ( !!_.contains(user.roles.campaign, "caller")  ) {
    hideForCaller = true;
  }

  //modal Edit Cheque
  const [showEditCheque, setShowEditCheque] = useState(false);

  const handleCloseEditCheque = () => setShowEditCheque(false);
  const handleShowEditCheque = () => setShowEditCheque(true);

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);


  const statusColor = {
    success : "success",
    failure : "danger",
    refunded : "secondary"
  }

  const columns = [{
    Header: 'Amount',
    className: "text-center",
    maxWidth: 90,
    accessor: 'data.amount',
    Cell: row => <strong>${(row.original.data.amount/100).toFixed(2)}</strong>
  }, {
    Header: 'Date',
    className: "text-center",
    minWidth: 140,
    maxWidth: 150,
    accessor: 'date',
    Cell: row => {
      return moment(row.original.date).format('lll')
    }

  }, {
    Header: 'Billing Date',
    className: "text-center",
    minWidth: 100,
    maxWidth: 100,
    accessor: 'data.billingDate',
    Cell: row => {
      return !!row.original.data.billingDate && moment(row.original.data.billingDate).format('ll')|| "";
    }

  }, {
    Header: 'Status',
    className: "text-center",
    maxWidth: 70,
    accessor: 'data.status',
    Cell: row => {
      return (
        <Badge
          pill
          outline
          theme={statusColor[row.original.data.status]}>
          {row.original.data.status}
        </Badge>
      );
    }
  }, {
    Header: 'Type',
    className: "text-center",
    maxWidth: 180,
    minWidth: 80,
    accessor: 'data.paymentType',
    Cell: row => {
      return row.original.data.paymentType === "otg" && "One-Time Gift" || row.original.data.paymentType || null;
    }
  }, {
    Header: 'Payment Method',
    className: "text-center",
    maxWidth: 180,
    minWidth: 70,
    accessor: 'data.paymentMethod'
  },{
    Header: 'Campaign',
    className: "text-center",
    Cell: row => {
      return !!row.original.campaign && !!row.original.campaign.nickname && <Link to={`/campaign/${row.original.campaign._id}`}>{row.original.campaign.nickname}</Link>
    }
  },{
    Header: 'Actions',
    accessor: "actions",
    maxWidth: 300,
    minWidth: 200,
    sortable: false,
    Cell: row => {
      if(!!hideForCaller) {
        return "";
      }
      if(row.original.data.paymentMethod === "card" && row.original.data.status === "success" && (!!row.original.data.record && !!row.original.data.record.id || !!row.original.data.record && row.original.data.record.chargeId)) {
        return  (
          <ButtonGroup className="btn-block">
            <Refund
              chargeId = {row.original.data.record.id || row.original.data.record.chargeId}
              amount = {row.original.data.amount}
              supporterId = {row.original.supporterId}
            />
            {/* Only show Edit campaign for otg */}
            {row.original.data.paymentType === "otg" && <EditCampaign
            action={row.original}
            />
            || ""}
        </ButtonGroup>
        )
      }
      if(row.original.data.paymentMethod === "cheque" && row.original.data.status === "success") {
        return(
          <ButtonGroup className="btn-block">
   
              <Button
                block
                theme="white"
                onClick={()=> { handleShowEditCheque()} }>
                Edit
              </Button>

          <EditCampaign
            action={row.original}
            />

     
          <Modal
            size="md"
            // centered
            show={showEditCheque}
            onHide={handleCloseEditCheque}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit cheque</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <EditCheque 
                cheque={row.original}
                chequeId = {row.original._id}
                supporterId={row.original.supporterId}
                amount={row.original.data.amount}
                status={row.original.data.status}
                handleClose={handleCloseEditCheque}
              />
            </Modal.Body>
          </Modal>
      </ButtonGroup>
        )
      }
      if(row.original.data.status === "failure" && !!row.original.data.record && !!row.original.data.record.invoiceId) {
        return (
        <RetryInvoice 
          invoiceId = {row.original.data.record.invoiceId}
          supporterId = {row.original.supporterId}
        />);
      }
      return ""
    }
  }]

  let IdList = !!supporter && [supporter._id];
    if ( !!supporter.mergedWith && !!supporter.mergedWith.length ) {
      IdList = [ supporter._id, ...supporter.mergedWith  ];
    }
  
  const opts = {
    variables: {
      selector : {
        supporterId : {$in : IdList},
        "data.paymentType" : {$exists : true, $ne : false}
      },
      limit,
      skip,
      sort     : { date : -1 },
      //hack using riding codes to ensure this actually reruns when the codes change
    },
    fetchPolicy : "network-only",
    onError(e) {
      console.log('fuckin error', e);
    }
  };

  const {data, loading, error} = useQuery(GET_CHARGES_ACTIONS, opts)
  console.log(data)

  const actions = !!data && data.getActions.actions || [];
  const count = !!data && data.getActions.count;



  const type = {
    "otg" : "OTG",
    "subscription" : "PAC"
  }
  const method = {
    "card"   : <i style = {{color : "royalBlue", fontSize : "1.8em"}} className="fab fa-cc-stripe"></i>,
    "cheque" : <i style = {{color : "#a8a886", fontSize : "1.8em"}} className="fas fa-money-check-alt"></i>,
    "bank"   : <i style = {{fontSize : "1.8em"}} className="fas fa-piggy-bank"></i>,
    "import" : <i style = {{fontSize : "1.8em"}} className="fas fa-database"></i>,
    "paypal"   : <i style = {{fontSize : "1.8em"}} className="fab fa-cc-paypal"></i>,
  }
  
  const table = {
    "data.paymentMethod" : {
      header: " ",
      xs : 1,
      sm : 1,
      format : (action) => !!action.data && !!method[action.data.paymentMethod] && method[action.data.paymentMethod] || action.data.paymentMethod
    },
    "date" : {
      header: "Date",
      xs : 5,
      sm : 3,
      format : (action) => moment(action.date).format("ll")
    },
    "data.amount" : {
      header: "Transaction",
      xs : 3,
      sm : 3,
      format : (action) => !!action.data && `${!!type[action.data.paymentType] && type[action.data.paymentType]}-$${(action.data.amount/100).toFixed(2)}` || ""
    },
    "campaign.nickname" : {
      header: "Campaign",
      xs : 4,
      sm : 3,
      format : (action) => {
        if(!!action.data.billingDate) {
          return `PAC-${moment(action.data.billingDate).format("MMMYYYY")}`;
        }
        return !!action.campaign && <Link to={`/campaign/${action.campaign._id}`}>{action.campaign.nickname}</Link>
      }
    }
  }


  return ( 
    <div>
      {!!actions.length && 
      <Fragment>
        <Row className="mx-1">
          {Object.keys(table).map((field, i) => {
            return <Col  key={i + Math.random()} xs={table[field].xs} sm={table[field].sm}>
              {table[field].header}
            </Col>
          })}
        </Row>
        {!!loading && <Loading /> || !!actions.length && actions.map((action, index) => {
            
            return  <div key={index + Math.random()}>
              <ChargesRow>
              <ChargesHeader
                campaignView={campaignView}
                table={table}
                index={index}
                action={action}/>
            
            </ChargesRow>
            </div>
          })}
        <Row className="m-3 justify-content-between">
          <Button
            theme={"white"}
            disabled = {skip === 0}
            onClick={() => setSkip(skip - limit)}>
          Previous
          </Button>
          <Button
            theme={"white"}
            disabled={skip + limit > count}
            onClick={() => setSkip(skip + limit)}>
            Next
          </Button>
        </Row>
      </Fragment>
      
      || <div className="mx-4 mb-4">- No Contributions History -</div>}
      
    </div>
  );
}
