import React, {useState} from "react";
import {Mutation} from "react-apollo";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import _ from "underscore";
import SetPageMetas from '../utils/SetPageMetas';
import DatePicker from "react-date-picker";
import numeral from "numeral";

import Loading from "./Loading";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Card
} from "shards-react";

import { toast } from 'react-toastify';

const CREATE_BANKRUN = gql`
mutation createBankRun($date: Date){
  createBankRun(date: $date) {
    _id
    status
    created
    runDate
    bankRunCode
    count {
      expected
      processed
      declined
    }
    amount {
      expected
      processed
      declined
    }
  }
}
`

const GET_SUBSCRIPTIONS = gql`
query getSubscriptions($selector: Selector, $projector: GenericObject, $limit: Int, $skip: Int, $sort: GenericObject){
  getSubscriptions(selector: $selector, projector: $projector, limit: $limit, skip: $skip, sort: $sort) {
    count
    subscriptions {
      plan {
        amount
      }
    }
  }
}`


const CreateBankRun = ({handleClose}) => {
    SetPageMetas({ title: "Create Bank Run" });

    const [date, setDate] = useState(null)  ;

    const {data, loading, error, refetch} = useQuery(GET_SUBSCRIPTIONS, {
      variables: {
          projector : {
            'plan.amount' : 1
          }
        },
        fetchPolicy : "network-only"
    });

    const expectedAmount = !!data && data.getSubscriptions.subscriptions.reduce((acc, subs) => acc + subs.plan.amount, 0);

    if ( !!loading ) {
      {/* <Loading/> */}
        return (
          <Loading />
        )
    }



    return (
        <Mutation
          mutation={CREATE_BANKRUN}
          onCompleted={({createBankRun}) => {
            console.log({createBankRun})
            toast.success(`Bank Run created`)
            handleClose();
          }}
          onError={e => {
            for (const error of e.graphQLErrors) {
              //check if the error is within GraphQL or sent back from the server
              // in order to retirce the error message at the right place
              const errorMessage = error.extensions.response ?
              error.extensions.response.body.error.message :
              error.message;
              toast.error(errorMessage);
            }
          }}
        >
        {(createBankRun, {loading, error}) => {
          if ( !!loading ) {
            {/* <Loading/> */}
              return (
                <Loading />
              )
          }
          return (
            <Form onSubmit={async(ev) => {
              ev.preventDefault();

              if(!date){
                return toast.error("You must pick a date for your bank run")
              }

              createBankRun({
                variables : {
                  date : date.toISOString()
                },
                refetchQueries: ['getBankRuns']
              })

              }}>

              <Card className="p-3 m-3">
                <Row form>
                  <Container fluid>
                    <Row className="px-3">
                      <Col className="sc-stats__title">Pick a Run Date</Col>
                      <Col className="sc-stats__value text-right">
                        <DatePicker
                          onChange={date => setDate(date)}
                          value={date}
                        />
                      </Col>
                    </Row>

                    <Row className="px-3 my-3">
                      <Col className="sc-stats__title">Monthly Susbscriptions</Col>
                      <Col className="sc-stats__value text-right">{!!data && data.getSubscriptions.count || "Can't access subscriptions"}</Col>
                    </Row>

                    <Row className="px-3 mb-3">
                      <Col className="sc-stats__title">Expected Amount</Col>
                      <Col className="sc-stats__value text-right">{!!data && `$ ${numeral(expectedAmount / 100).format('0,0.00')}` || "Can't access subscriptions"}</Col>
                    </Row>

                  </Container>

                </Row>
              </Card>



              <Button>Create Bank Run</Button>
            </Form>
          );
        }}
      </Mutation>
    );

}

export default CreateBankRun;
