import React, { useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Route, Redirect } from "react-router-dom";
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';

import gql from 'graphql-tag';
import _ from 'underscore';

import SetPageMetas from '../utils/SetPageMetas';
import Loading from "../friendsComponents/Loading";
import PageTitle from "../components/common/PageTitle";

import UserAccountDetailsForm from "../views/UserAccountDetailsForm";

export const CREATE_USER = gql`
  mutation createUser($user: GenericObject) {
    createUser(user: $user) {
      _id
      firstName
      lastName
      emails {
        _id
        address
        isPrimary
      }
      roles
      gatewayRole
    }
  }
`;


const CreateUser = () => {
  SetPageMetas({ title: "Create User" });
  const [redirect, setRedirect] = useState("");

  if(!!redirect && !!redirect.length) {
    return <Redirect to={redirect} />
  }
  return (
    <Mutation
      mutation={CREATE_USER}
      onCompleted={({createUser}) => {
            toast.success(`User created`);
            setRedirect(`${createUser._id}`)
          }}
      onError={(e) => {
        console.log(e);
      }}
      >
      {(createUser, {data, loading, error}) => {
        if (loading) {
          return <Loading />
        }

        if (error) return <p>ERROR: {error.message}</p>;
        return (
          <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={ `User` } subtitle="Create" md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            <Col >
              <UserAccountDetailsForm
                createUser={createUser}
              />
            </Col>
          </Row>
        </Container>
      )
     }}

    </Mutation>
  )
  return null;
}

export default CreateUser;
