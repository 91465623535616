import React, {useState, Fragment} from "react";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { Modal } from "react-bootstrap";
import QueryFilter from "./QueryFilter/index";
import useToast from "../hooks/use-toast";
import {
  Button,
  FormSelect,
  Row,
  Collapse
} from "shards-react";

import fields from "./QueryFilter/fields";
import _ from "underscore";

export const GET_REPORT = gql`
  query getReport($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject, $columns: Array, $name: String) {
    getReport(selector: $selector, limit: $limit, skip: $skip, sort: $sort, columns: $columns, name: $name) {
      url
    }
  }
`;



const ExportButton = ({ queryReport = {}, columns, projector = {}, name, collection}) => {
  console.log(collection);
  const [columnsToUse, setColumnsToUse] = useState(columns);
  const [query, setQuery] = useState({
    skip : true
  });

  const [selector, setSelector] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //state of the collapse of advanced search
  const [ active, toggleActive ] = useState(false);

  const { loadingToast, completeToast, errorToast} = useToast();

  const {data, loading, error, refetch} = useQuery(GET_REPORT, query);

  // if ( !!error ) {
  //   console.log(error);
  //   toast.error("Error:", error.message);
  // }

  const collectionFields = fields[collection];

  //reorder the different fields so they appear correctly and in a constant way in the export
  const orderedSelector = () => {
    let ordered = [];

    Object.keys(collectionFields).forEach( category => {
      collectionFields[category].forEach(field => {
        let present = _.findWhere(selector, {key : field.key})
        if(!!present) {
          ordered.push(field);
        }
      });
    })

    return ordered;

  }

  //if we're looking for collection supporters and no isDeceased key is passed,
  //make sure to add a isDeceased default to false.
  const defaultNotDeceased = () => {
    const newSelector = queryReport;
    if(collection === "supporters" && queryReport.isDeceased === undefined) {
      newSelector.isDeceased = false;
    }
    return newSelector;
  }

  return (
    <Fragment>
      <Button
        onClick={handleShow}>
        Export <i className='material-icons'>get_app</i>
      </Button>
      <Modal
    size="md"
    // centered
    show={show}
    onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Export</Modal.Title>
      </Modal.Header>
      <Modal.Body>

          <div className="mb-2">
            Choose an existing fields selection :
          </div>
          <div className="mb-4">
          <FormSelect
            size="sm"
            style={{ maxWidth: "130px" }}
            onChange={() => {}}>
            <option value="default">default</option>
          </FormSelect>
          </div>

          <Button
            theme={!active && "secondary" || "primary"}
            outline= {!active}
            onClick = {() => toggleActive(!active)} className="mb-2">
            Advanced field selection {}
          </Button>
          <div className="mb-2">
           <Collapse open={active} timeout={0}>
            <QueryFilter
              selector={selector}
              setSelector={setSelector}
              collection={collection}
            />
           </Collapse>
          </div>
        <Row className="justify-content-between">
          <Button
            outline
            theme="danger"
            className="export-button mt-4 mx-3"
            onClick={handleClose}>
            Cancel
          </Button>
          <Button
          className="export-button mt-4 mx-3"
          onClick={() => {
            const conf = window.confirm("This report may contain confidential personal. You should not download this report if you are using public WIFI or a computer you do not trust. Continue anyway?");

            if ( !conf )  {
              return toast.warning(`Report canceled`);
            }

            loadingToast(`Generating your export...`);

            setQuery({
              variables : {
                selector : defaultNotDeceased(),
                columns : !!active && orderedSelector() || columnsToUse,
                projector,
                name
              },
              fetchPolicy :"network-only",
              onCompleted : ({ getReport }) => {
                completeToast("Export Generated");
                if ( !getReport ) {
                  return console.log('no report', getReport);
                }
                const win = window.open(getReport.url);
                setQuery({
                  skip : true
                })
              },
              onError: (e) => {
                console.log('error', Object.keys(e), e.graphQLErrors );
                if ( !!e.graphQLErrors && !!e.graphQLErrors.length )  {
                  errorToast(e.graphQLErrors[0].message);
                } else {
                  errorToast("Error with export");
                }
              }
            });
          }}
          >Export
        </Button>
      </Row>
      </Modal.Body>
      </Modal>
    </Fragment>
  )

}

export default ExportButton;
