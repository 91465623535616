import gql from 'graphql-tag';

//Queries
import { SUPPORTER_DETAILS } from "../../fragments/graphqlFragments";


export const GET_SUPPORTERS = gql`
  query getSupporters($selector: Selector, $projector: GenericObject, $limit: Int, $skip: Int, $sort: GenericObject, $cacheBuster: Int) {
    getSupporters(selector: $selector, projector: $projector, limit: $limit,skip: $skip, sort: $sort, cacheBuster: $cacheBuster ) {
      count
      supporters {
        ...SupporterDetails
        requireReview
        matchCandidates
        importFromJH
        householdMembers {
          _id
          firstName
          lastName
        }
        lastUpdated
      }
    }
  }
  ${SUPPORTER_DETAILS}
`;

export const MERGE_SUPPORTERS = gql`
  mutation mergeSupporters($supportersToMerge: GenericObject, $modifier: GenericObject, $opts: GenericObject) {
    mergeSupporters(supportersToMerge: $supportersToMerge, modifier: $modifier, opts: $opts) {
      ok
      n
      nModified
    }
  }
`;

export const SET_AS_NEW_SUPPORTER = gql`
  mutation updateOneSupporter($selector: Selector, $modifier: Modifier) {
    updateOneSupporter(selector: $selector, modifier: $modifier) {
      ok
      n
      nModified
    }
  }
`;

export const CREATE_HOUSEHOLD = gql`
  mutation linkSupporters($supporterIds: [ String ], $modifier: Modifier, $allSupportersInfo: GenericObject, $updateAll: Boolean, $opts: GenericObject) {
    linkSupporters(supporterIds: $supporterIds, modifier: $modifier, allSupportersInfo: $allSupportersInfo, updateAll: $updateAll, opts: $opts) {
      ok
      n
      nModified
    }
  }
`;

export const UPDATE_ACTIONS = gql`
  mutation updateManyActions($selector: Selector, $modifier: Modifier) {
    updateManyActions(selector: $selector, modifier: $modifier) {
      ok
      n
      nModified
    }
  }`;


  let provinces = {
    "AB" : ["Alberta", "AB"],
    "BC" : ['British Columbia', 'Colombie-Britannique', 'BC'],
    "PE" : ['Prince Edward Island', 'PE', 'PEI'],
    "MB" : ['Manitoba', 'MB'],
    "NB" : ['New Brunswick', 'Nouveau-Brunswick', 'New-Brunswick', 'NB'],
    "NS" : ['Nova Scotia', 'Nouvelle-Ãcoss', 'Nouvelle-Écosse', 'NS'],
    "ON" : ['Ontario', 'ont', 'ON'],
    "QC" : ['Quebec', 'Québec', 'QuÃ©bec', 'QC'],
    "SK" : ['Saskatchewan', 'Sask', 'SK'],
    "NL" : ['Newfoundland and Labrador', 'Newfoundland', 'Newfoundland & Labrador', 'Terre-Neuve-et-Labrador', 'NL'],
    "NU" : ['Nunavut', 'NU'],
    "NT" : ['Northwest Territories', 'Territoires du Nord-Ouest', 'NT'],
    "YK" : ['Yukon', 'YK']
  };
