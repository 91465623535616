import React, {useState, Fragment} from 'react';
import { ToastContainer, toast } from "react-toastify";
import QueryBuilder from './QueryBuilder';
import { Fade } from "react-bootstrap";
import ReactTable from 'react-table';
import { Link } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import _ from "underscore";
import gql from 'graphql-tag';
import numeral from 'numeral';
import getSupporterColumns from "../data/supporter-columns";
import ExportButton from "./ExportButton";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Badge,
  FormCheckbox,
  ButtonGroup,
  Button,
  Row,
  Col,
} from "shards-react";

import BottomActionBar from './BottomActionBar.js';


export const GET_SUPPORTERS = gql`
  query getSupporters($selector: Selector, $projector: GenericObject, $limit: Int, $skip: Int, $sort: GenericObject, $cacheBuster: Int) {
    getSupporters(selector: $selector, projector: $projector, limit: $limit, skip: $skip, sort: $sort, cacheBuster: $cacheBuster ) {
      count
      supporters {
        _id
        firstName
        lastName
        mobilePhone
        homePhone
        address1
        address2
        ENSupporterId
        ridingName
        lastUpdated
        ridingCode
        city
        PIN
        province
        language
        PACStatus
        customerId
        isDeceased
      }
    }
  }
`;


const PACStatusMap = {
  "active"        : "success",
  "pending"       : "info",
  "lapsed"        : "warning",
  "uncollectable" : "danger",
  "cancelled"     : "secondary"
}



const SupporterTable =({query, collection, setQuery, count, setCount, handleError, actions = [], setCampaignSupporter }) => {


  const [ loading, setLoading ] = useState(false);
  const [ pages, setPages ] = useState(0);



  //handles the supporters to select
  const [ selectedSupporters, setSelectedSupporters ] = useState([]);

  //this keeps track of the toast labels showing supporters selected
  const [labelSelectedSupporters, setLabelSelectedSupporters] = useState({});

  if ( !actions.length ) {
    actions = ['view', 'select'];
  }

  //sets the columns to display for our supporters table
  const columns = [{
    Header: 'First Name',
    className: "text-center d-flex align-items-center",
    accessor: 'firstName'
  }, {
    Header: 'Last Name',
    className: "text-center d-flex align-items-center",
    accessor: 'lastName'

  },{
    Header: 'Mobile Phone',
    className: "text-center",
    accessor: "mobilePhone"
  }, {
    Header: 'City',
    className: "text-center",
    accessor: 'city'
  },{
    Header: 'Province',
    className: "text-center",
    accessor: 'province'
  },{
    Header: 'PAC',
    className: "text-center",
    accessor: "PACStatus",
    Cell: row => {
      return <span>{!!row.original.PACStatus && <Badge pill theme={PACStatusMap[row.original.PACStatus]}>{row.original.PACStatus}</Badge>}</span>;
    }
  }
  // ,{
  //   Header: 'Actions',
  //   accessor: "actions",
  //   maxWidth: 300,
  //   minWidth: 180,
  //   sortable: false,
  //   Cell: row => {
  //     return (
  //     <Fragment>
  //       {
  //         actions.includes('view') ?
  //         <Link to={`/supporter/${row.original._id}?view=main`}
  //           className='btn btn-white'>
  //           View Profile
  //         </Link>
  //         : ""
  //       }

  //       {
  //         actions.includes('select') ?
  //         <FormCheckbox
  //           checked={_.contains(selectedSupporters, row.original._id)}
  //           className="ml-auto my-auto"
  //           id="isPrimary"
  //           onChange={ () => {
  //             if(!_.contains(selectedSupporters, row.original._id)) {
  //               setSelectedSupporters([...selectedSupporters, row.original._id ]);
  //               setLabelSelectedSupporters({
  //                 ...labelSelectedSupporters,
  //                 [row.original._id] : toast.info(`${row.original.firstName} ${row.original.lastName} selected `, {autoClose : false, closeButton: false, closeOnClick: false, draggable: false})})
  //             } else {
  //               setSelectedSupporters(selectedSupporters.filter(_id => _id !== row.original._id))
  //               toast.dismiss(labelSelectedSupporters[row.original._id]);
  //             }
  //           } }>
  //         </FormCheckbox>
  //         : ""
  //       }

  //       {
  //         actions.includes('useForCampaign') ?
  //         <Button
  //           className='btn btn-primary'
  //           onClick={ e => {
  //             setCampaignSupporter( row.original );
  //           }}
  //         >
  //           Select
  //         </Button>
  //         : ""
  //       }
  //     </Fragment>
  //     )
  //   }
  // }
];



  const opts = {
    variables: {
      selector : query,
      limit    : (20 > query.limit) ? parseInt(query.limit) : 20,
      skip     : 0,
      sort     : { createdDate: -1 },
      //hack using riding codes to ensure this actually reruns when the codes change
      cacheBuster : !!query.ridingCode && !!query.ridingCode.$in && query.ridingCode.$in.reduce( (tally, val) => tally + val, 0 ) || 0
    },
    fetchPolicy : "network-only"
  };

  const { error, data, fetchMore } = useQuery(GET_SUPPORTERS, opts );




  const countSupporters = !!data && !!data.getSupporters && data.getSupporters.count || 0;
  const displayCount = (countSupporters > query.limit) ? query.limit : countSupporters;

  setCount(countSupporters);

  if (error) return `Error! ${error}`;

  //if a supporter is deceased, grey out the line
  const getTrProps = (state, rowInfo, instance) => {
    const deceased = !!rowInfo && !!rowInfo.original.isDeceased || null;

    if (!!rowInfo) {
      return {
        style: {
          background : (!!deceased) ? 'Gainsboro' : '',
          color      : (!!deceased) ? 'grey' : ''
        }
      }
    }
    return {};
  }


  return (
    <div>
      <CardBody className="p-0">
        <ReactTable
          data={!!data && !!data.getSupporters ? data.getSupporters.supporters : []}
          columns={columns}
          // LoadingComponent={Loading}
          defaultPageSize={count || 20}
          getTrProps={getTrProps}
          pageIndex={0}
          showPageJump = {false}
          manualPagination={true}
          manual
          noDataText={`No ${collection} to display`}
          pages={ pages }
          onFetchData={(state, instance) =>  {

            //create sort object with default being createdDate desc
            let sort = { createdDate: -1 }
            state.sorted.forEach( (sortField, i) => {
              //if we have anything here, overwrite default
              if ( i == 0 ) {
                sort = {};
              }
              sort[sortField.id] = !!sortField.desc && -1 || 1;
            });
            //tell the table we are loading
              setLoading(true);

              //get the data
              fetchMore({
                variables: {
                  skip: state.pageSize * state.page,
                  limit: state.pageSize,
                  sort
                },

                updateQuery: (prev, { fetchMoreResult }) => {

                  //update the data
                  if (!fetchMoreResult) {
                    setPages(Math.ceil(prev.getSupporters.count / state.pageSize));
                    return prev;
                  }
                  setPages(Math.ceil(fetchMoreResult.getSupporters.count / state.pageSize));
                  const newObj = {
                    getSupporters: {
                      ...prev.getSupporters,
                      supporters : fetchMoreResult.getSupporters.supporters
                    }
                  };
                  //disable loading state
                  setLoading(false);
                  return Object.assign({}, prev, newObj);
                }
              })

          }}
        />
      </CardBody>

        <Fade
          in={selectedSupporters.length >= 2}
          out={(selectedSupporters.length <= 1).toString()}
          >
            <BottomActionBar show={selectedSupporters.length >= 2}>

              <Link
                to={{
                  pathname : `/supporter/household`,
                  state : {
                    supportersId : selectedSupporters,
                    view         : "houseHold"
                  }
                }}
                onClick ={ (e) => {
                if(selectedSupporters.length < 2) {
                  e.preventDefault()
                }}}
                className='btn btn-primary'>
                Create Household
              </Link>
              <Link
                to={{
                  pathname : `/supporter/merge`,
                  state : {
                    supportersId : selectedSupporters,
                    view         : "merge"
                  }
                }}
                onClick ={ (e) => {
                if(selectedSupporters.length < 2) {
                  e.preventDefault()
                }}}
                className='btn btn-primary'>
                Merge Supporters
              </Link>
            </BottomActionBar>
        </Fade>


    </div>
  );
};

export default SupporterTable;
