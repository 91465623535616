
import React, {useState, Fragment} from "react";
import {Modal, Form} from "react-bootstrap";
import ImportCollections from "./importCollections";
import {Button} from "shards-react";


const UploadButton = ({collection}) => {

  const [ showModal, setShowModal ] = useState(false);

  const [file, setFile] = useState();

  return <Fragment>
    <Button
      className="mr-1"
      onClick={() => setShowModal(true)}>
      Import <i className='material-icons'>file_upload</i>
    </Button>
    {/* This is the edit modal */}
    <Modal
      size="lg"
      backdrop={false}
      keyboard={false}
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload {collection}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImportCollections collection={collection} />
      </Modal.Body>
    </Modal>

  </Fragment>




}

export default UploadButton
