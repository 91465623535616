import React from "react";
import DonationActionsChart from
  "./../components/analytics/DonationActionsChart";
import ActionTable from "./ActionTable";
import Loading from "./Loading";

import SmallStats from "./../components/common/SmallStats";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader
}
from "shards-react";

import {
  useQuery
}
from '@apollo/react-hooks';
import gql from 'graphql-tag';

import numeral from "numeral";

import _ from 'underscore';

export const GET_CAMPAIGN = gql `
  query getCampaign($_id: ID) {
    getCampaign(_id: $_id) {
      _id
      nickname
      description
      mode
      stats
    }
  }
`;

const OTGLabel = {
  'card': "Credit Cards",
  'cheque': "Cheques"
};
const SubsLabel = {
  'card': "Credit Cards",
  'bank': "Bank Accounts"
};

const CampaignAnalytics = ({ campaignId }) => {

    const user = JSON.parse(localStorage.getItem("user"));
    const opts = {
      variables: {
        _id: campaignId
      },
      fetchPolicy: "network-only",
      refetchQueries: ['getCampaign']
    };

    const { data, loading, error } = useQuery(GET_CAMPAIGN, opts);

    if (!!loading) {
      return ( <Loading /> );
    }
    if (!_.contains(user.roles.campaign, "super-admin")) {
      return ( <p> Nothing to see here </p>);
    }

    const campaign = !!data && data.getCampaign || null;

    return (
      <Container fluid className = "main-content-container my-4">
        <Row>
        <Col md = "6" className = "mb-4" >
          <Card>
            <CardHeader>
              <h6>One Time Gifts</h6>
            </CardHeader>
            <Row className = "px-4">
              { ['cheque', 'card'].map((val) => (
                <Col className = "col-lg mb-4"
                     md = "6"
                     sm = "6"
                     key = {val} >

            <SmallStats
              id = {`small-stats-${val}`}
              variation = "1"
              label = {`${!!campaign && !!campaign.stats && !!campaign.stats.processed && campaign.stats.processed.OTG[val].count|| 0} ${OTGLabel[val]}`}
              value = {`$${!!campaign && !!campaign.stats && !!campaign.stats.processed && numeral(campaign.stats.processed.OTG[val].amount / 100).format('0,0.00') || 0}`}
              percentage = { null }
            />
            </Col>
          ))
        }
      </Row>
      <Row>
        <Col className = "col-lg mb-4 p-4" sm = "6">
          <DonationActionsChart
            data = { !!campaign && !!campaign.stats && campaign.stats.graphs.OTG || [] }
            colour = { "primary" }
          />
        </Col>
      </Row>
      </Card>
      </Col>
      <Col md = "6"
        className = "mb-3.9" >
        <Card>
        <CardHeader>
          <h6> Subscriptions </h6>
        </CardHeader>
        <Row className = "px-4" >
          { ['bank', 'card'].map((val) => (
            <Col className = "col-lg mb-4"
              md = "6"
              sm = "6"
              key = { val } >
            <SmallStats
              id = { `small-stats-${val}` }
              variation = "1"
              label = { `${!!campaign && !!campaign.stats && campaign.stats.processed.subscriptions[val].count|| 0} ${SubsLabel[val]}` }
              value = { `$${!!campaign && !!campaign.stats && numeral(campaign.stats.processed.subscriptions[val].amount /100).format('0,0.00') || 0}` }
              percentage = { null }
            />
            </Col>
          ))
        }
        </Row>
        <Row>
          <Col className = "col-lg mb-4 p-4" md = "6" sm = "6" >
            <DonationActionsChart
              data = { !!campaign && !!campaign.stats && campaign.stats.graphs.Subscriptions || [] }
              colour = { "danger" }
              />
          </Col>
        </Row>
        </Card>
        </Col>
      </Row>

        <ActionTable
          campaignId = { campaignId }
          campaignNickname = { !!campaign && campaign.nickname }
        />
        </Container>
      )
    }

    export default CampaignAnalytics;
