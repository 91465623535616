import React, {Fragment, useState, useEffect} from "react";
import gql from 'graphql-tag';
import _ from "underscore";

import { Link } from "react-router-dom";
import {
  Container,
  ButtonGroup,
  Button,
  FormCheckbox,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader
} from "shards-react";
import moment from "moment";
import numeral from 'numeral';
import getTransactionColumns from "../data/transaction-columns";
import CollectionTable from '../friendsComponents/CollectionTable';
import PageTitle from "../components/common/PageTitle";
import SetPageMetas from '../utils/SetPageMetas';

const Url = require('url-parse');


export const GET_ACTIONS = gql`
  query getActions($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject) {
    getActions(selector: $selector, limit: $limit, skip: $skip, sort: $sort) {
      count
      actions {
        _id
        type
        campaign
        supporterId
        date
        data
        method
        service
        supporter {
          firstName
          lastName
        }
      }
    }
  }
`;


const Exports = (props) => {

  const [tableView, setTableView] = useState("search");


  SetPageMetas({title: "Transactions"});

  const columns = [{
    Header: 'Date',
    className: "text-center d-flex align-items-center",
    accessor: 'date',
    Cell: row => {
      return moment(row.original.date).format("lll")
    }
  }, {
    Header: 'Supporter',
    accessor: 'supporter',
    className: "text-center",
    sortable: false,
    Cell: row => {
      if(row.original.supporterId === "orphan" || row.original.supporterId === "unmatch") {
        return row.original.supporterId
      }
      if (!!row.original.supporter.firstName && !!row.original.supporter.lastName) {
        return <Link to={`/supporter/${row.original.supporterId}`}>{`${row.original.supporter.firstName} ${row.original.supporter.lastName}`}</Link>;
      } else
      return row.original.supporterId
    }
  }, {
    Header: 'Billing Date',
    className: "text-center d-flex align-items-center",
    accessor: 'data.billingDate',
    Cell: row => {
      return !!row.original.data.billingDate && moment(row.original.data.billingDate).format("lll") || "";
    }
  }, {
    Header: 'Type',
    className: "text-center d-flex align-items-center",
    accessor: 'data.paymentType',
  }, {
    Header: 'Payment Method',
    className: "text-center d-flex align-items-center",
    accessor: 'data.paymentMethod'
  }, {
    Header: 'Amount',
    className: "text-center d-flex align-items-center",
    accessor: 'data.amount',
    Cell : row => {
      return `$${numeral(row.original.data.amount/100 ).format("0,0.00")}`
    }
  },{
    Header: 'Status',
    className: "text-center d-flex align-items-center",
    accessor: 'data.status'
  }, {
    Header: 'Campaign',
    className: "text-center d-flex align-items-center",
    Cell: row => {
      return !!row.original.campaign && !!row.original.campaign.nickname && <Link to={`/campaign/${row.original.campaign._id}`} className='btn btn-white btn-block'>{row.original.campaign.nickname}</Link>
    }
  }];

  const actionType = {
    'create'   : "Subscription",
    'process'  : "One Time Gift",
    'register' : "One Time Gift",
    'cancel'   : "Subscription"
  }
  const typePaymentMethod = {
    'bank_account' : "bank account",
    'credit_card'  : "credit card"
  }


  const createColumns = [{
    Header: 'Date',
    className: "text-center",
    accessor: 'ridingName',
    Cell: row => {
      return moment(row.original.date).format("lll")
    }
  }, {
    Header: 'Supporter',
    accessor: 'supporter',
    className: "text-center",
    Cell: row => {
      if (!!row.original.supporter.firstName && !!row.original.supporter.lastName) {
        return <Link to={`/supporter/${row.original.supporterId}`}>{`${row.original.supporter.firstName} ${row.original.supporter.lastName}`}</Link>;
      } else 
      return row.original.supporterId
    }
  }, {
    Header: 'Donation',
    accessor: 'method',
    className: "text-center",
    Cell: row => {
      console.log(row.original);
      return !!actionType[row.original.method] && actionType[row.original.method] || row.original.method || null;
    }
  }, {
    Header: 'Payment Method',
    className: "text-center",
    accessor: 'data.type',
    Cell: row => {
  
      return !!typePaymentMethod[row.original.data.type] && typePaymentMethod[row.original.data.type]  || row.original.data.paymentMethod === "card" && "credit card" || row.original.data.paymentMethod || null;
    }
  }, {
    Header: 'Action',
    className: "text-center",
    accessor: 'method'
  }, {
    Header: 'amount',
    className: "text-center",
    accessor: 'data.amount',
    Cell: row => {
      //if there is no amount to show return null
      if(!row.original.data || !row.original.data.amount) {
        return null
        //if the charge has failed, return declined
      } else if (!!row.original.data && !!row.original.data.status && row.original.data.status == "fail") {
        return "declined"
      }
      return <span>{`$${numeral(row.original.data.amount/100 ).format("0,0.00")}`}</span>
    }
  
  }, {
    Header: 'Campaign',
    className: "text-center d-flex align-items-center",
    Cell: row => {
      return !!row.original.campaign && !!row.original.campaign.nickname && <Link to={`/campaign/${row.original.campaign._id}`} className='btn btn-white btn-block'>{row.original.campaign.nickname}</Link>
    }
  }];

  const DateNow = new Date();

  const failedOpts = {
    variables: {
      selector : {
        service : "subscription",
        method : "failed",
        "data.paymentType" : "subscription",
        "data.status" : "failure"
      },
      limit    : 20,
      skip     : 0,
      sort     : { date : -1 },
      //hack using riding codes to ensure this actually reruns when the codes change
    },
    fetchPolicy : "network-only",
    onError(e) {
      console.log('fuckin error', e);
    }
  };

  const opts = {
    variables: {
      selector : {"data.paymentType" : {$exists : true}},
      limit    : 20,
      skip     : 0,
      sort     : { date : -1 },
      //hack using riding codes to ensure this actually reruns when the codes change
    },
    fetchPolicy : "network-only",
    onError(e) {
      console.log('fuckin error', e);
    }
  };

  return(
    <Container fluid className="main-content-container px-4">

      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Search" subtitle="Transactions" className="text-sm-left" />
      </Row>

      <ButtonGroup block className="btn-block mx-auto mb-3">
          <Button
            className="p-3"
            theme={tableView == "search" && "secondary" || "white"}
            onClick={() => setTableView("search")}>
            All Transactions
          </Button>
          <Button
            className="p-3"
            theme={tableView == "failed30" &&  "secondary" || "white"}
            onClick={() => setTableView("failed30")}>
            Failed subscriptions last 30 days
          </Button>
          <Button
            className="p-3"
            theme={tableView == "creations" && "secondary" || "white"}
            onClick={() => setTableView("creations")}>
            Subscriptions and One-Time Gifts
          </Button>
        </ButtonGroup>
      
      <Card small className="mb-4">
        
        {tableView === "search" && <CollectionTable
          collectionName='actions'
          gqlQuery={GET_ACTIONS}
          gqlQueryOpts={opts}
          columns={columns}
          viewProps={props}
          selector= {{
            service : {$in : ["subscription", "charge", "cheque"]},
            method : {$in : ["process", "failed", "success", "register"]}
          }}
          sort={{date : -1 }}
          displayButtons={["filter", "upload", "export"]}
          exportColumns={ getTransactionColumns() }
        /> || ""}

        {tableView === "failed30" && <CollectionTable
          collectionName='actions'
          gqlQuery={GET_ACTIONS}
          gqlQueryOpts={failedOpts}
          columns={columns}
          sort={{date : -1 }}
          selector= {{
            service : "subscription",
            method : "failed",
            "data.paymentType" : "subscription",
            "data.status" : "failure",
            "date" : {$gte : DateNow.setDate(DateNow.getDate()-30)}
          }}
          displayButtons={["export"]}
          exportColumns={ getTransactionColumns() }
        /> || ""}

        {tableView === "creations" && <CollectionTable
          collectionName='actions'
          gqlQuery={GET_ACTIONS}
          gqlQueryOpts={opts}
          columns={createColumns}
          sort={{date : -1 }}
          selector= {{
            service : {$in : ["subscription", "charge", "cheque"]},
            method : {$in : ["process", "create", "cancel", "register"]},
          }}
          displayButtons={[]}
          exportColumns={ getTransactionColumns() }
        /> || ""}

      </Card>
    </Container>
  )
}

export default Exports;
