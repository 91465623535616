import React, {useState, createContext} from "react";

let AccordionContext;
const {Provider, Consumer} = AccordionContext = createContext();

const AccordionProvider = (props) => {
    //this saves and sets the state of the accordion collapse
    const [active, toggleActive ] = useState(props.openOnLoad);

    return <Provider 
      value = {{
          active, 
          toggleActive
        }
      }
      >
      {props.children}
      </Provider>
    
}


export {AccordionProvider, Consumer as AccordionConsumer, AccordionContext};