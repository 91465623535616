/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect, Fragment } from "react";
import Pagination from "react-js-pagination";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  InputGroup,
  Button,
  Form,
  InputGroupAddon,
  FormInput,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "shards-react";
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import classNames from 'classnames';
import Loading from "../friendsComponents/Loading";
import moment from 'moment';
import { toast } from "react-toastify";


export const CREATE_ACTION = gql`
  mutation createAction($action: GenericObject) {
    createAction(action: $action) {
      _id
    }
  }
`;

const ActionTemplate = ({ actions, index, action, bgColour, iconColor, detailTextClass='text-light', descriptionTextClass='text-dark' }) => {
  const wrapperClasses = classNames(
    'user-activity__item',
    'pr-3',
    'py-3'
  );

  const showReportDetails = (action.service === 'subscription' && !!action.data.chargeInfo) ?
    <Fragment>
    <p className={descriptionTextClass}>Amount : {`$${!!action.data.chargeInfo.amount_due ? action.data.chargeInfo.amount_due / 100 : action.data.chargeInfo.amount_paid / 100}`}</p>
    {!!action.data.chargeInfo.next_payment_attempt ?
    <p className={descriptionTextClass}>Next payment attempt : {moment(action.data.chargeInfo.next_payment_attempt).format("lll")}</p>
    : ""}
    </Fragment>
    : "";

  const showComment = !!action.comment ?
    <p className={descriptionTextClass}>Comment : <i>"{action.comment}"</i> </p>
    : "";

  const showCampaign = !!action.campaign && !!action.campaign.nickname ?
    <p className={descriptionTextClass}>Campaign : {action.campaign.nickname} - {action.campaign.mode}</p>
    : "";

    const date = index === 0 && <div className={wrapperClasses} key={action._id}><strong className="px-4">{moment(actions[index].date).format("MMMM DD YYYY")}</strong></div> || !!moment(actions[index].date).isSame(actions[index-1].date, "day") && " " || <div className={wrapperClasses} key={action._id}><strong className="px-4">{moment(actions[index].date).format("MMMM DD YYYY")}</strong></div>
    
  return (
    <div>
      {/* {date} */}
      <div className={wrapperClasses} key={action._id}>

        {action.type === "comment" && 
          <div className="p-3" style={{backgroundColor: "#e4e4e4", width : "90%", borderRadius : "10px"}}>
          <div className="user-activity__item__content">
                <span style={{fontColor : "grey"}} className={detailTextClass}>{moment(action.date).format("LLL")} by {action.user.firstName} {action.user.lastName}</span>
                <p>{action.comment}</p>
          </div>
          
          </div>
         || <Fragment>
            <div className="user-activity__item__icon">
            <div style={{margin : "auto", marginTop: "5px", width : "20px", height : "20px" , borderRadius : "50%", backgroundColor: iconColor}}  className={`bg-${bgColour}`}></div>
            </div>
            <div className="user-activity__item__content">
              <span style={{fontColor : "grey"}} className={detailTextClass}>{moment(action.date).format("LLL")} by {action.user.firstName} {action.user.lastName}</span>
              <p className={descriptionTextClass}>{action.description}</p>
              {showCampaign}
              {showComment}
              {showReportDetails}
            </div>
         </Fragment>
        }
        

      </div>
    </div>

  );
}

//helps to determine the different colours to display for the different payment activities
const paymentMap = {
  'process' : 'success',
  'refund' : 'warning',
  'subscribe' : 'success',
  'cancel' : 'danger',
  "update-expiry" : "info",
  'add' : 'primary',
  'success' : 'success',
  'failed' : 'warning'
}



const actionMap = {
  'comment'(actions, i) {
    return (<ActionTemplate
      index={i}
      actions={actions}
      key={actions[i]._id}
      iconColor={"grey"}
      action={actions[i]}
      icon={'how_to_reg'}
      detailTextClass={'text-dark'}
    />)
  },
  'opt-in'(actions, i) {
    return (<ActionTemplate
      index={i}
      actions={actions}
      key={actions[i]._id}
      iconColor={"blue"}
      bgColour={'success'}
      action={actions[i]}
      icon={'how_to_reg'}
      detailTextClass={'text-dark'}
    />)
  },
  'opt-out'(actions, i) {
    return (<ActionTemplate
      index={i}
      actions={actions}
      iconColor={"red"}
      key={actions[i]._id}
      bgColour={'danger'}
      action={actions[i]}
      icon={'unsubscribe'}
      detailTextClass={'text-dark'}
    />)

  },
  'update-supporter'(actions, i) {
    return (<ActionTemplate
      index={i}
      actions={actions}
      iconColor={"blue"}
      key={actions[i]._id}
      bgColour={'info'}
      action={actions[i]}
      icon={'person'}
      detailTextClass={'text-dark'}
    />)
  },
  'supporter'(actions, i) {
    return (<ActionTemplate
      index={i}
      actions={actions}
      iconColor={"blue"}
      key={actions[i]._id}
      bgColour={'info'}
      action={actions[i]}
      icon={'person'}
      detailTextClass={'text-dark'}
    />)
  },
  'payment'(actions, i) {
    return (<ActionTemplate
      index={i}
      actions={actions}
      key={actions[i]._id}
      iconColor={"green"}
      bgColour={!!actions[i].data.status && (actions[i].data.status === "fail" || actions[i].data.status === "failure" )&& "danger" || paymentMap[actions[i].method]}
      action={actions[i]}
      icon={'payment'}
      detailTextClass={'text-dark'}
    />)
  },
  'bank-run-item'(actions, i) {
    return (<ActionTemplate
      index={i}
      actions={actions}
      key={actions[i]._id}
      iconColor={!!actions[i].data.status && actions[i].data.status === "declined" && "red" || "green"}
      action={actions[i]}
      icon={'payment'}
      detailTextClass={'text-dark'}
    />)
  },
  'email'(actions, i) {
    return (<ActionTemplate
      index={i}
      actions={actions}
      key={actions[i]._id}
      bgColour={'info'}
      iconColor={"yellow"}
      action={actions[i]}
      icon={'payment'}
      detailTextClass={'text-dark'}
    />)
  }
}


const selectorMap = {
  "All Items"       : {},
  "Payments"        : {type : "payment"},
  "Comments"        : {type : "comment"},
  "Profile Updates" : {type : "supporter"}
}



const SupporterActivity = ({ loading, actions, supporterId, setSelector, limit, setLimit, sort, setSort, setSkip}) => {
  
  const user = JSON.parse(localStorage.getItem('user'));
  const token = !!user && user.token || "";

  const headers ={
    authorization: `Bearer ${token}`,
    user : JSON.stringify(user)
  };

  const req = {
    url : `fakeurl/comment/add`,
    headers,
    body : {}
  }

  const [activePage, setActivePage] = useState(1);
  const [menu, setMenu] = useState(false);
  const [selected, setSelected] = useState("All Items");
  const [displayActions, setDisplayActions] = useState(!!actions && actions || {count : 0, actions : []});
  

  const [actionComment, setActionComment] = useState({
    req,
    supporterId,
    type        : "comment",
    description : "comment by user",
    comment     : "",
  })

  useEffect(()=>{
    // setLoading(false);
    if(actions.count !== displayActions.count || !!actions.actions && !!actions.actions.length && actions.actions[0]._id !== displayActions.actions[0]._id) {
      setDisplayActions(actions);
      
    }
    
  })


  const toggle = () => setMenu(!menu);

  const [actionCount, setActionCount] = useState(0);

  const changeSelection = (selection) => {
    setSkip(0);
    setSelected(selection);
    // setLoading(true);
    return setSelector(selectorMap[selection]);
  }


  if ( actionCount !== actions.count ) {
    setActionCount( actions.count );
  }


  const opts = {
    refetchQueries : ["getSupporter", "getSupporters"],
    onCompleted : () => {
      toast.success("Comment saved");
      // setLoading(false);
    },
    onError : (e) => {
      // setLoading(false);
      for (const error of e.graphQLErrors) {
        //check if the error is within GraphQL or sent back from the server
        // in order to retirce the error message at the right place
        const errorMessage = error.extensions.response ?
        error.extensions.response.body.error.message :
        error.message;
        toast.error(errorMessage);
      }
    }
  }
 
  const [createAction, { data, error }] = useMutation(CREATE_ACTION , opts );
  console.log(error)
  

  return (
    <Card small className="user-activity mb-4">
      <CardHeader className="d-flex justify-content-between">
        <strong style={{fontSize : "1.5em"}}className="m-1">Supporter Actions</strong>
        <Dropdown open={menu} toggle={toggle} >
          <DropdownToggle nav split>{`${selected} `}</DropdownToggle>
          <DropdownMenu right>
            {Object.keys(selectorMap).map(item => {
              return <DropdownItem onClick={() => changeSelection([item])} key={[item]}>{[item]}</DropdownItem>
            })}
          </DropdownMenu>
        </Dropdown>
      </CardHeader>
      <CardBody className="p-0">
        <Form>
          <InputGroup className="px-3">
            <FormInput value={actionComment.comment} size="lg" placeholder="Leave a comment..." onChange={(e) => setActionComment({...actionComment, comment : e.target.value})} />
            <InputGroupAddon type="append">
              <Button 
                type="submit"
                theme="white"
                onClick={(e) => {
                  e.preventDefault();
                  if(!actionComment.comment.length) {
                    return;
                  }
                  if(actionComment.comment.length < 5) {
                    return toast.warning("Your comment is too short (less than 5 characters)")
                  }
                  createAction({
                    variables : {
                      action : actionComment 
                  }});
                  // setLoading(true);
                  setActionComment({...actionComment, comment : ""})
                
                }}
                >Post</Button>
            </InputGroupAddon>

          </InputGroup>
        </Form>


          {/* <Row className='py-3 pr-3 bg-light border-bottom mx-0'>
          <Col sm="8" className="col d-flex mb-2 mb-sm-0">
            <ButtonGroup>
              <Button theme="white" active={sort == -1} onClick={(e) => {
                setSort( {date : -1} )
                setActivePage( 1 );
                setSkip( 0 );
              }}>
                Newest first
              </Button>
              <Button theme="white" active={sort == 1} onClick={(e) => {
                setSort( {date : 1 } );
                setActivePage( 1 );
                setSkip( 0 );
              }}>
                Oldest first</Button>
            </ButtonGroup>
          </Col>
          <Col sm="4" className="col justify-content-right">

            <FormSelect
              size="sm"
              value={limit}
              style={{ maxWidth: "130px" }}
              onChange={(e) => { setLimit( parseInt(e.target.value) ) }}
            >
              <option value="5">5 per page</option>
              <option value="10">10 per page</option>
              <option value="20">20 per page</option>
            </FormSelect>
          </Col>
        </Row> */}

         {!!loading && <Loading /> || 
            !!displayActions.actions && displayActions.actions.map((action, i) => {
            if ( !!actionMap[action.type] )
            return actionMap[action.type](displayActions.actions, i);
          })}

      </CardBody>
      <CardFooter className="border-top">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={limit}
          totalItemsCount={actionCount}
          pageRangeDisplayed={5}
          innerClass={'pagination justify-content-center'}
          itemClass={'page-item'}
          linkClass={'page-link'}
          onChange={(val) => {
            setActivePage( val );
            setSkip( ( val - 1 ) * limit  );
          }}
        />
      </CardFooter>
    </Card>
  )
};

export default SupporterActivity;
