import React, { useState, Fragment } from 'react';
import _ from 'underscore';

import {
  Row,
  Col,
  FormCheckbox,
  Collapse
} from "shards-react";


//import all widgets
import SelectOperator from './SelectOperator';
import QBText from './widgets/text';
import QBNumber from './widgets/number';
import QBDate from './widgets/date';
import QBSelect from './widgets/select';
import QBMultiSelect from './widgets/multiselect';
import QBBoolean from './widgets/boolean';

import { detectOperator } from './util';


//stash them in an object
const widgets = {
  text   : QBText,
  number : QBNumber,
  date   : QBDate,
  boolean: QBBoolean,
  select : QBSelect,
  multiselect: QBMultiSelect
};


const FieldRow = ({keyName, operators, selector, setSelector, field, triggerSubmit }) => {

  //internal stores for operator, whether the field is active, and its current value
  const [ operator, setOperator ] = useState( _.has( selector, keyName ) && detectOperator( selector[keyName] ) || field.defaultOperator || "equals");

  //active is true if the field is currently used in selector. Second condition checks for nested keys
  const [ active      , toggleActive ] = useState( _.has( selector, keyName ) || !!_.keys( selector ).filter( k => k.includes(keyName + '.') ).length  );
  const [ currentValue, setCurrentValue ] = useState( _.has( selector, keyName ) && selector[keyName] || null );
  const FieldWidget = widgets[field.type];

  const widgetsToRender = operator == 'between' && 2 || 1;

  function removeFromSelector() {
    setCurrentValue( null );
    //modify top level selector
    setSelector(_ .omit( selector, keyName ));
  }

  return (
    <Row>
      <Col>
        <FormCheckbox
          style={{fontWeight: 'bold'}}
          checked={ active }
          className={ !active && 'mb-0' || ''  }
          onChange={ (e) => {
            //if inactive (i.e. we are activating), reset the selector to use currentValue if any
            if ( !active && currentValue !== null ) {
              setSelector({ ...selector, [keyName]: currentValue });
            } else if ( _.has( selector, keyName ) ) {
              //otherwise if the selector has a value for this key, remove it
              setSelector( _.omit( selector, keyName ) );
            }

            //set internal state for activity
            toggleActive( !active );
          }}>
          { field.label }
        </FormCheckbox>

        <Collapse open={active} timeout={0}>

          <div style={{'paddingLeft': '1rem'}}>
            {/* Don't show operators for booleans or nested fields */}
            {
              ['object', 'boolean'].includes(field.type) === false ? <SelectOperator field={field} keyName={keyName} selector={selector} setSelector={setSelector} operator={operator} setOperator={setOperator} operators={operators}/> : ""
            }

            {/* If nested field, call FieldRow recursively */}
            { field.type == 'object'  ?
                Object.keys(field.properties).map(subkey => {
                  return <FieldRow
                            key={subkey}
                            keyName={`${keyName}.${subkey}`}
                            operators={operators}
                            selector={selector}
                            setSelector={setSelector}
                            field={field.properties[subkey]}
                            triggerSubmit={triggerSubmit}
                          />
                })
            :

            // Otherwise call normaly, one instance for most operators, two if using "between"
              <div className='d-flex align-items-center'>
                { operator !== 'between' && <i className="fas fa-share fa-rotate-90 fa-flip-vertical"></i> || ''}
                {
                  _.range( 0, widgetsToRender ).map( i => {
                     return (
                       <Fragment key={`inner-frag-${keyName}-${i}`}>
                       { operator == 'between' && i == 1 && <span className='mx-2'>and</span>  || ''}
                       <FieldWidget
                         key={`${keyName}-field-widget-${i}`}
                         field={field}
                         onKeyPress={(e) => {
                           alert('i pushed')
                         }}
                         keyName={keyName}
                         operator={operator}
                         isMinVal={ operator == 'between' && i == 0 }
                         isMaxVal={ operator == 'between' && i == 1 }
                         operators={operators}
                         selector={selector}
                         setSelector={setSelector}
                         currentValue={currentValue}
                         setCurrentValue={setCurrentValue}
                         removeFromSelector={removeFromSelector}
                         triggerSubmit={triggerSubmit}
                       />
                       </Fragment>
                     );
                   })
                 }
              </div>
            }


          </div>
        </Collapse>
      </Col>

    </Row>
  );
}

export default FieldRow;
