import React from "react";
import {
  Col,
  Row
} from "shards-react";

const numbers = {
  0 : <span style={{fontSize : "1.5em"}}>&#9312;</span>,
  1 : <span style={{fontSize : "1.5em"}}>&#9313;</span>,
  2 : <span style={{fontSize : "1.5em"}}>&#9314;</span>,
  3 : <span style={{fontSize : "1.5em"}}>&#9315;</span>,
  4 : <span style={{fontSize : "1.5em"}}>&#9316;</span>,
}


const ShowSteps = ({steps = [], actualStep, setJumpTo}) => {
  const number = <span>{}</span>
    return <Row>
    <Col className="text-center" xs ={2}>
    {steps.map((step, i) => {
        return <div key={"step-color" + i}>
          <div style={{color : `${i <= actualStep && "#33cc33" || "darkGrey"}`, height: "15px"}}>•</div>
          <div style={{color : `${i <= actualStep && "#33cc33" || "darkGrey"}`}}>{numbers[i]}  </div>
          <div style={{color : `${i < actualStep && "#33cc33" || "darkGrey"}`, height: "15px"}}>•</div>
        </div>
    })}
    </Col>
    
    <Col xs={10}>
    {steps.map((step, i) => {
        return <div key = {"step-" + i}>
          <div style={{height: "15px"}}></div>
          <div >
            <span 
              onClick= { () => {
                if(i > 0 && i !== actualStep && actualStep > 0) {
                  setJumpTo(i)
                }
              }}
              style={{
                cursor: `${i > 0 && i !== actualStep && actualStep > 0 && "pointer"}`, 
                fontSize : "1.5em", 
                color :`${i <= actualStep && "black" || "darkGrey"}`}}>
              {step.name} 
            </span>
          </div>
          <div style={{height: "15px"}}></div>
        </div>
    })}
    </Col>
    </Row>

}


export default ShowSteps