import React, {useState} from "react";
import Loading from "./Loading";
import {Mutation} from "react-apollo";
import { toast } from 'react-toastify';

import gql from 'graphql-tag';

import {
  Row,
  Col,
  CardBody,
  Button,
  FormInput
} from "shards-react";


export const UPDATE_EXP = gql`
  mutation updateExpiry($supporterId: String, $cardId: String, $exp_month: Int, $exp_year: Int) {
    updateExpiry(supporterId: $supporterId, cardId: $cardId, exp_month: $exp_month, exp_year: $exp_year) {
      id
      exp_month
      exp_year
    }
  }
`;

const UpdateExpiry = ({actualMonth, actualYear, supporterId, cardId, handleClose}) => {
  const [month, setMonth] = useState(actualMonth)
  const [year, setYear] = useState(actualYear)

  return (
    <Mutation
      mutation={UPDATE_EXP}
      onError={e => { 
        toast.error(e.message); console.log({e}); 
        for (const error of e.graphQLErrors) {
          //check if the error is within GraphQL or sent back from the server
          // in order to retirce the error message at the right place
          const errorMessage = error.extensions.response ?
          error.extensions.response.body.error.message :
          error.message;
          toast.error(errorMessage);
        }
        // const errorMessage = !!e.extensions.response && e.extensions.response.body.error.message || e.message;
        // toast.error(`Problem updating expiry - ${errorMessage}`); console.log(e); 
      }}
      onCompleted={({update})=> {
        toast.success("card updated");
        handleClose()
      }}>
      {(update, {data, loading, error}) => {

        if (loading) return(
          <Loading />
        );
        if (error) return <p>ERROR: {error.message}</p>;

        return (
          
            <CardBody>
              <Row>
                <Col>
                  <FormInput 
                    id="month"
                    type="text"
                    value={month}
                    placeholder="MM"
                    onChange={e => setMonth(e.target.value)}
                  />
                </Col>
                <Col>
                  <FormInput 
                    id="year"
                    type="text"
                    value={year}
                    placeholder="YYYY"
                    onChange={e => setYear(e.target.value)}
                  />
                </Col>
                <Col>
                  <Button onClick={() => {

                 
                    return update({variables: 
                    {supporterId,
                    cardId,
                    exp_month: parseInt(month),
                    exp_year: parseInt(year)
                    },
                    refetchQueries: ['getSupporter']})
                  }
                  }>Update</Button>
                </Col>
              </Row>

            </CardBody>
      
        )    
      }}
    </Mutation>

  )
}

export default UpdateExpiry;