import React, { useState, Fragment, useEffect } from "react";
import Loading from "../friendsComponents/Loading";
import {Modal} from "react-bootstrap"
import {Accordion, AccordionBody, AccordionHeader} from "../friendsComponents/Accordion";
import PaymentMethods from "../friendsComponents/PaymentMethods";
import Contributions from "../friendsComponents/Contributions";
import {EmailsComponent} from "../friendsComponents/EmailsComponent";
import ContactPreferenceTags from "../friendsComponents/ContactPreferences";
import { Link } from "react-router-dom";
import {
  SUPPORTER_DETAILS,
  CUSTOMER_INFO,
  CHARGES,
  SMS_CONVERSATION,
  BANK_ACCOUNT
} from "../fragments/graphqlFragments";
import { toast } from 'react-toastify';

import InlineTitleButton from "../friendsComponents/InlineTitleButton";

import {
  Container,
  Button,
  Row,
  Col,
  Card
} from "shards-react";
import { Tabs, Tab } from 'react-bootstrap';
import SupporterDetails from "../friendsComponents/SupporterDetails";
import SupporterActivity from "../friendsComponents/SupporterActivity";
import ShowSupporterDetails from "./ShowSupporterDetails";
import Conversation from "./Conversation";
import Payments from "./Payments";
import SupporterContacttags from "../friendsComponents/SupporterContactTags";
import ManageHousehold from "../friendsComponents/ManageHousehold";
import Charges from "../friendsComponents/Charges";
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import _ from 'underscore';
const Url = require('url-parse');


export const GET_SUPPORTER = gql`
  query getSupporter($supporterId: String, $chargeLimit: Int, $actionSelector: Selector, $actionLimit: Int, $actionSkip: Int, $actionSort: GenericObject) {
    getSupporter(supporterId: $supporterId) {
      ...SupporterDetails
      householdMembers {
        _id
        firstName
        lastName
      }
      actions(actionSelector: $actionSelector, actionSkip: $actionSkip, actionLimit : $actionLimit, actionSort : $actionSort) {
        count
        actions {
          _id
          date
          supporterId
          prospectId
          note
          comment
          service
          campaign
          data
          type
          user
          description
          method
        }
      }
      customerInfo {
        ...CustomerInfo
        charges(supporterId: $supporterId, limit: $chargeLimit) {
          ...Charges
        }
      }
      bankAccounts {
        ...BankAccounts
      }

    }
  }
  ${SUPPORTER_DETAILS}
  ${CUSTOMER_INFO}
  ${BANK_ACCOUNT}
  ${CHARGES}
`;

//TODO when readding SMS Conversation Card, manage users that still need to see supporter profile but can't have access to sms
// SMSConversation {
//   ...SMSConversation
// }

function parseContactKeyName(key) {
  const keyMap = {
    okMail: 'Mail',
    okCall: 'Call',
    okFund: 'Fund',
    okSMS:  'SMS',
    okContact: 'Contact',
    optInEn: 'English Opt-In',
    optInFr: 'French Opt-In',
    'okEmail': 'Email',
    'okNewsletter' : 'Newsletter',
    'okTrade' : 'Trade',
    'okSurveys' : 'Surveys',
    'okEvents' : 'Events',
    'okPremiums' : 'Premiums',
    'okContactRegular' : 'Contact Regular'
  }

  return !!keyMap[key] && keyMap[key];
}

const warningPreferences = (supporter) => {
  console.log({supporter})
  let warningToShow = [];
  const FieldsToShow = ["okCall", "okMail", "okTrade", "okContact"]
  FieldsToShow.forEach((field, i) => {
    if(supporter.contactPreferences[field] === false) {
      warningToShow.push(<span key={`${field}${i}`}>{!!warningToShow.length && "|" || ""} <i className={"fas fa-times"}></i> No {parseContactKeyName(field)} </span>)
    }
  })
  return warningToShow;
}

const Supporter = ({match, location, history}) => {
  //gets the supporterId from the route
  const supporterId = match.params.id;

  const user = JSON.parse(localStorage.getItem('user'));
  const [actionSelector, setActionSelector] = useState({});
  const [actionLimit, setActionLimit]       = useState(5);
  const [actionSkip, setActionSkip]         = useState(0);
  const [actionSort, setActionSort]         = useState({date : -1});

  const [chargesLimit, setChargesLimit] = useState(50);


  //modal household
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  let params = new Url(location.search, true);

  // if there is a view passed in the parameters, get it or display 'main' by default
  const opts = {
    variables : {
      supporterId,
      actionSelector,
      actionLimit,
      actionSkip,
      actionSort
    },
    fetchPolicy: "network-only"
  }
  
  console.log(`supporterId: ${supporterId}`);
  const {data, loading, error} = useQuery(GET_SUPPORTER, opts);


  console.log(`error start`);
  console.log(error);
  console.log(`error emd`);

  console.log(`data start`);
  console.log(data);
  console.log(`data emd`);

  const supporter = !!data && !!data.getSupporter && data.getSupporter;

  console.log(`XXXXXXXXXX`);
  console.log(supporter);

  console.log(`XXXXXXXXXXXA`);
  document.title = !!supporter && `${supporter.firstName} ${supporter.lastName} -- FCB Control` || "Loading...";

  if(!supporter && !!loading) {
    return <Loading/>
  }

  let showHouseholdMembers;
  if(!!supporter.householdMembers && !!supporter.householdMembers.length) {
    showHouseholdMembers = supporter.householdMembers.map((s, index) => {
      return <Link key={s._id} to={`/supporter/${s._id}?view=main`}>{s.firstName} {s.lastName}{index < (supporter.householdMembers.length - 1) && ", " || " "}</Link>
    });
  }

  let defaultCard = !!supporter.customerInfo
    && !!supporter.customerInfo.sources
    && !!supporter.customerInfo.sources.length
    &&  supporter.customerInfo.sources.filter(card => card.id === supporter.customerInfo.default_source)
    || "";


  return(
    <Container fluid className="main-content-container p-4">
      <Row className="justify-content-between mx-3">
        <h3>{!supporter.isDeceased && supporter.prefix} {supporter.firstName} {supporter.lastName} {!!supporter.isDeceased && "(deceased)"} {!!supporter.mergedInto && !!supporter.mergedInto.length &&  <Link key={supporter.mergedInto[0]} to={`/supporter/${supporter.mergedInto[0]}?view=main`}>(supporter merged)</Link>}</h3>
        <h5>{showHouseholdMembers}{!!supporter.householdMembers && !!supporter.householdMembers.length && <Button outline onClick={() => {handleShowModal()}}><i className="material-icons">home</i> Edit</Button>}  </h5>

        {/* Household Modal */}
        <Modal
          size="md"
          // centered
          show={showModal}
          onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Manage household</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ManageHousehold
              supporter={supporter}
              handleClose={handleCloseModal}
            />
          </Modal.Body>
        </Modal>
      </Row>
      <Row>
        <Col md="12" lg="8">
          <ShowSupporterDetails
            supporterData={supporter}
            changeView ={false}
          /> 
          {/* awk */}
          <Accordion
            openOnLoad={true}>
            <AccordionHeader
              title="Emails2"
            />
            <AccordionBody
              table={true}>
              <EmailsComponent
                supporterEmails={ !!supporter && supporter.emails || [] }
                supporterId={ !!supporter && supporter._id || null }
                // supporter={supporter || null}
               />
            </AccordionBody>
          </Accordion>
          <Accordion>
            <AccordionHeader
              title="Contact Preferences"
              content={!!supporter && warningPreferences(supporter)}
            />
            <AccordionBody>
              <ContactPreferenceTags
                supporter={!!supporter && supporter}
              />
            </AccordionBody>
          </Accordion>
              <PaymentMethods
                openOnLoad={true}
                supporter={supporter}
              />
          <Accordion
            openOnLoad={true}>
            <AccordionHeader
              title="Contribution History"
            />
            <AccordionBody
              table={true}>
              <Charges
                supporter={!!supporter && supporter || {}}
                hideHeader={true}
              />
            </AccordionBody>
          </Accordion>
        </Col>
        <Col md="12" lg="4">
          <Contributions
            supporter={supporter}
            user={user}
          />
          <SupporterActivity
            loading={!!loading}
            actions={!!supporter && supporter.actions}
            supporterId={!!supporter && supporter._id}
            limit={actionLimit}
            skip={actionSkip}
            sort={actionSort}
            setSelector={setActionSelector}
            setLimit={setActionLimit}
            setSort={setActionSort}
            setSkip={setActionSkip}
          />
        </Col>

      </Row>
    </Container>
  )


}

export default Supporter;
