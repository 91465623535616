import React, {useState}from 'react';
import {Button, Form, Row, Col} from 'react-bootstrap';



const MessageBox = ({message, handleChangeMessage, disabled, title}) => {


  const countCharacters = () => {
    return message.length
  }


    return (

      <Col >
        <Form.Label className="d-flex justify-content-between">
          <span>{title}</span>
          <span>{countCharacters()}</span>
        </Form.Label>
        <Form.Control as="textarea" disabled={disabled} value={message} onChange={e => handleChangeMessage(e.target.value)}  />

      </Col>
      );

}

export default MessageBox;
