import React, { Component, Fragment } from "react";
import _ from 'underscore';
import validator from "validator";
import { toast } from 'react-toastify';
import {
  Button,
  Row,
  Col,
} from "shards-react";

import ConfirmationEmail from '../../../friendsComponents/ConfirmationEmail.js';

export default class CampaignStepReview extends Component {

  constructor(props) {
    super(props);

    this.state = {
      emailOverride: null
    };
    this.props.setStep(4)
  }

  getEmail() {
    if ( !!this.state.emailOverride ) return this.state.emailOverride;

    const { emails } = this.props.store.supporter;

    if ( !!emails && !!emails.length ) {
      const primary = _.findWhere( emails, { isPrimary: true } );
      return !!primary && primary.address || emails[0].address;
    }

    return null;
  }

  render() {
    return(
      <Fragment>
        {/* {!! this.props.store.gifts.length ?
          <Row className="justify-content-md-center">
            <Col md={{ span: 6 }} className='mb-3'>
              <ConfirmationEmail
                firstName={this.props.store.supporter.firstName}
                address={ this.getEmail() }
                gifts={ this.props.store.gifts }
              />
            </Col>
          </Row>
      : ""} */}
      <Row className="justify-content-md-center">
        <Col md={{ offset: 3, span: 6 }}>
          <Button
            className={"mb-2 btn-block"}
            onClick={() => {
              this.props.setSupporter({});
              // this.props.resetGifts();
              this.props.jumpToStep(0);
          }}>Process Next Donation</Button>
        </Col>
        <Col md="3">
       </Col>
      </Row>
    </Fragment>
    )
  }


}
