import React from 'react';
import moment from 'moment';
import Loading from "./Loading";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import './Messages.scss';

import {Mutation} from "react-apollo";
import gql from 'graphql-tag';

export const MARK_AS_READ = gql`
  mutation updateMessage($selector: Selector, $modifier: Modifier){
    updateMessage(selector: $selector, modifier: $modifier){
      n
      nModified
      ok
    }
  }
`;

export const UPDATE_PROSPECT = gql`
mutation updateProspect($selector: Selector, $modifier: Modifier){
    updateProspect(selector: $selector, modifier: $modifier){
      n
      nModified
      ok
    }
  }
`;

const Message = ( {messageId, message, direction, from, date, status, id, conversation = false, prospect = false }) => {
  //if the messages are supposed to be displayed in the inbox, include the link to show the conversation
  const title = !conversation  ?
    <Link to={`/supporter/${id}?view=sms`}>
      <div className="clickable justify">
        <span>From :{from} </span>
        <span>Show the conversation</span>
      </div>
    </Link>
    : <span></span>;

  // markAsRead allows the user to mark a message as read
  const markAsRead = (status === 'unread') ?
    <Mutation
      mutation={MARK_AS_READ}
      onCompleted={({markAsRead}) => {
        toast.success(`Message marked as read`);
      }}
      onError={e => {
        for (const error of e.graphQLErrors) {
          //check if the error is within GraphQL or sent back from the server
          // in order to retirce the error message at the right place
          const errorMessage = error.extensions.response ?
          error.extensions.response.body.error.message :
          error.message;
          toast.error(errorMessage);
        }
      }}
      >
      {(markAsRead, {data, loading, error}) => {
        if ( !!loading ) {
            return <Loading />
        }

        return(
          <span className="clickable" onClick={()=> {
            markAsRead({
              variables : {
                selector : {
                  _id : messageId
                },
                modifier : {
                  status : "read"
                }
              },
              refetchQueries : ['getSupporter', 'getProspect', 'getInboxCount']
            });
          }}>mark as read</span>
        )
      }}

    </Mutation>
    : '';

    const typeMessage = !!prospect ?

      <Mutation
        mutation={UPDATE_PROSPECT}
          onCompleted={({update}) => {
            toast.success({update});
          }}
        onError={e => {

          for (const error of e.graphQLErrors) {
            //check if the error is within GraphQL or sent back from the server
            // in order to retirce the error message at the right place
            const errorMessage = error.extensions.response ?
            error.extensions.response.body.error.message :
            error.message;
            toast.error(errorMessage);
          }
        }}
        >
        {(updateProspect, {data, loading, error}) => {
          if ( !!loading ) {
              return <Loading />
          }

          return(
            <span className="clickable" onClick={()=> {
              updateProspect({
                variables : {
                  selector : {
                    _id : id
                  },
                  modifier : {
                    response : "positive"
                  }
                },
                refetchQueries : ['getProspect']
              })
            }}>Mark as Positive</span>
          )
        }}

      </Mutation>
      : '';


  //depending if the message is incoming or outgoing, defines the style and where the message is supposed to be displayed

  const colourClasses = classNames(
    'messages',
    {
      'yours': direction == 'incoming',
      'mine': direction == 'outgoing'
    },
    { [`status-${status}`]: true }
  );
  const alignmentClasses = classNames(
    'mb-2',
    'text-muted',
    {
      'alignLeft': direction == 'incoming',
      'alignRight': direction == 'outgoing'
    }
  )

  return (
    <div className="boxDisplay p-2" key={messageId}>
      <div className={alignmentClasses}>
      {title}
        </div>
        <div className={colourClasses}>
          <div className="message">
            <div>{message}</div>
            <div className="d-flex justify">
              <div>
                <strong>{status}</strong>
                {markAsRead}
              </div>
            <div className="px-2">{moment(date).fromNow()}</div>
            </div>
          </div>
        </div>

    </div>
  )
}

export default Message;
