import _ from 'underscore';

export const supporterFromAction = function(action) {
  let result = {
    _id              : action._id,
    lastUpdated      : action.date,
    householdMembers : [] //need this or mergeview won't be happyy
  };


  if( action.type == 'update-one' ) {
    const changedKeys = _.without( Object.keys( action.requestBody.modifier.$set ), 'lastUpdated' );
    _.extend( result, _.pick( action.priorState, ...changedKeys ) );
  }

  return result;
}
