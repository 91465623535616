const ridingMap = new Map([
  [
    "Avalon", 10001
  ],
  [
    "Bonavista–Burin–Trinity", 10002
  ],
  [
    "Coast of Bays–Central–Notre Dame", 10003
  ],
  [
    "Labrador", 10004
  ],
  [
    "Long Range Mountains", 10005
  ],
  [
    "St. John's East", 10006
  ],
  [
    "St. John's South–Mount Pearl", 10007
  ],
  [
    "Cardigan", 11001
  ],
  [
    "Charlottetown", 11002
  ],
  [
    "Egmont", 11003
  ],
  [
    "Malpeque", 11004
  ],
  [
    "Cape Breton–Canso", 12001
  ],
  [
    "Central Nova", 12002
  ],
  [
    "Cumberland–Colchester", 12003
  ],
  [
    "Dartmouth–Cole Harbour", 12004
  ],
  [
    "Halifax", 12005
  ],
  [
    "Halifax West", 12006
  ],
  [
    "Kings–Hants", 12007
  ],
  [
    "Sackville–Preston–Chezzetcook", 12008
  ],
  [
    "South Shore–St. Margarets", 12009
  ],
  [
    "Sydney–Victoria", 12010
  ],
  [
    "West Nova", 12011
  ],
  [
    "Acadie–Bathurst", 13001
  ],
  [
    "Beauséjour", 13002
  ],
  [
    "Fredericton", 13003
  ],
  [
    "Fundy Royal", 13004
  ],
  [
    "Madawaska–Restigouche", 13005
  ],
  [
    "Miramichi–Grand Lake", 13006
  ],
  [
    "Moncton–Riverview–Dieppe", 13007
  ],
  [
    "New Brunswick Southwest", 13008
  ],
  [
    "Saint John–Rothesay", 13009
  ],
  [
    "Tobique–Mactaquac", 13010
  ],
  [
    "Abitibi–Baie-James–Nunavik–Eeyou", 24001
  ],
  [
    "Abitibi–Témiscamingue", 24002
  ],
  [
    "Ahuntsic-Cartierville", 24003
  ],
  [
    "Alfred-Pellan", 24004
  ],
  [
    "Argenteuil–La Petite-Nation", 24005
  ],
  [
    "Avignon–La Mitis–Matane–Matapédia", 24006
  ],
  [
    "Beauce", 24007
  ],
  [
    "Beauport–Limoilou", 24008
  ],
  [
    "Bécancour–Nicolet–Saurel", 24009
  ],
  [
    "Bellechasse–Les Etchemins–Lévis", 24010
  ],
  [
    "Beloeil–Chambly", 24011
  ],
  [
    "Berthier–Maskinongé", 24012
  ],
  [
    "Thérèse-De Blainville", 24013
  ],
  [
    "Pierre-Boucher–Les Patriotes–Verchères", 24014
  ],
  [
    "Bourassa", 24015
  ],
  [
    "Brome–Missisquoi", 24016
  ],
  [
    "Brossard–Saint-Lambert", 24017
  ],
  [
    "Rimouski-Neigette–Témiscouata–Les Basques", 24018
  ],
  [
    "Charlesbourg–Haute-Saint-Charles", 24019
  ],
  [
    "Beauport–Côte-de-Beaupré–Île d'Orléans–Charlevoix", 24020
  ],
  [
    "Châteauguay–Lacolle", 24021
  ],
  [
    "Chicoutimi–Le Fjord", 24022
  ],
  [
    "Compton–Stanstead", 24023
  ],
  [
    "Dorval–Lachine–LaSalle", 24024
  ],
  [
    "Drummond", 24025
  ],
  [
    "Gaspésie–Les Îles-de-la-Madeleine", 24026
  ],
  [
    "Gatineau", 24027
  ],
  [
    "Hochelaga", 24028
  ],
  [
    "Honoré-Mercier", 24029
  ],
  [
    "Hull–Aylmer", 24030
  ],
  [
    "Joliette", 24031
  ],
  [
    "Jonquière", 24032
  ],
  [
    "La Pointe-de-l'Île", 24033
  ],
  [
    "La Prairie", 24034
  ],
  [
    "Lac-Saint-Jean", 24035
  ],
  [
    "Lac-Saint-Louis", 24036
  ],
  [
    "LaSalle–Émard–Verdun", 24037
  ],
  [
    "Laurentides–Labelle", 24038
  ],
  [
    "Laurier–Sainte-Marie", 24039
  ],
  [
    "Laval–Les Îles", 24040
  ],
  [
    "Longueuil–Charles-LeMoyne", 24041
  ],
  [
    "Lévis–Lotbinière", 24042
  ],
  [
    "Longueuil–Saint-Hubert", 24043
  ],
  [
    "Louis-Hébert", 24044
  ],
  [
    "Louis-Saint-Laurent", 24045
  ],
  [
    "Manicouagan", 24046
  ],
  [
    "Mégantic–L'Érable", 24047
  ],
  [
    "Mirabel", 24048
  ],
  [
    "Montarville", 24049
  ],
  [
    "Montcalm", 24050
  ],
  [
    "Montmagny–L'Islet–Kamouraska–Rivière-du-Loup", 24051
  ],
  [
    "Mont-Royal", 24052
  ],
  [
    "Notre-Dame-de-Grâce–Westmount", 24053
  ],
  [
    "Outremont", 24054
  ],
  [
    "Papineau", 24055
  ],
  [
    "Pierrefonds–Dollard", 24056
  ],
  [
    "Pontiac", 24057
  ],
  [
    "Portneuf–Jacques-Cartier", 24058
  ],
  [
    "Québec", 24059
  ],
  [
    "Repentigny", 24060
  ],
  [
    "Richmond–Arthabaska", 24061
  ],
  [
    "Rivière-des-Mille-Îles", 24062
  ],
  [
    "Rivière-du-Nord", 24063
  ],
  [
    "Rosemont–La Petite-Patrie", 24064
  ],
  [
    "Marc-Aurèle-Fortin", 24065
  ],
  [
    "Saint-Hyacinthe–Bagot", 24066
  ],
  [
    "Saint-Jean", 24067
  ],
  [
    "Saint-Laurent", 24068
  ],
  [
    "Saint-Léonard–Saint-Michel", 24069
  ],
  [
    "Saint-Maurice–Champlain", 24070
  ],
  [
    "Salaberry–Suroît", 24071
  ],
  [
    "Shefford", 24072
  ],
  [
    "Sherbrooke", 24073
  ],
  [
    "Vaudreuil–Soulanges", 24074
  ],
  [
    "Terrebonne", 24075
  ],
  [
    "Trois-Rivières", 24076
  ],
  [
    "Ville-Marie–Le Sud-Ouest–Île-des-Soeurs", 24077
  ],
  [
    "Vimy", 24078
  ],
  [
    "Ajax", 35001
  ],
  [
    "Algoma–Manitoulin–Kapuskasing", 35002
  ],
  [
    "Aurora–Oak Ridges–Richmond Hill", 35003
  ],
  [
    "Barrie–Innisfil", 35004
  ],
  [
    "Barrie–Springwater–Oro-Medonte", 35005
  ],
  [
    "Bay of Quinte", 35006
  ],
  [
    "Beaches–East York", 35007
  ],
  [
    "Brampton Centre", 35008
  ],
  [
    "Brampton East", 35009
  ],
  [
    "Brampton North", 35010
  ],
  [
    "Brampton South", 35011
  ],
  [
    "Brampton West", 35012
  ],
  [
    "Brantford–Brant", 35013
  ],
  [
    "Bruce–Grey–Owen Sound", 35014
  ],
  [
    "Burlington", 35015
  ],
  [
    "Cambridge", 35016
  ],
  [
    "Chatham-Kent–Leamington", 35017
  ],
  [
    "Davenport", 35018
  ],
  [
    "Don Valley East", 35019
  ],
  [
    "Don Valley North", 35020
  ],
  [
    "Don Valley West", 35021
  ],
  [
    "Dufferin–Caledon", 35022
  ],
  [
    "Durham", 35023
  ],
  [
    "Eglinton–Lawrence", 35024
  ],
  [
    "Elgin–Middlesex–London", 35025
  ],
  [
    "Essex", 35026
  ],
  [
    "Etobicoke Centre", 35027
  ],
  [
    "Etobicoke–Lakeshore", 35028
  ],
  [
    "Etobicoke North", 35029
  ],
  [
    "Flamborough–Glanbrook", 35030
  ],
  [
    "Glengarry–Prescott–Russell", 35031
  ],
  [
    "Guelph", 35032
  ],
  [
    "Haldimand–Norfolk", 35033
  ],
  [
    "Haliburton–Kawartha Lakes–Brock", 35034
  ],
  [
    "Hamilton Centre", 35035
  ],
  [
    "Hamilton East–Stoney Creek", 35036
  ],
  [
    "Hamilton Mountain", 35037
  ],
  [
    "Hamilton West–Ancaster–Dundas", 35038
  ],
  [
    "Hastings–Lennox and Addington", 35039
  ],
  [
    "Huron–Bruce", 35040
  ],
  [
    "Kanata–Carleton", 35041
  ],
  [
    "Kenora", 35042
  ],
  [
    "King–Vaughan", 35043
  ],
  [
    "Kingston and the Islands", 35044
  ],
  [
    "Kitchener Centre", 35045
  ],
  [
    "Kitchener–Conestoga", 35046
  ],
  [
    "Kitchener South–Hespeler", 35047
  ],
  [
    "Lambton–Kent–Middlesex", 35048
  ],
  [
    "Lanark–Frontenac–Kingston", 35049
  ],
  [
    "Leeds–Grenville–Thousand Islands and Rideau Lakes", 35050
  ],
  [
    "London–Fanshawe", 35051
  ],
  [
    "London North Centre", 35052
  ],
  [
    "London West", 35053
  ],
  [
    "Markham–Stouffville", 35054
  ],
  [
    "Markham–Thornhill", 35055
  ],
  [
    "Markham–Unionville", 35056
  ],
  [
    "Milton", 35057
  ],
  [
    "Mississauga Centre", 35058
  ],
  [
    "Mississauga East–Cooksville", 35059
  ],
  [
    "Mississauga–Erin Mills", 35060
  ],
  [
    "Mississauga–Lakeshore", 35061
  ],
  [
    "Mississauga–Malton", 35062
  ],
  [
    "Mississauga–Streetsville", 35063
  ],
  [
    "Nepean", 35064
  ],
  [
    "Newmarket–Aurora", 35065
  ],
  [
    "Niagara Centre", 35066
  ],
  [
    "Niagara Falls", 35067
  ],
  [
    "Niagara West", 35068
  ],
  [
    "Nickel Belt", 35069
  ],
  [
    "Nipissing–Timiskaming", 35070
  ],
  [
    "Northumberland–Peterborough South", 35071
  ],
  [
    "Oakville", 35072
  ],
  [
    "Oakville North–Burlington", 35073
  ],
  [
    "Oshawa", 35074
  ],
  [
    "Ottawa Centre", 35075
  ],
  [
    "Orléans", 35076
  ],
  [
    "Ottawa South", 35077
  ],
  [
    "Ottawa–Vanier", 35078
  ],
  [
    "Ottawa West–Nepean", 35079
  ],
  [
    "Oxford", 35080
  ],
  [
    "Parkdale–High Park", 35081
  ],
  [
    "Parry Sound–Muskoka", 35082
  ],
  [
    "Perth–Wellington", 35083
  ],
  [
    "Peterborough–Kawartha", 35084
  ],
  [
    "Pickering–Uxbridge", 35085
  ],
  [
    "Renfrew–Nipissing–Pembroke", 35086
  ],
  [
    "Richmond Hill", 35087
  ],
  [
    "Carleton", 35088
  ],
  [
    "St. Catharines", 35089
  ],
  [
    "Toronto–St. Paul's", 35090
  ],
  [
    "Sarnia–Lambton", 35091
  ],
  [
    "Sault Ste. Marie", 35092
  ],
  [
    "Scarborough–Agincourt", 35093
  ],
  [
    "Scarborough Centre", 35094
  ],
  [
    "Scarborough–Guildwood", 35095
  ],
  [
    "Scarborough North", 35096
  ],
  [
    "Scarborough–Rouge Park", 35097
  ],
  [
    "Scarborough Southwest", 35098
  ],
  [
    "Simcoe–Grey", 35099
  ],
  [
    "Simcoe North", 35100
  ],
  [
    "Spadina–Fort York", 35101
  ],
  [
    "Stormont–Dundas–South Glengarry", 35102
  ],
  [
    "Sudbury", 35103
  ],
  [
    "Thornhill", 35104
  ],
  [
    "Thunder Bay–Rainy River", 35105
  ],
  [
    "Thunder Bay–Superior North", 35106
  ],
  [
    "Timmins–James Bay", 35107
  ],
  [
    "Toronto Centre", 35108
  ],
  [
    "Toronto–Danforth", 35109
  ],
  [
    "University–Rosedale", 35110
  ],
  [
    "Vaughan–Woodbridge", 35111
  ],
  [
    "Waterloo", 35112
  ],
  [
    "Wellington–Halton Hills", 35113
  ],
  [
    "Whitby", 35114
  ],
  [
    "Willowdale", 35115
  ],
  [
    "Windsor–Tecumseh", 35116
  ],
  [
    "Windsor West", 35117
  ],
  [
    "York Centre", 35118
  ],
  [
    "York–Simcoe", 35119
  ],
  [
    "York South–Weston", 35120
  ],
  [
    "Humber River–Black Creek", 35121
  ],
  [
    "Brandon–Souris", 46001
  ],
  [
    "Charleswood–St. James–Assiniboia–Headingley", 46002
  ],
  [
    "Churchill–Keewatinook Aski", 46003
  ],
  [
    "Dauphin–Swan River–Neepawa", 46004
  ],
  [
    "Elmwood–Transcona", 46005
  ],
  [
    "Kildonan–St. Paul", 46006
  ],
  [
    "Portage–Lisgar", 46007
  ],
  [
    "Provencher", 46008
  ],
  [
    "Saint Boniface–Saint Vital", 46009
  ],
  [
    "Selkirk–Interlake–Eastman", 46010
  ],
  [
    "Winnipeg Centre", 46011
  ],
  [
    "Winnipeg North", 46012
  ],
  [
    "Winnipeg South", 46013
  ],
  [
    "Winnipeg South Centre", 46014
  ],
  [
    "Battlefords–Lloydminster", 47001
  ],
  [
    "Cypress Hills–Grasslands", 47002
  ],
  [
    "Desnethé–Missinippi–Churchill River", 47003
  ],
  [
    "Carlton Trail–Eagle Creek", 47004
  ],
  [
    "Moose Jaw–Lake Centre–Lanigan", 47005
  ],
  [
    "Prince Albert ", 47006
  ],
  [
    "Regina–Lewvan", 47007
  ],
  [
    "Regina–Qu'Appelle ", 47008
  ],
  [
    "Regina–Wascana ", 47009
  ],
  [
    "Saskatoon–Grasswood", 47010
  ],
  [
    "Saskatoon–University", 47011
  ],
  [
    "Saskatoon West", 47012
  ],
  [
    "Souris–Moose Mountain ", 47013
  ],
  [
    "Yorkton–Melville ", 47014
  ],
  [
    "Banff–Airdrie", 48001
  ],
  [
    "Battle River–Crowfoot", 48002
  ],
  [
    "Bow River", 48003
  ],
  [
    "Calgary Centre", 48004
  ],
  [
    "Calgary Confederation", 48005
  ],
  [
    "Calgary Forest Lawn", 48006
  ],
  [
    "Calgary Heritage", 48007
  ],
  [
    "Calgary Midnapore", 48008
  ],
  [
    "Calgary Nose Hill", 48009
  ],
  [
    "Calgary Rocky Ridge", 48010
  ],
  [
    "Calgary Shepard", 48011
  ],
  [
    "Calgary Signal Hill", 48012
  ],
  [
    "Calgary Skyview", 48013
  ],
  [
    "Edmonton Centre", 48014
  ],
  [
    "Edmonton Griesbach", 48015
  ],
  [
    "Edmonton Manning", 48016
  ],
  [
    "Edmonton Mill Woods", 48017
  ],
  [
    "Edmonton Riverbend", 48018
  ],
  [
    "Edmonton Strathcona", 48019
  ],
  [
    "Edmonton West", 48020
  ],
  [
    "Edmonton–Wetaskiwin", 48021
  ],
  [
    "Foothills", 48022
  ],
  [
    "Fort McMurray–Cold Lake", 48023
  ],
  [
    "Grande Prairie–Mackenzie", 48024
  ],
  [
    "Lakeland", 48025
  ],
  [
    "Lethbridge", 48026
  ],
  [
    "Medicine Hat–Cardston–Warner", 48027
  ],
  [
    "Peace River–Westlock", 48028
  ],
  [
    "Red Deer–Mountain View", 48029
  ],
  [
    "Red Deer–Lacombe", 48030
  ],
  [
    "St. Albert–Edmonton", 48031
  ],
  [
    "Sherwood Park–Fort Saskatchewan", 48032
  ],
  [
    "Sturgeon River–Parkland", 48033
  ],
  [
    "Yellowhead", 48034
  ],
  [
    "Abbotsford", 59001
  ],
  [
    "Burnaby North–Seymour", 59002
  ],
  [
    "Burnaby South", 59003
  ],
  [
    "Cariboo–Prince George", 59004
  ],
  [
    "Central Okanagan–Similkameen–Nicola", 59005
  ],
  [
    "Chilliwack–Hope", 59006
  ],
  [
    "Cloverdale–Langley City", 59007
  ],
  [
    "Coquitlam–Port Coquitlam", 59008
  ],
  [
    "Courtenay–Alberni", 59009
  ],
  [
    "Cowichan–Malahat–Langford", 59010
  ],
  [
    "Delta", 59011
  ],
  [
    "Fleetwood–Port Kells", 59012
  ],
  [
    "Kamloops–Thompson–Cariboo", 59013
  ],
  [
    "Kelowna–Lake Country", 59014
  ],
  [
    "Kootenay–Columbia", 59015
  ],
  [
    "Langley–Aldergrove", 59016
  ],
  [
    "Mission–Matsqui–Fraser Canyon", 59017
  ],
  [
    "Nanaimo–Ladysmith", 59018
  ],
  [
    "New Westminster–Burnaby", 59019
  ],
  [
    "North Okanagan–Shuswap", 59020
  ],
  [
    "North Vancouver", 59021
  ],
  [
    "Pitt Meadows–Maple Ridge", 59022
  ],
  [
    "Port Moody–Coquitlam", 59023
  ],
  [
    "Prince George–Peace River–Northern Rockies", 59024
  ],
  [
    "Richmond Centre", 59025
  ],
  [
    "Esquimalt–Saanich–Sooke", 59026
  ],
  [
    "Saanich–Gulf Islands", 59027
  ],
  [
    "Skeena–Bulkley Valley", 59028
  ],
  [
    "South Okanagan–West Kootenay", 59029
  ],
  [
    "South Surrey–White Rock", 59030
  ],
  [
    "Steveston–Richmond East", 59031
  ],
  [
    "Surrey Centre", 59032
  ],
  [
    "Surrey–Newton", 59033
  ],
  [
    "Vancouver Centre", 59034
  ],
  [
    "Vancouver East", 59035
  ],
  [
    "Vancouver Granville", 59036
  ],
  [
    "North Island–Powell River", 59037
  ],
  [
    "Vancouver Kingsway", 59038
  ],
  [
    "Vancouver Quadra", 59039
  ],
  [
    "Vancouver South", 59040
  ],
  [
    "Victoria", 59041
  ],
  [
    "West Vancouver–Sunshine Coast–Sea to Sky Country", 59042
  ],
  [
    "Yukon", 60001
  ],
  [
    "Northwest Territories", 61001
  ],
  [
    "Nunavut", 62001
  ]
]);

export default() => ridingMap;
