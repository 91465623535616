'use strict';

import React from 'react';
import _ from 'underscore';
import { FormInput } from "shards-react";

import { computeMinMax, detectOperator } from '../util.js';
// import { null } from 'check-types';

const QBNumber = ({field, keyName, operators, operator, selector, setSelector, currentValue, setCurrentValue, isMinVal, isMaxVal, removeFromSelector, triggerSubmit }) => {

  //set the initial value
  const setValue = () => {
    if ( !!selector[keyName] ) {
      const raw = operators[ operator ].rawValue( selector[keyName] );
      if ( operator !== 'between' ) {
        return !!field.option && field.option === "currency" && (raw /100) || raw;
      } else if ( !!isMinVal ) {
        return !!field.option && field.option === "currency" && (currentValue["$gte"] /100) || currentValue["$gte"] || '';
      } else if ( !!isMaxVal ) {
        return !!field.option && field.option === "currency" && (currentValue["$lte"] /100) || currentValue["$lte"] || '';
      }
    }

    //nothing is default
    return '';
  }

  return (
        <div className='d-flex align-items-center'>
        <FormInput
            type='number'
            size='sm'
            className={ operator !== 'between' && 'ml-md-2' || '' }
            placeholder={field.label}
            min={field.min}
            max={field.max}
            value={ setValue() }
            onKeyPress = {
              e => {
                if (e.key === 'Enter') {
                  triggerSubmit();
                }
              }
            }
            onChange={e => {
              // if(typeof parseInt(e.target.value) !== "number") {
              //   return null;
              // }
              const val = !!field.option && field.option === "currency" &&  Math.ceil(parseFloat(e.target.value) * 100) + "" || e.target.value;

              //if empty, remove but not if using between, as ther other value may be set
              if ( operator !== 'between' && ( !val || !val.length ) ) {
                return removeFromSelector();
              }

              //compute new value, either a single value or array pair
              let newVal = operator === 'between' && computeMinMax( currentValue, parseFloat(val), isMinVal, isMaxVal ) || parseFloat(val);

              //remove from selector if both values are unset
              if ( operator == 'between' && _.isNull( newVal[0] ) && _.isNull( newVal[1] ) ) {
                return removeFromSelector();
              }

              // if(!!field.option && field.option === "currency") {
              //   if(_.isArray(newVal)) {
              //     newVal = newVal.map(val => val*100);
              //   } else {
              //     newVal = newVal * 100;
              //   }
              // }

              //format properly given the operator
              let argsToPass = [keyName];

              //if min and mix, we pass them in order
              if ( _.isArray(newVal) ) {
                argsToPass = [ keyName, ...newVal ]; //[ keyName, minVal, maxVal ]
              } else {
                //otherwise pass a single val
                argsToPass.push( newVal ); // [ keyName, val ]
              }

              const addToSelector = operators[operator].handler.apply(this, argsToPass);
              //set the current value locally, without the operator
              setCurrentValue( addToSelector[keyName] );
              //modify top level selector
              setSelector({ ...selector, ...addToSelector});
          }}/> </div>
        );
}

export default QBNumber;
