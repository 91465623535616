import React, {useState} from "react";
import {Mutation} from "react-apollo";
import gql from 'graphql-tag';
import _ from "underscore";

import Loading from "./Loading";
import {
  Form,
  Button,
  Row,
  Col,
  FormInput
} from "shards-react";

import { toast } from 'react-toastify';

const ADD_BANK_ACCOUNT = gql`
mutation addBankAccount($account: GenericObject){
  addBankAccount(account: $account) {
    _id
    firstName
    lastName
    bank
    last4
    created
    updated
    supporterId
  }
}
`


const AddBank = ({supporter, handleCloseBank}) => {

    const [accountData, setAccountData] = useState({
      supporterId : supporter._id,
      firstName : supporter.firstName,
      lastName : supporter.lastName,
      institution : "",
      account : "",
      transit : ""
 
    });
    
    const acceptOnlyNumbers = ({field, input, limit}) => {
      const regex = /^[0-9.,]+$/;

      if(!!regex.test(input) && input.length <= limit || !input) {
        setAccountData( {...accountData, [field] : input});
      }
      return null;
    }

    return (
        <Mutation
          mutation={ADD_BANK_ACCOUNT}
          onCompleted={({addBankAccount}) => {
            toast.success(`Bank Account created`)
            handleCloseBank();
          }}
          onError={e => {

          for (const error of e.graphQLErrors) {
            toast.error(error.extensions.response.body.error.message);
          }
          }}
        >
        {(addBankAccount, {data, loading, error}) => {
          if ( !!loading ) {
            {/* <Loading/> */}
              return (
                <Loading />
              )
          }
          return (
            <Form onSubmit={async(ev) => {
              ev.preventDefault();

              let error = "";
              if(accountData.transit.length < 5) {
                error += "Transit must be 5 digits - "
              }
              if(accountData.institution.length < 3) {
                error += "Institution must be 5 digits - "
              }
              if(accountData.account.length < 5) {
                error += "Account must be 5 digits minimum"
              }

              if(!!error.length) {
                return toast.error(error)
              }
              
  
              const account = _.omit(accountData, _.isEmpty);
              account.last4 = account.account.slice(-4)
          
              addBankAccount({
                variables : {
                  account
                },
                refetchQueries: ['getSupporter', 'getSupporters', 'getActions']
              });
 
            }}>
              <Row form>
                <Col md="6" className="form-group">
                  <label htmlFor="name">First Name (required)</label>
                  <FormInput
                    id="name"
                    type="text"
                    value={accountData.firstName}
                    onChange={(e) => setAccountData({...accountData, firstName : e.target.value})}
                  />
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="name">Last Name (required)</label>
                  <FormInput
                    id="name"
                    type="text"
                    value={accountData.lastName}
                    onChange={(e) => setAccountData({...accountData, lastName : e.target.value})}
                  />
                </Col>
              </Row>

              
              <Row>
                <Col md="3" className="form-group">
                  <label htmlFor="name">Transit</label>
                  <FormInput
                    id="name"
                    type="text"
                    value={accountData.transit}
                    onChange={(e) => acceptOnlyNumbers({field : "transit", input : e.target.value, limit : 5})}
                  />
                </Col>
                <Col md="3" className="form-group">
                  <label htmlFor="name">Institution</label>
                  <FormInput
                    id="name"
                    type="text"
                    value={accountData.institution}
                    onChange={(e) => acceptOnlyNumbers({field : "institution", input : e.target.value, limit : 3})}
                  />
                </Col>
                <Col md="6" className="form-group">
                  <label htmlFor="name">Account number</label>
                  <FormInput
                    id="name"
                    type="text"
                    value={accountData.account}
                    onChange={(e) => acceptOnlyNumbers({field : "account", input : e.target.value, limit : 12})}
                  />
                </Col>
              </Row>
              <Button>Add Bank Account</Button>
            </Form>
          );
        }}
      </Mutation>
    );

}

export default AddBank;