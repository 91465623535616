import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import BankAccount from "./BankAccount";
import CreditCard from "./CreditCard";
import Subscription from "./Subscription";
import SubscribeToPlan from "./SubscribeToPlan";
import {Accordion, AccordionBody, AccordionHeader} from "./Accordion";
import AddBank from "./AddBank";
import AddCard from "./AddCard";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ListGroup,
  Button
} from "shards-react";
import _ from "underscore";

const PaymentMethods = ({supporter, openOnLoad = false}) => {
  //modal addCard
  const [showCard, setShowCard] = useState(false);


  const handleCloseCard = () => setShowCard(false);
  const handleShowCard = () => setShowCard(true);

  //modal Add Bank Account
  const [showBank, setShowBank] = useState(false);

  const handleCloseBank = () => setShowBank(false);
  const handleShowBank = () => setShowBank(true);


  const customer      = supporter.customerInfo;
  const sources       = !!customer && !!customer.sources && !!customer.sources.length && customer.sources || [];
  const bankAccounts  = !!supporter.bankAccounts && supporter.bankAccounts || [];
  const subscriptions = !!customer && !!customer.subscriptions && !!customer.subscriptions.length && customer.subscriptions || [];


  let defaultCard = !!supporter.customerInfo
  && !!supporter.customerInfo.sources
  && !!supporter.customerInfo.sources.length
  &&  supporter.customerInfo.sources.filter(card => card.id === supporter.customerInfo.default_source)
  || "";

  return(

    <Accordion
    openOnLoad={!!openOnLoad}>
    <AccordionHeader
      title="Payment Methods"
      content={!!defaultCard && !!defaultCard.length && `${defaultCard[0].brand} ending in ${defaultCard[0].last4} - Default` || "No card on file"}
    />
    <AccordionBody>

    <div>
      <strong>CREDIT CARDS</strong>
      <Row className="my-3">
        {sources.map((card, i) => {
          return <Col xs={12} md={4}>
            <CreditCard
              key={i + Math.random()}
              card={card}
              default_source={customer.default_source}
              supporterId={supporter._id}
              customerId={ customer.id }
            />
          </Col>
        })}
      </Row>
      <Button
        //if the user is a caller and there is already a card, disable the Add card button
        // disabled={_.contains(!!user && user.roles.campaign, "caller") && sources.length >= 1}
        onClick={() => {
            handleShowCard()
        }}>Add New Card</Button>


      <hr/>

      <strong>BANK ACCOUNTS</strong>
      <Row className="my-3">

      {bankAccounts.map((bankAccount, i) => {
        const activeSubscription = subscriptions.filter(subscription => subscription.bankAccountId === bankAccount._id);
        return <Col  key={i + Math.random()} xs={12} md={4}>
          <BankAccount 
            bankAccount={bankAccount}
            activeSubscription={!!activeSubscription.length}
            supporterId={supporter._id}
          />
        </Col>
      })}

      </Row>
      <Button
        onClick={handleShowBank}>
        Add Bank Account
      </Button>

      {/* Add Card Modal */}
      <Modal
        size="md"
        // centered
        show={showCard}
        onHide={handleCloseCard}
        >
        <Modal.Header closeButton>
          <Modal.Title>Add a Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <AddCard
              supporter={supporter}
              handleCloseCard={handleCloseCard}
            />
        </Modal.Body>
      </Modal>

      {/* Add Bank Account Modal */}
      <Modal
      size="lg"
      // centered
      show={showBank}
      onHide={handleCloseBank}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Bank Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddBank
            supporter={supporter}
            handleCloseBank={handleCloseBank} />
        </Modal.Body>
      </Modal>
    </div>

    </AccordionBody>
  </Accordion>


  )
}

export default PaymentMethods;
