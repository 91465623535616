import _ from 'underscore';
import { Modal } from "react-bootstrap";
import React, { useState, Fragment } from "react";
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import {
  Col,
  Form,
  InputGroup,
  FormInput,
  InputGroupAddon,
  ListGroupItem,
  Button,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  FormCheckbox,
  FormRadio

} from "shards-react";
import numeral from "numeral";
import moment from "moment";
import { set } from 'react-ga';

export const GET_BANKRUNITEMS = gql`
  query getBankRunItems($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject) {
    getBankRunItems(selector: $selector, limit: $limit, skip: $skip, sort: $sort) {
      count
      bankRunItems {
        _id
        bankRunId
        supporterId
        subscriptionId
        status
        reason
        PIN
        plan {
          amount
        }
        supporter {
          firstName
          lastName
        }
        bankAccount{
          last4
          firstName
          lastName
        }
      }
    }
  }
`;

export const UPDATE_BANK_RUN_ITEM = gql`
  mutation updateOneBankRunItem($selector: Selector, $modifier: Modifier, $updateAction: GenericObject, $bankRunToUpdate: GenericObject, $supporterId: String) {
    updateOneBankRunItem(selector: $selector, modifier: $modifier, updateAction: $updateAction, bankRunToUpdate: $bankRunToUpdate, supporterId: $supporterId) {
      n
      nModified
      ok
    }
  }
`;


const DeclinedTransactions = ({bankRun, bankRunStatus, declinedList, setDeclinedList, bankRunId}) => {
  //modal addCard
  const [showModal, setShowModal] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = () => setShowEditModal(true);

  const [PINSearch, setPINSearch] = useState("");

  const [declined, setDeclined] = useState({});
  const [query, setQuery] = useState({
    skip : true
  })

  const {data, loading, refetch} = useQuery(GET_BANKRUNITEMS, query);


  const opts = {
    onCompleted :  () => {
      toast.dismiss()
      toast.success("Bank Run updated")}
      ,
    onError : (e) => {
      for (const error of e.graphQLErrors) {
        //check if the error is within GraphQL or sent back from the server
        // in order to retirce the error message at the right place
        const errorMessage = error.extensions.response ?
        error.extensions.response.body.error.message :
        error.message;
        toast.error(errorMessage);
      }
    }
  }

  const [updateOneBankRunItem, {error}] = useMutation(UPDATE_BANK_RUN_ITEM, opts);

  if(!!data && !!data.getBankRunItems && data.getBankRunItems.bankRunItems.length === 1 && !declined._id) {
    setDeclined(data.getBankRunItems.bankRunItems[0])
  }

  return(
    <Fragment>
      <CardHeader>
      <h6 className="text-center">{bankRunStatus === "open" && "Enter Declined Transactions" || "List of transactions"}</h6>
      </CardHeader>
      <CardBody className="p-0 mb-3">
        <Form>
          <Col sm="6">
            <InputGroup>
              <FormInput
              id="supporterPIN"
              type="text"
              value={PINSearch}
              placeholder="search by PIN"
              onChange={e => setPINSearch(e.target.value)}
              />
              <InputGroupAddon type="append">
                <Button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();

                    setQuery({
                      variables: {
                        selector : {
                          PIN : PINSearch,
                          bankRunId
                        }
                      },
                      fetchPolicy : "network-only",
                      onCompleted : ({getBankRunItems}) => {

                        //if there is a result and bank run Status is "open", show the decline modal
                        if(getBankRunItems.count > 0 && bankRunStatus === "open") {
                          handleShowModal();
                        }
                        //if there is a result and the bank run status is not "open" show the edit modal
                        else if (getBankRunItems.count > 0) {
                          handleShowEditModal();
                        }
                        //any other case show a warning
                        else {
                          toast.warning("There is no subscription/bankRunItem for this PIN in this bank run")
                        }

                      },
                      onError : (e) => {
                        console.log({e})
                      }
                    });

                    setPINSearch("");

                  }}>
                  Search
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Form>
      </CardBody>

       {/* Decline transaction Modal */}
       <Modal
        size="md"
        // centered
        show={showModal}
        onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add a declined transaction</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroupItem className="d-flex row px-0 mb-3">
              {!!data && data.getBankRunItems.bankRunItems.map((item, i) => {
                return(
                  <Col className="col-11" lg="11" md="11" sm="11" key={`${item._id}`} >
                    <FormRadio
                     className="ml-auto my-auto"
                     checked={declined._id === item._id}
                     disabled={!!_.where(declinedList, {_id : item._id}).length}
                     onChange={()=> {
                       setDeclined(item)
                     }}
                     key={`${item._id}`}>
                      <h6 className="go-stats__label mb-1">{`PAC - ${(item.plan.amount/100).toFixed(2)} - ${numeral(item.plan.amount/100).format('$0,0.00')}`}</h6>
                      <div className="go-stats__meta">
                        <span className="mr-2">
                          <strong>{`${item.bankAccount.firstName} ${item.bankAccount.lastName} / Bank Account : ending ***${item.bankAccount.last4}`}</strong>
                        </span>
                      </div>
                    </FormRadio>
                  </Col>
                )
              })}
            </ListGroupItem>
            <Col className="col-11 mb-3" lg="11" md="11" sm="11">
              <label>Please select the reason :</label>
              <FormSelect
                value={!!declined.reason && declined.reason || " "}
                onChange={e => setDeclined({...declined, reason : e.target.value})}
                >
                <option> </option>
                <option>Invalid/Incorrect Account No.</option>
                <option>Payor/Payee Deceased</option>
                <option>No Debit Allowed</option>
                <option>Account Closed</option>
                <option>Payment Stopped/Recalled</option>
                <option>Insufficient Funds</option>
                <option>Account Not Found</option>
                <option>Other</option>
              </FormSelect>
            </Col>
            <Col className="col-11 mb-3" lg="11" md="11" sm="11">
              <FormCheckbox
                toggle
                checked={!!declined.cancelSubscription}
                onChange={e => setDeclined({...declined, cancelSubscription : !declined.cancelSubscription})}
                >
                Cancel Subscription and mark as uncollectable - if you keep the subscription active, it will be presented again to the bank next month
              </FormCheckbox>
            </Col>
            <Button
              type="submit"
              onClick={() => {
                if(!declined._id) {
                  return toast.warning("Please select a transaction");
                }
                if(!declined.reason) {
                  return toast.warning("Please select a reason");
                }

                // add the declined transaction to the list
                setDeclinedList([...declinedList, declined]);
                //reset declined
                setDeclined({});

                setQuery({
                  skip : true
                });
                
                handleCloseModal();
              }}>
              Confirm
            </Button>
          </Modal.Body>
        </Modal>

       {/* Edit Modal */}
       <Modal
        size="md"
        // centered
        show={showEditModal}
        onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Decline a transaction</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroupItem className="d-flex row px-0 mb-3">
              {!!data && data.getBankRunItems.bankRunItems.map((item, i) => {
                return(
                  <Col className="col-11" lg="11" md="11" sm="11" key={`${item._id}`} >
                    <FormRadio
                     className="ml-auto my-auto"
                     checked={declined._id === item._id}
                     disabled={!!_.where(declinedList, {_id : item._id}).length}
                     onChange={()=> {
                       setDeclined(item)
                     }}
                     key={`${item._id}`}>
                      <h6 className="go-stats__label mb-1">{`PAC - ${(item.plan.amount/100).toFixed(2)} - ${numeral(item.plan.amount/100).format('$0,0.00')}`}</h6>
                      <div className="go-stats__meta">
                        <span className="mr-2">
                          <strong>{`${item.bankAccount.firstName} ${item.bankAccount.lastName} / Bank Account : ending ***${item.bankAccount.last4}`}</strong>
                        </span>
                      </div>
                      <div>
                        <span>
                          <strong>{`status : ${item.status}`}</strong>
                        </span>
                      </div>
                    </FormRadio>
                  </Col>
                )
              })}
            </ListGroupItem>
            <Col className="col-11 mb-3" lg="11" md="11" sm="11">
              <label>Please select the reason :</label>
              <FormSelect
                value={!!declined.reason && declined.reason || " "}
                onChange={e => setDeclined({...declined, reason : e.target.value})}
                >
                <option> </option>
                <option>Invalid/Incorrect Account No.</option>
                <option>Payor/Payee Deceased</option>
                <option>No Debit Allowed</option>
                <option>Account Closed</option>
                <option>Payment Stopped/Recalled</option>
                <option>Insufficient Funds</option>
                <option>Account Not Found</option>
                <option>Other</option>
              </FormSelect>
            </Col>
            {/* <Col className="col-11 mb-3" lg="11" md="11" sm="11">
              <FormCheckbox
                toggle
                checked={!!declined.cancelSubscription}
                onChange={e => setDeclined({...declined, cancelSubscription : !declined.cancelSubscription})}
                >
                Cancel Subscription - if you keep the subscription active, it will be presented again to the bank next month
              </FormCheckbox>
            </Col> */}
            <Button
              disabled={declined.status === "declined"}
              type="submit"
              onClick={() => {
                console.log(declined)
                if(!declined._id) {
                  return toast.warning("Please select a transaction");
                }
                if(!declined.reason) {
                  return toast.warning("Please select a reason");
                }
                toast.info(`Please wait while this transaction is edited and the bank run updated. It may take a while, don't refresh or close the page. :-)`, {autoClose : false, closeButton: false, closeOnClick: false, draggable: false});
                //update the bankrunitem
                updateOneBankRunItem({
                  variables : {
                    selector : {
                      _id : declined._id
                    },
                    modifier : {
                      $set : {
                        status : "declined",
                        reason : declined.reason
                      }
                    },
                    supporterId : declined.supporterId,
                    bankRunToUpdate : bankRun,
                    updateAction : {
                      selector : {$or : [
                        {'data.document' : declined._id},
                        {'data.documentId' : declined._id}
                      ]
                        
                      },
                      modifier : {
                        $set : {
                          'data.status'   : "failure",
                          'data.reason'   : declined.reason,
                          'method'        : 'failed',
                          'description'   : `PAC-${(declined.plan.amount/100).toFixed(2)} bank_account payment has been declined on ${moment(bankRun.runDate).format('MM/DD/YY')}`
                        }
                      }
                    }
                  },
                  refetchQueries : ['getBankRuns', 'getBankRunItems']
                })

                //reset declined
                setDeclined({});

                setQuery({
                  skip : true
                });
                handleCloseEditModal();
              }}>
              Confirm
            </Button>
          </Modal.Body>
        </Modal>
    </Fragment>
  )
}

export default DeclinedTransactions;


