import React, { useState, Fragment } from "react";
import ReactTable from 'react-table';
import PageTitle from "../components/common/PageTitle";
import MessagesList from "../friendsComponents/MessagesList";
import SMSUnsubscribeButton from '../friendsComponents/SMSUnsubscribeButton';
import Loading from "../friendsComponents/Loading";
import InboxTable from '../friendsComponents/InboxTable';
// import MARK_AS_READ from './Messages';
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ListGroup,
  Button,
  ButtonGroup,
  FormInput,
  FormSelect
} from "shards-react";
import moment from 'moment';
import { toast } from 'react-toastify';

import {Query, Mutation} from "react-apollo";
import gql from 'graphql-tag';

export const GET_INBOX = gql`
  query getInbox($limit: Int, $status: String) {
    getInbox(limit: $limit, status: $status) {
      _id
      from
      message
      to
      date
      direction
      status
      supporter {
        _id
        contactPreferences{
          okSMS
          okContact
        }
        firstName
        lastName
      }
      prospect {
        _id
        askedSTOP
      }
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation updateMessage($selector: Selector, $modifier: Modifier){
    updateMessage(selector: $selector, modifier: $modifier){
      n
      nModified
      ok
    }
  }
`;

export const UPDATE_PROSPECT = gql`
mutation updateOneProspect($selector: Selector, $modifier: Modifier){
    updateOneProspect(selector: $selector, modifier: $modifier){
      n
      nModified
      ok
    }
  }
`;


const Inbox = () => {

  const [limit, setLimit] = useState(50);
  const [status, setStatus] = useState('');


  
  return (
    <Query
      query={GET_INBOX}
      variables={{limit, status}}
      pollInterval={15 * 1000}
      fetchPolicy={"network-only"}
      onError={e => {
      }}
      onCompleted={()=> {

      }}>

      {({data, loading, error}) => {
    
     
        if (loading) return(
          <Loading />
        );
        if (error) {
          if(error.message === 'GraphQL error: 404: Not Found') {
            return "ERROR : You might not have the presmissions to access to this page"
          }
          return <p>ERROR: {error.message}</p>
        };
        

        return (
          <Container fluid className="main-content-container px-4">
          {/* Page Header */}
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Messages" subtitle="Inbox" className="text-sm-left" />
          </Row>
          <Card small className="my-4">
            <CardHeader>
              <h6 className="text-center">Inbox</h6>

            </CardHeader>
              <CardBody className="my-4">
                <InboxTable
                  messages={!!data && data.getInbox || []}
                  limit={limit}
                  setLimit={setLimit}
                  status={status}
                  setStatus={setStatus}
                />
              </CardBody>
          </Card>

          </Container>
          )
      }}
    </Query>
)};

export default Inbox;
