import React, { useState, useEffect } from 'react';
import _ from 'underscore';
import MergeSupporter from '../MergeSupporter/index.js';
import { supporterFromAction } from './supporterFromAction'
import UPDATE_ACTIONS from './gql';

const DupeWizard = ({ reportName, data, wizardTotal, setWizardTotal, setCurrentIndex, currentIndex = -1 }) => {

  const [ currentGroup, setCurrentGroup ] = useState( !!data && !!data.results && !!data.results.length && data.results[0] || null );

  let currentIndexLocal = !!currentGroup && data.results.indexOf( currentGroup ) || 0;

  useEffect( () => {
    setCurrentIndex( currentIndexLocal );
  }, [ currentIndexLocal ]);
  

  useEffect( () => {
    setWizardTotal( data.results.length );
  }, [ currentGroup ]);

  console.log(currentGroup)

  let locationVal, actions = [], fieldsToCompare = [];

  if ( reportName === 'duplicates' || 'bigMerge' ) {
    locationVal = { state: { supportersId: currentGroup['possibleDuplicateIds'] } };
  }

  // if( reportName === "gigImport") {
    
  //   let formattedSupporterIds = cu
  // }


  if ( reportName === 'donorChanges' ) {
    locationVal = { state: { supportersId: [ currentGroup['match'] ] } };
    actions = !!currentGroup && currentGroup['actions'].map( a => {
      a.priorState  = supporterFromAction(a);
      fieldsToCompare.push( ...Object.keys( a.priorState ) );
      return a;
    });

    fieldsToCompare = _.uniq( _.without( fieldsToCompare, 'lastUpdated', '_id', 'householdMembers' ) )
  }


  if ( !!currentGroup ) {
    console.log({currentGroup, currentIndexLocal, data : data.results[currentIndexLocal], data1 : data.results[currentIndexLocal+1]});
    return (
      <MergeSupporter
        location = { locationVal }
        currentIndex = { currentIndexLocal }
        specialActions = { reportName }
        wizard = { true }
        isActionCheck = { reportName === 'donorChanges' }
        actions = { !!actions && !!actions.length && actions || null }
        fieldsToCompare = { !!fieldsToCompare && !!fieldsToCompare.length && fieldsToCompare || null }
        hasPrev = { currentIndexLocal > 0  }
        hasNext = { currentIndex <  data.results.length - 1 }
        successCallback = { (e) => {
          currentIndexLocal ++;
          setCurrentGroup(data.results[ currentIndexLocal ]);
        }}
        goBack = { (e) => {
          currentIndexLocal --;
          setCurrentGroup(data.results[ currentIndexLocal ]);
        }}
        setWizardSupporterIds = { (ids) => {
          const newCurrentGroup = currentGroup;
          newCurrentGroup.possibleDuplicateIds = ids;
          setCurrentGroup( newCurrentGroup );
        }}
      />
    )
  }

  return (<h3>All done!</h3>)
}

export default DupeWizard;
