import React, {useState, useEffect, memo, useContext, Fragment} from 'react';
import {
  Row
} from "shards-react";
import {AccordionProvider, AccordionContext } from "./AccordionContext";

import {
  Collapse,
  Card,
  CardBody
} from "shards-react";

import "./index.css";



const Accordion = memo(props => {


  return(
    <Card className="mx-2 mt-4">
      <AccordionProvider
        openOnLoad={props.openOnLoad}>
        {props.children}
      </AccordionProvider>
    </Card>
  )
})

const AccordionHeader = ({title, content}) => {
  const {active, toggleActive} = useContext(AccordionContext);

  return( 
    <div
        style={{cursor : "pointer"}}
        onClick={()=> {toggleActive(!active)}}>
      <div 
        className="justify-content-between align-me m-4"

        >
        <span> <strong className="title-accordion">{title}</strong> <strong className="mx-4">{!!content && !active && content || ""}</strong></span>
        {<span className="icon-accordion"><i className={`fas fa-${!!active && "minus" || "plus"}`}></i></span>}
      </div>
  </div>)

}

const AccordionBody = ({table, children}) => {
  const {active} = useContext(AccordionContext)
  //if we pass table = true, we want it to take as much space
  const className = !!table &&  "mt-2" || "mb-4 mx-4";
  
  return(
      <Collapse open={active}>
        <div
          className={className}>
          {children}
        </div>
        
      </Collapse>)
   
}



export {Accordion, AccordionHeader, AccordionBody} ;