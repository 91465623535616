const getTransactionColumns = [
    { key: 'date', header: 'Date' },
    { key: 'data.billingDate', header: 'Billing Date' },
    { key: 'data.paymentType', header: 'Payment Type' },
    { key: 'data.paymentMethod', header: 'Payment Method' },
    { key: 'data.amount', header: 'Amount' },
    { key: 'data.status', header: 'Status' },
    { key: 'campaign.nickname', header: 'Campaign' },
    { key: 'PIN', header: 'PIN' },
    { key: "firstName", header : "First Name"},
    { key: 'lastName', header: 'Last Name' },
    { key: 'address1', header: 'Adddress 1' },
    { key: 'address2', header: 'Adddress 2' },
    { key: 'city', header: 'City' },
    { key: 'province', header: 'Province' },
    { key: 'postalCode', header: 'Postal Code' },
    { key: 'emails.0.address', header: 'Primary Email' },
    { key: 'homePhone', header: 'Home Phone' },
    { key: 'mobilePhone', header: 'Mobile Phone' },
    { key : "lastSuccessPAC", header: "Last PAC payment" },
    { key: 'PACStatus', header: 'PAC Status' },
    { key: 'createdDate', header: 'Created' },
    { key: 'lastUpdated', header: 'Last Updated' },
    { key: 'data.opts.utm_source', header: 'utm_source'},
    { key: 'data.opts.utm_content', header: 'utm_content'},
    { key: 'data.opts.utm_campaign', header: 'utm_campaign'},
    { key: 'data.opts.receive_gift_optin', header: 'receive_gift'},
  ];


  export default() => getTransactionColumns;
