import React, { useState, Fragment } from "react";


import CommentAction from "./CommentAction";
import { useMutation } from '@apollo/react-hooks';
import useToast from "../hooks/use-toast";
import {
  Button
} from "shards-react";

import gql from 'graphql-tag';


export const RETRY_SUBSCRIPTION = gql`
  mutation canceSubscription($subscriptionId: String, $supporterId: String, $opts: GenericObject) {
    retrySubscription(subscriptionId : $subscriptionId, supporterId : $supporterId, opts: $opts){
      id
      status
    }
  }
`;

const RetrySubscription = ({ supporterId, subscription }) => {

  const { loadingToast, completeToast, errorToast} = useToast();

  const [retry, {data, error, loading}] = useMutation(RETRY_SUBSCRIPTION, {
    onCompleted : () => {
      completeToast("Payment for subscription resubmitted");
    }
  });
  const [opts, setOpts] = useState({});
  const [showModal, setShowModal] = useState(false);

  if(!!error) {
    for (const err of error.graphQLErrors) {
      //check if the error is within GraphQL or sent back from the server
      // in order to retrieve the error message at the right place 
      const errorMessage = err.extensions.response ? 
      err.extensions.response.body.error.message :
      err.message;
      errorToast(errorMessage);
    }
  }

  return(
    <Fragment>
      <Button
        className="btn-block"
        onClick={() => {
          setOpts({
            variables : {
              supporterId,
              subscriptionId : subscription.id,
              opts : {}
            },
            refetchQueries : ['getSupporter']
          })
          setShowModal(true);
        }}>
        retry
      </Button>
      <CommentAction
        action={`retry ${subscription.plan.nickname} payment`}
        confirmation={true}
        mutation={retry}
        showModal={showModal}
        setShowModal={setShowModal}
        loadingMessage={"Retrying the subscription..."}
        loadingToast={loadingToast}
        opts={opts} />
    </Fragment>
  )

}

export default RetrySubscription;
