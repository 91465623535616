import React, { useState, Fragment } from "react";
import Loading from "../Loading";
import Table from "react-bootstrap/Table";
import {
    Row,
    Col,
    InputGroup,
    FormSelect,
    FormInput,
    Form,
    InputGroupAddon
} from "shards-react";
import {
  Button
} from "shards-react";

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { match } from "fuzzy";
import { FormLabel } from "react-bootstrap";


const types = {
  supporters : ["ENS", "Care2"],
  actions    : ["paypal"]
}

const endpoint = {
  supporters : "/import",
  actions : "/action/import"
}

const ImportCollections = ({collection}) => {

  const [file, setFile] = useState();
  const [fileType, setFileType] = useState(types[collection][0]);
  const [dataResponse, setDataResponse] = useState();
  const [ loading, setLoading ] = useState(false);

  if(!!loading) {
    return <Loading />
  }

  if(dataResponse) {
    return <DisplayResultImport
      data={dataResponse}
      collection={collection} />
  }
  return (
    <Row>
      <Col sm={12} md={4}>
        <FormLabel>
            Select source of import:
          </FormLabel>
          <FormSelect
            id="fileType"
            value={fileType}
            onChange={e => {}}
            >
            {types[collection].map((type, i) => {
              return <option key={i+Math.random()}>{type}</option>
            })}
          </FormSelect>
      </Col>
      <Col sm={12} md={8}>
         <FormLabel>
            Select the file to import:
          </FormLabel>
        <InputGroup className="m-auto">
          <Form onSubmit={(e) => {console.log("Submitted")}} encType={'multipart/form-data'}>
              <FormInput
                // name={'document'}
                type={'file'}
                placeholder="Click to select file..."
                // className="custom-file-input"
                id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
                onChange={({target: { files }}) => {
                    if(!!files.length && files[0].type === "text/csv") {
                      if (  files[0].size >= 1024 * 1024 * 5 ) {
                        toast.error("Max file size 5mb");
                        setFile();
                        return false;
                      }

                      return setFile(files[0]);
                    }
                    toast.error("You can only upload .csv files");
                }}
              />
            </Form>
              <InputGroupAddon type="append">
            <Button
              disabled = {!file}
              onClick = {() => {
                setLoading(true);
                const userInStorage = localStorage.getItem('user');
                const user = !!userInStorage && !!userInStorage.length && JSON.parse(userInStorage) || null;
                const token = !!user && user.token || "";

                const form = new FormData();
                form.append('file', file);

                let baseURL = process.env.REACT_APP_UPLOAD_URL || 'http://localhost:8000';
                if ( collection == 'supporters' ) {
                  baseURL += '/supporter';
                }
                baseURL += '' + endpoint[collection] + '/';
                baseURL += fileType

                fetch(baseURL, {
                  method: 'POST',
                  body: form,
                  mode: 'cors',
                  headers: {
                    authorization: 'Bearer ' + token,
                  }
                })
                .then(res => {
                  console.log('res', res);
                  if ( !!res.ok ) {
                    return res.json();
                  }
                  throw res;
                })
                .then( json => {
                  setDataResponse(json.data);
                  toast.success(json.message + ' Reloading');
                  setFile();

                 //reload on success
                  window.setTimeout( () => {
                    window.location = window.location;

                  }, 3000);
                })
                .catch(async e => {
                  let { error } = await e.json();
                  console.log('error', error);
                  toast.error(error.message || error.statusMessage || error.statusText || 'Error');
                })
                .finally( () => {
                  setLoading(false);
                });

              }}
              >UPLOAD</Button>
            </InputGroupAddon>
        </InputGroup>
      </Col>

  </Row>

  )
}



const DisplayResultImport = ({data, collection}) => {
  const {notImported, countUpdated, countImported} = data;

  return <div>
    <p><strong>{`New ${collection} imported: ${countImported}`}</strong></p>
    {!!countUpdated && <p><strong>{`${collection} updated: ${countUpdated}`}</strong></p> || ""}
    {!!notImported && notImported.length && <div>
      <p>These records were not imported: </p>
      <Table striped bordered hover>
        <thead>
          <tr>
            {Object.keys(notImported[0]).map((field, i) => {
                    return <th key={i + Math.random()}>{field}</th>
                  })}
          </tr>
        </thead>
        <tbody>
          {notImported.map((entry, i) => {
              return <tr key= {i + Math.random()}>
                {Object.keys(entry).map((field, i) => {
                  return <td key={i + Math.random()}>{entry[field]}</td>
                })}
                </tr>

            })}
        </tbody>

      </Table>
    </div>}
  </div>

}



export default ImportCollections;
