import React, {useState, Fragment} from "react";
import CommentAction from "./CommentAction";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import { GET_SUPPORTER } from "../views/supporterProfile";


export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription($subscriptionId: String, $type: String, $supporterId: String, $opts: GenericObject) {
    cancelSubscription(subscriptionId : $subscriptionId, supporterId : $supporterId, type: $type, opts: $opts){
      id
      status
    }
  }
`;

const CancelSubscription = ({customerId, subscriptionId, bankAccount, nickname, supporterId, campaign, campaignView = false}) => {

  const [opts, setOpts] = useState({});
  const [open, setOpen] = useState(false);

  const toggle = () =>  setOpen(!open);
  //this is the show variable for the modal CommentAction
  const [showModal, setShowModal] = useState(false);

  const [cancelSubscription, {data, loading, error}] = useMutation(CANCEL_SUBSCRIPTION, {
    onError : e => { console.log(e); toast.error(e.message);
    },
    onCompleted : ({cancelSubscription}) => {
      toast.success(`Subscription plan cancelled`)
    }
  })

  
  return(
    <Fragment>
    {!!campaignView 
    && <Button
        outline
        theme="danger"
        className="btn-block"
        onClick={ () => {
          let cancelOptions = {
                variables: {
                  subscriptionId,
                  supporterId,
                  opts : {
                    campaign : !!campaign && campaign || null
                  } },
                refetchQueries: ['getSupporter', 'getCampaign', 'getActions']
              }
              //if the subscription is a local bank account, need to pass the type and the nickname
              if(!!bankAccount) {
                cancelOptions.variables.type = "bank_account";
                cancelOptions.variables.nickname = nickname
              }
              //if the cancellation is part of a campaign, pass it in the opts
              if(!!campaign) {
                cancelOptions.variables.opts.campaign = campaign
              }

              //set the cancel options and call the commentAction modal
              setOpts(cancelOptions);
              setShowModal(true);
        }}>
      Cancel
    </Button>
    || <Dropdown open={open} toggle={toggle}>
        <DropdownToggle nav style={{color : "red"}} >Cancel</DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              let cancelOptions = {
                variables: {
                  subscriptionId,
                  supporterId,
                  opts : {
                    campaign : !!campaign && campaign || null
                  } },
                  update: (store, {data : {cancelSubscription}}) => {
                    console.log("in update : ", store)
                    const supporter = store.readQuery({
                      query : GET_SUPPORTER,
                      variables : {supporterId}
                    })
                    console.log({supporter})
                  },
                  optimisticResponse: {
                    cancelSubscription: {

                    }
                  },
                refetchQueries: ['getSupporter', 'getCampaign', 'getActions']
              }
              //if the subscription is a local bank account, need to pass the type and the nickname
              if(!!bankAccount) {
                cancelOptions.variables.type = "bank_account";
                cancelOptions.variables.nickname = nickname
              }
              //if the cancellation is part of a campaign, pass it in the opts
              if(!!campaign) {
                cancelOptions.variables.opts.campaign = campaign
              }

              //set the cancel options and call the commentAction modal
              setOpts(cancelOptions);
              setShowModal(true);

            }}
          >Cancel</DropdownItem>
          <DropdownItem
            onClick={() => {
              let cancelOptions = {
                variables: {
                  subscriptionId,
                  supporterId,
                  opts : {
                    campaign : !!campaign && campaign || null,
                    uncollectable : true
                  } },
                refetchQueries: ['getSupporter', 'getCampaign', 'getActions']
              }
              //if the subscription is a local bank account, need to pass the type and the nickname
              if(!!bankAccount) {
                cancelOptions.variables.type = "bank_account";
                cancelOptions.variables.nickname = nickname
              }
              //if the cancellation is part of a campaign, pass it in the opts
              if(!!campaign) {
                cancelOptions.variables.opts.campaign = campaign
              }

              //set the cancel options and call the commentAction modal
              setOpts(cancelOptions);
              setShowModal(true);

            }}
          >Uncollectable</DropdownItem>
        </DropdownMenu>
      </Dropdown>}

      
      <CommentAction
        action={`cancel ${nickname} subscription`}
        confirmation={true}
        mutation={cancelSubscription}
        showModal={showModal}
        setShowModal={setShowModal}
        opts={opts} />
    </Fragment>
  )
}

export default CancelSubscription;
