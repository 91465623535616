import React, {useState} from "react";
import Loading from "./Loading";
import {Modal} from "react-bootstrap";
import UpdateExpiry from "./UpdateExpiry";
import RemoveCard from "./RemoveCard";
import {Mutation} from "react-apollo";
import gql from 'graphql-tag';
import { toast } from 'react-toastify';
import CustomerMismatchWarning from './CustomerMismatchWarning'
import {
  ListGroupItem,
  Col,
  Button,
  Badge
} from "shards-react";

export const SET_DEFAULT_CARD = gql`
  mutation updateCustomer($supporterId: String, $modifier: Modifier) {
    updateCustomer(
      supporterId: $supporterId,
      modifier: $modifier
    ) {
      default_source
    }
  }
`;



const CreditCard = ({card, default_source, supporterId, customerId}) => {

  const [show, setShow] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const customerMismatch = !!card.customerId && card.customerId !== customerId;
 
  const primaryCard = (card.id === default_source) && " - Default" || "";
  const EditCard = !customerMismatch ? <a  className="mr-2" style={{"cursor" : "pointer", "color" : "#2A82E6"}} onClick={() => handleShow()}>Edit</a> : ""

  const defaultCardComponent = (card.id !== default_source && !customerMismatch) ?
    <Mutation
      mutation={SET_DEFAULT_CARD}
      onError={() => {
        setSubmitting(false);
      }}
      onCompleted={({updateCustomer}) => {
        toast.success(`${card.brand} EXPIRY: ${card.exp_month}/${card.exp_year} card successfully set to primary.`)
        setSubmitting(false);
      }}
      refetchQueries= {['getSupporter', 'getSupporters', 'getActions']}
    >

      {(update, {data, loading, error}) => {

        if (loading) return(
          <Loading />
        );
        if (error) return <p>ERROR: {error.message}</p>;

        return (
          <a
            className="mr-2"
            style={{cursor: "pointer"}}
            disabled={submitting}
            onClick={() => {
              setSubmitting(true);
              update({
                variables: {
                  supporterId,
                  modifier : {
                    default_source: card.id
                  }
                },
                refetchQueries: ["getSupporter", "getSupporters", 'getActions']
              });
            }}
          >
          Set as Default
          </a>
        )
      }}
    </Mutation> :
    "";


  return (

    <div className= "d-flex row px-0" style={!!customerMismatch && {"border" : "5px solid orange"} || {}}>
      { !!customerMismatch ?
        <CustomerMismatchWarning />
      : "" }
      <Col>
       
        <h6 className="go-stats__label mb-1">{card.brand} {primaryCard}</h6>
        
        <div className="go-stats__meta">
          <span className="mr-2">
            <strong>{`**** **** **** ${card.last4}`}</strong>
          </span>
          <div className="mr-2">
            <strong>EXPIRY : {card.exp_month} / {card.exp_year}</strong>
          </div>
          <div>
            {EditCard}
            <RemoveCard
              supporterId={supporterId}
              cardId={card.id}
              customerId={card.customerId}
            /> 
            
            {defaultCardComponent}
          </div>
        </div>
      </Col>


    {/* Update Expiry Modal */}
    <Modal
    size="md"
    // centered
    show={show}
    onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Expiry</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <UpdateExpiry
          cardId={card.id}
          supporterId={supporterId}
          actualMonth={card.exp_month}
          actualYear={card.exp_year}
          handleClose={handleClose}
        />
      </Modal.Body>
      </Modal>
    </div>
  )

}

export default CreditCard;
