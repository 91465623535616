import React, { useState, useEffect } from "react";
import {Button, Modal} from "react-bootstrap"
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import MessageEditor from "../friendsComponents/MessageEditor";
import SupporterTable from '../friendsComponents/SupporterTable';
import PageTitle from "../components/common/PageTitle";
import SetPageMetas from '../utils/SetPageMetas';
import SearchSupporter from "../friendsComponents/SearchSupporter";
import Review from "../friendsComponents/Review";
import templateEngine from "../templateEngine";


const Messages = ({match, location, history}) => {
  SetPageMetas({ title: "SMS Messages" });

  const [query, setQuery] = useState({selector: {mobilePhone: { $ne: null}}});
  const [count, setCount] = useState(0);
  const [from, setFrom] = useState('16474924966');
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [queryStringHandler, setQueryStringHandler] = useState('');
  const [messageTemplate, setMessageTemplate] = useState('');
  const [positiveResponse, setpositiveResponse] = useState('');
  const [positiveLink, setPositiveLink] = useState('');
  const [negativeResponse, setnegativeResponse] = useState('');
  const [collection, setCollection] = useState('supporter');


  //modal Review
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  //methods for the Message editor form
  const handleChange = (event) => {
		setFrom(event.target.value);
	};

	const handleChangeLink = (event) => {
		setLink(event.target.value);
	};

	const handleChangeQueryString = (event) => {
		setQueryStringHandler(event.target.value);
	};

	const handleChangeMessage = (input) => {
    setMessage(input);
    //need to add the example : our dear Bob Tester
		setMessageTemplate(templateEngine(input));
	};

  // const mobilePhoneRequired = () => {
  //   const actualQuery = query;
  //   actualQuery.selector.mobilePhone = { $ne: null}
  //   return actualQuery

  // }


  const contactPreferences = {
    "optInEn"      : '',
    "optInFr"      : '',
    "okEmail"      : '',
    "okCall"       : '',
    "okMail"       : '',
    "okEvents"     : '',
    'okSMS'        : true,
    'okContact'    : true,
    "okFund"       : '',
    "okTrade"      : '',
    "okNewsletter" : '',
    "okSurveys"    : '',
  };


  return (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Messages" subtitle="Send Bulk" className="text-sm-left" />
    </Row>
    <Card small className="mb-4">
      <MessageEditor
        from={from}
        message={message}
        messageTemplate={messageTemplate}
        link={link}
        handleChange={handleChange}
        handleChangeMessage={handleChangeMessage}
        handleChangeLink={handleChangeLink}
        handleChangeQueryString={handleChangeQueryString}
        positiveResponse={positiveResponse}
        setpositiveResponse={setpositiveResponse}
        positiveLink={positiveLink}
        setPositiveLink={setPositiveLink}
        negativeResponse={negativeResponse}
        setnegativeResponse={setnegativeResponse}

      />
    </Card>

    <Card small className="mb-4">
      <SearchSupporter
        setQuery={setQuery}
        mobilePhoneRequired={true}
        count={count}
        collection={collection}
        setCollection={setCollection}
        disabledFields={['okSMS', 'okContact']}
        defaults={{ contactPreferences }}
        prospectOption={true}
        />
        <SupporterTable
        query={query}
        count={count}
        sequery={setQuery}
        setCount={setCount}
        collection={collection}
      />
    </Card>
   
    <Button variant="primary" onClick={handleShow}>
        Review
    </Button>

    {/* modal review before sending messages  */}
    <Modal
      size="lg"
      // centered
      show={show}
      onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Review before sending</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Review
            message={message}
            messageTemplate={messageTemplate}
            queryStringHandler={queryStringHandler}
            link={link}
            from={from}
            query={query}
            count={count}
            positiveResponse={positiveResponse}
            negativeResponse={negativeResponse}
            positiveLink={positiveLink}
            collection={collection}
          />
        </Modal.Body>
      </Modal>

  </Container>
)};

export default Messages;
