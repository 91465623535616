import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';

import gql from 'graphql-tag';
import validator from "validator";

const SEND_BLAST = gql`
mutation sendBlast($from: String, $message: String, $link: String, $queryStringHandler: String, $query: Selector, $collection: String, $positiveResponse: String, $positiveLink: String, $negativeResponse: String){
  sendBlast(
    from               : $from,
    message            : $message,
    link               : $link,
    queryStringHandler : $queryStringHandler,
    query              : $query,
    collection         : $collection,
    positiveResponse   : $positiveResponse,
    positiveLink       : $positiveLink,
    negativeResponse   : $negativeResponse
  ) {
   blastId
   sent
   failed
  }
}
`

const Review = ({ message, messageTemplate, from, query, link, queryStringHandler, positiveResponse, collection, positiveLink, negativeResponse, count}) => {
  const numberToReach = (count > query.limit) ? query.limit : count

  const [sending, setSending] = useState(false)
  return (
    <Mutation
      mutation={SEND_BLAST}
      onError={e => {
        setSending(false)
        console.log({e})
        // for (const error of e.graphQLErrors) {
        //   //check if the error is within GraphQL or sent back from the server
        //   // in order to retirce the error message at the right place
        //   const errorMessage = error.extensions.response ?
        //   error.extensions.response.body.error.message :
        //   error.message;
        //   toast.error(errorMessage);
        // }
        }}
      onCompleted={({sendBlast}) => {
        setSending(false)
        alert(`Your messages are being sent
        BlastId: ${sendBlast.blastId}
        Sent: ${sendBlast.sent}
        Failed: ${sendBlast.failed}`)
      }}
    >
    {(sendBlast) => {
    return (
      <div>
        <p>You will reach {numberToReach} {collection}s</p>
        <p>Phone number : {from}</p>
        <Form>
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Template to be sent</Form.Label>
                <Form.Control disabled as="textarea" rows="5" value={message} />
              </Col>
              <Col>
                <Form.Label>Message rendered</Form.Label>
                <Form.Control disabled as="textarea" rows="5" value={messageTemplate} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Positive Response</Form.Label>
                <Form.Control disabled as="textarea" rows="5" value={positiveResponse} />
              </Col>
              <Col>
                <Form.Label>Negative Response</Form.Label>
                <Form.Control disabled as="textarea" rows="5" value={negativeResponse} />
              </Col>
            </Row>
          </Form.Group>

          <Button 
            variant="primary" 
            disabled={sending == true}
            onClick={() => {
              setSending(true)

            const validFromPhone = validator.isMobilePhone(from, ['en-CA', 'en-US'])
            if(!validFromPhone) {
              return toast.error("The from phone number needs to be valid")
            }

            if(message.length === 0 ){
              return toast.error("You cannot send an empty message")
            }

            const validLink = validator.isURL(link.split("?")[0])
            if(!!link && !validLink) {
              return toast.error("please enter a valid URL")
            }


            sendBlast({variables:
              {
              collection,
              from,
              message,
              link,
              queryStringHandler,
              positiveResponse,
              positiveLink,
              negativeResponse,
              query}});

          }}>
            Send
          </Button>
        </Form>
      </div>
    )
    }}
    </Mutation>
  )
}

export default Review;
