import React from "react";
import {
  Col
} from "shards-react";

const CustomerMismatchWarning = () => {
  return (<Col sm="12"
      style={{backgroundColor: 'orange', textAlign: 'center', marginBottom: '1em', marginTop: '-16px', padding: '10px'}}
      >
      <span style={{width: '100%', color: 'white', fontWeight: 'bold'}}>** <em>TRANFERRED FROM MERGE. PLEASE CANCEL AND RECREATE.</em> **</span>

  </Col>)
}

export default CustomerMismatchWarning;
