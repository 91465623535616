import React, {Fragment, useState} from "react";
import mailTrackPrograms from "../data/mail-tracking-Codes";

import {
    FormSelect,
    FormInput,
    Col
} from "shards-react";

const MailTrackingCodeSelector = ({campaign, mailTrackingCode, setMailTrackingCode}) => {
  console.group(localStorage.getItem("mailProgram"))

  const [program, setProgram] = useState(!!localStorage.getItem("mailProgram") && !!mailTrackPrograms[localStorage.getItem("mailProgram")] && localStorage.getItem("mailProgram") || "");

  return (<Fragment>

      <Col className="mb-3" md="6">
        <label>Enter the Mail Tracking Code:</label>


      {/* If a program is selected show the dropdown menu, if not, show a regular input form */}
        {!!program && program.length && <FormSelect
            defaultValue={""}
            onChange={e => {
                setMailTrackingCode(e.target.value);

            }}
            >
            <option value = ""></option>
            {!!mailTrackPrograms[program] && mailTrackPrograms[program].sort((a, b)=> a.value - b.value ).map(field => {
                return <option id={"key-code-" + field.value} value={field.value}>{field.key}</option>
            })}
            </FormSelect>
        || <FormInput
           value={mailTrackingCode}
           onChange={e => setMailTrackingCode(e.target.value)}>
        </FormInput>
        }
        

      </Col>
      <Col className="mb-3" md="6">
        <label>Mail Tracking Program selected :</label>
        <FormSelect
            defaultValue={program}
            onChange={e => {
              const programSelected = e.target.value
              localStorage.setItem('mailProgram', programSelected);
              setMailTrackingCode("");
              setProgram(programSelected);
            }}>
            <option value=""> - </option>
            {Object.keys(mailTrackPrograms).map(field => {
                return  <option key={"choice-" + field } value={field}>{field}</option>
            })}
        </FormSelect>

      </Col>
      
      
      
      
  </Fragment>)
}

export default MailTrackingCodeSelector;