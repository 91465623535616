import React from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "shards-react";
import SetPageMetas from '../utils/SetPageMetas';

const Errors = () => {
  SetPageMetas({ title: "Error" })
  return (<Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h2>404</h2>
        <h3>Page not found</h3>
        <p>The page you're looking for doesn't seem to exist.</p>
        <Link to='/' className='btn btn-primary btn-pill'>&larr; Go Home</Link>
      </div>
    </div>
  </Container>);

}

export default Errors;
