import React, { useState, useEffect, Fragment } from "react";
import Loading from "../friendsComponents/Loading";
import {
  SMS_CONVERSATION
} from "../fragments/graphqlFragments";

import { Container, Row, Col} from "shards-react";
import { Tabs, Tab } from 'react-bootstrap';

import Conversation from "./Conversation";
import Payments from "./Payments";
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
const Url = require('url-parse');

export const GET_PROSPECT = gql`
  query getProspect($prospectId: String) {
    getProspect(prospectId: $prospectId) {
      _id
      mobilePhone
      response
      SMSConversation {
        ...SMSConversation
      }
    }
  }
  ${SMS_CONVERSATION}
`;



const Prospect = ({match}) => {
  //gets the supporterId from the route
  const prospectId = match.params.id;


  
  const [prospect, setProspect] = useState({});
  const [ fields, setFields ] = useState();


  return (

    <Container fluid className="main-content-container p-4">

      {/* Tabs represent different views related to supporter */}
      
        <Container className='bg-white'>
          <Row className="p-3">
            <Query
              query={GET_PROSPECT}
              variables={{prospectId}}
              fetchPolicy="network-only"
              >
              {({ data, loading, error }) => {
           
                if (loading) return(
                  <Loading />
                );
                if (error) return <p>ERROR: {error.message}</p>;

                setProspect(data.getProspect)
                return (
                  <Fragment>
                    <div>Prospect : {data.getProspect._id} mobilePhone {data.getProspect.mobilePhone} Response received : {data.getProspect.response}</div>
                    <Conversation
                      prospectId={prospectId}
                    />
                  </Fragment>
                );
              }}
            </Query>
           
          </Row>
        </Container>
       

    </Container>
  )


}


export default Prospect;
