//needs to be provided with options in config
import React from "react";
import {
    FormSelect
} from "shards-react";
//"equals, not_equals"

const QBSelect = ({field, keyName, operators, operator, selector, setSelector, currentValue, setCurrentValue, removeFromSelector }) => {

    return(
    <FormSelect
      key={`select-${keyName}`}
      defaultValue={currentValue}
      className='ml-md-2'
      onChange={ e => {
    }}
    >
    {field.options.map(option => <option key={ `${keyName}-${option.value}` } value={option.value}>{option.label}</option>)}
    </FormSelect>)
}

export default QBSelect;
