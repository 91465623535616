export const routesPermissions = {
  "/" : {
    service : "app"
  },
  "/supporter" : {
    service       : "supporter",
    roles         : ["basic", "admin", "super-admin"]
  },
  "/supporter/create" : {
    service       : "supporter",
    roles         : ["admin", "super-admin"]
  },
  "/supporter/clean" : {
    service       : "supporter",
    roles         : ["admin", "super-admin"]
  },
  "/supporter/merge" : {
    service       : "supporter",
    roles         : ["admin", "super-admin"]
  },
  "/supporter/household" : {
    service       : "supporter",
    roles         : ["admin", "super-admin"]
  },
  "/supporter/:id" : {
    service       : "supporter",
    roles         : ["basic", "admin", "super-admin"]
  },
  "/prospect/:id" : {
    service : "prospect",
    roles : ["basic", "admin", "super-admin"]
  },
  "/sms/inbox" : {
    service : "sms",
    roles : ["basic", "admin", "super-admin"]
  },
  "/sms/blast/create" : {
    service : "sms",
      roles : ["admin", "super-admin"]
  },
  "/sms/blast" : {
    service : "sms",
    roles : ["admin", "super-admin"]
  },
  "/sms/blast/:id" : {
    service : "sms",
      roles : ["admin", "super-admin"]
  },
  '/admin/users' : {
    service : "gateway",
      roles : ["admin", "super-admin"]
  },
  '/admin/users/create' : {
    service : "gateway",
    roles : ["super-admin"]
  },
  '/admin/users/:id' : {
    service : "gateway",
    roles : ["basic", "admin", "super-admin"]
  },
  '/campaign' : {
    service : "campaign",
      roles : ["caller", "admin", "super-admin"]
  },
  '/campaign/create' : {
    service : "campaign",
    roles : ["super-admin"]
  },
  '/campaign/:id' : {
    service : "campaign",
    roles : ["caller", "basic", "admin", "super-admin"]
  },
  '/admin/services' : {
    service : "gateway",
    roles : ["super-admin"]
  },
  '/bankrun' : {
    service : "payment",
      roles : ["super-admin"]
  },
  '/bankrun/:id' : {
    service : "payment",
      roles : ["super-admin"]
  },
  '/transactions' : {
    service : "payment",
      roles : ["super-admin"]
  }
};
