import React from "react";
import _ from "underscore";
import {
  FormInput
}
from "shards-react";

import {
  detectOperator
}
from "../util.js";

const QBText = ({
  field,
  keyName,
  operators,
  operator,
  selector,
  setSelector,
  currentValue,
  setCurrentValue,
  removeFromSelector,
  triggerSubmit,
}) => {
  return ( < FormInput type = "text"
    size = "sm"
    className = "ml-md-2"
    placeholder = {
      field.label
    }
    value = {
      (!!selector[keyName] && operators[detectOperator(currentValue)].rawValue(
        currentValue)) || ""
    }
    onKeyPress = {
      e => {
        if (e.key === 'Enter') {
          triggerSubmit();
        }
      }
    }
    onChange = {
      (e) => {
        const val = e.target.value;

        //if empty, remove
        if (!val || !val.length) {
          return removeFromSelector();
        }

        //format properly given the operator
        const addToSelector = operators[operator].handler.apply(this, [
          keyName,
          val,
        ]);

        //set the current value locally, without the operator
        setCurrentValue(addToSelector[keyName]); //{ $ne: 'Daniel' }
        //modify top level selector
        setSelector({...selector, ...addToSelector
        });
      }
    }
    />
  );
};

export default QBText;
