import React, { Component, useEffect, useRef, Fragment } from "react";
import { Fade } from "react-bootstrap";
import { toast } from 'react-toastify';
import Charges from "../../../friendsComponents/Charges"
import PaymentMethods from "../../../friendsComponents/PaymentMethods";
import Contributions from "../../../friendsComponents/Contributions";
import {Accordion, AccordionBody, AccordionHeader} from "../../../friendsComponents/Accordion";

import CardBankSubscription from "../../../friendsComponents/CardBankSubscription";
import Loading from "../../../friendsComponents/Loading";
import { useQuery } from '@apollo/react-hooks';

import {Row} from "shards-react";
import { GET_SUPPORTER_PAYMENT } from '../gql.js';


const CampaignStepSubscription = ({store, setStep, registerGift, setPendingAmount, pendingAmount}) => {

  const user = JSON.parse(localStorage.getItem('user'));
  
  const scrollTopRef = useRef(null);
  const scrollToTop = () => {
    scrollTopRef.current.scrollIntoView({ behavior: "smooth" })
  }

  // useEffect(scrollToTop);
  // scrollToTop();
   
   return (
    <Fade in={true}>
   <Fragment>
    <div ref={scrollTopRef} />
    <Row className="justify-content-between mx-3">
        <h3>{store.supporter.firstName} {store.supporter.lastName} {!!store.supporter.isDeceased && "(deceased)"}</h3>   
    </Row>
      <div>
        <PaymentMethods
          supporter={store.supporter}
        />
      </div>
      <div className= "m-2">
        <Contributions 
          title={"Monthly Donations"}
          supporter={store.supporter}
          user={user}
          campaign={store.campaign}
          wizardRegisterGift={registerGift}
          campaignView = {"subscription"}
        />
      </div>

      <div className="mb-4">
        <Accordion
              openOnLoad={true}>
              <AccordionHeader 
                title="Contribution History"
              />
              <AccordionBody
                table={true}>
                <Charges
                  campaignView={true}
                  supporter={!!store.supporter && store.supporter}
                  hideHeader={true}
                />
              </AccordionBody>
        </Accordion>
      </div>
      
     
   </Fragment>
   </Fade>
   )



}

export default class CampaignStep2 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      pendingAmount    : false
    };
    this.props.setStep(2)

    if(!!this.props.jumpTo) {
      const step = this.props.jumpTo;
      this.props.setJumpTo();
      this.props.jumpToStep(step)
    }
  }
  
  setPendingAmount = (boolean) => {
    console.log("setting pendingAmount to : ", boolean)
    this.setState({pendingAmount : boolean});
  }


  render() {
    return (
    
      <CampaignStepSubscription 
      store            = {this.props.store}
      registerGift     = {this.props.registerGift}
      setPendingAmount = {this.setPendingAmount}
      pendingAmount    = {this.state.pendingAmount}
      setStep          = {this.props.setStep}
      />

    )
  }


}
