import React, { useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Route, Redirect } from "react-router-dom";
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';

import gql from 'graphql-tag';
import _ from 'underscore';

import Loading from "../friendsComponents/Loading";
import PageTitle from "../components/common/PageTitle";

import CampaignForm from "../friendsComponents/CampaignForm";

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign($campaign: GenericObject) {
    createCampaign(campaign: $campaign) {
      _id
      nickname
      description
      mode
    }
  }
`;




const CreateCampaign = () => {

  const [redirect, setRedirect] = useState(false);

  if(!!redirect) {
    return <Redirect to={'/campaign'} />
  }
  return (
    <Mutation
      mutation={CREATE_CAMPAIGN}
      onCompleted={({createCampaign}) => {
            toast.success(`Campaign ${createCampaign.nickname} created`);
            setRedirect(true)
          }}
      onError={(e) => {
        console.log(e);
      }}
      >
      {(createCampaign, {data, loading, error}) => {
        if (loading) {
          return <Loading />
        }

        if (error) return <p>ERROR: {error.message}</p>;
        return (
          <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title={ `Campaign` } subtitle="Create" md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            <Col >
              <CampaignForm
                createCampaign={createCampaign}
              />
            </Col>
          </Row>
        </Container>
      )
     }}

    </Mutation>
  )
  return null;
}

export default CreateCampaign
