import React from "react";
import gql from 'graphql-tag';
import {
  Container,
  Row,
  Col
} from "shards-react";
import { Link } from "react-router-dom";

import { Query } from "react-apollo";
import { toast } from 'react-toastify'
import classNames from 'classnames';

import Loading from '../../../friendsComponents/Loading.js';
import './NavbarSearchResults.scss';

export const GET_SUPPORTERS = gql`
  query getSupporters($selector: Selector) {
    getSupporters(selector: $selector) {
      count
      supporters {
        _id
        firstName
        lastName
        PIN
        score
        city
        province
        postalCode
        emails {
          address
        }
      }
    }
  }
`;

export default ({ searchTerm, setSearchTerm}) => {
  const classes = classNames(
    'modal',
    { show: !!searchTerm && !!searchTerm.length },
  );

  return (
    <div id='search-results-overlay' className={classes}>
      {
        !!searchTerm && searchTerm.length < 3 && <Loading/>
      }
      {
        !!searchTerm && searchTerm.length >= 3 && (
          <Container fluid={true}>
            <Query
              query={GET_SUPPORTERS}
              variables={{
                selector: {
                  $text     : { $search: searchTerm },
                  sort      : { score: { $meta: "textScore" }, },
                  projector : { score: { $meta: "textScore" } },
                  limit     : 10
                },

              }}
              fetchPolicy="network-only"
              onError={(e) => {
                console.log(e);
                toast.error("Error fetching search results");
              }}

              >
              {({ data, loading, error, refetch }) => {
                return (
                  <Row id='search-row-inner-container' className='mx-1'>
                    <Col>
                      { !!loading && !data && <Loading /> }
                      { !loading && ( !data || !data.getSupporters.count ) && <h5 className='p-3 mb-0'><em>No results</em></h5> }
                      {
                        !loading && !!data && data.getSupporters.count > 10 && (
                          <Row className='pt-2 px-3'>
                            <Col>
                              <span className='too-many-results'>{data.getSupporters.count} records match your query. Showing first 10.</span>
                            </Col>
                          </Row>
                        )
                      }
                      {
                        !loading && !!data && !!data.getSupporters.count && data.getSupporters.supporters.map( s => {
                          return <SearchResult onClick={(e) => { setSearchTerm(null); } } supporter={s} key={s._id} />
                        })
                      }
                    </Col>
                  </Row>
                )
            }}
            </Query>
          </Container>
        )
      }
    </div>
  )
};

const displayAddress = (supporter) => {
  return `${!!supporter.city && supporter.city + ", " || ""} ${!!supporter.province && supporter.province + ", " || ""} ${!!supporter.postalCode && supporter.postalCode || ""}`;
}


const SearchResult = ({ supporter, onClick }) => (
  <Link onClick={onClick} to={`/supporter/${supporter._id}`} className='search-result' >
  <Row className='p-3 search-result-inner'>
    <Col xs='12' className='page-header'>
      <span className="text-uppercase page-subtitle">Supporter</span>
      <h5 className='page-title'>{supporter.firstName} {supporter.lastName}</h5>
      { !!supporter.PIN && <span className='d-block'>{supporter.PIN}</span> || '' }
      {displayAddress(supporter)}
      <span className='d-block'>{supporter.emails && supporter.emails[0] && supporter.emails[0].address}</span>
    </Col>
  </Row>
</Link>
);
