import React, { Component, Fragment } from "react";
import postcode from "postcode-validator";
import "./SearchSupporter.scss";
import _ from 'underscore';

import RidingTagSelect from './RidingTagSelect';

import  QueryBuilder from '../friendsComponents/QueryBuilder';



import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormFeedback,
  FormCheckbox,
  FormSelect,
  Button,
  CardHeader
} from "shards-react";




class SearchSupporter extends Component {

  constructor(props) {
    super(props)
    this.state = _.extend({
      firstName            : '',
      lastName             : '',
      PIN                  : '',
      city                 : '',
      province             : '',
      postalCode           : '',
      language             : '',
      homePhone            : '',
      mobilePhone          : '',
      'emails.address'      : '',
      ridingCode           : '',
      customerId           : '',
      limit                : '',
      skip                 : '0',
      "contactPreferences" : {
        "optInEn"       : '',
        "optInFr"       : '',
        "okEmail"       : '',
        "okCall"        : '',
        "okMail"        : '',
        "okEvents"      : '',
        "okSMS"         : '',
        "okFund"        : '',
        "okTrade"       : '',
        "okNewsletter"  : '',
        "okSurveys"     : '',
        "okContact"     : ''
      },
      message    : {
        postalCode : ''
      }
    }, this.props.defaults || {});




    this.addRidingCode    = this.addRidingCode.bind(this);
    this.removeRidingCode = this.removeRidingCode.bind(this);
    this.parseRidingCode = this.parseRidingCode.bind(this);
  }

  checkPostalCode(input){
    const validPostalCode = postcode.validate(input, 'CA');
    const inputFormatted = this.removeSpaces(input.toUpperCase());
    if (!!validPostalCode) {
      this.setState({postalCode : inputFormatted, message : {postalCode : ''}})
    } else {
      if(!!inputFormatted.length) {
        this.setState({postalCode : inputFormatted, message : {postalCode : 'Postal Code is not valid'}})
      } else {
        this.setState({postalCode : inputFormatted, message : {postalCode : ''}})
      }
    }
  }

  removeSpaces(input) {
    return input.split("").map( str => str.replace(/\s/g, '')).join('');
  }


  changeCollection = (e) => {
    if(this.props.collection === "supporter") {
      this.setState({
        firstName  : '',
        lastName   : '',
        city       : '',
        province   : '',
        postalCode : '',
        language   : '',
        ridingName : '',
        ridingCode : '',
        PACStatus  : '',
        customerId : ''
      });
      this.props.setCollection("prospect");
    } else {
      this.props.setCollection("supporter");
    }



  }

  isDisabled( fieldName ) {

    return !!this.props.disabledFields && this.props.disabledFields.includes(fieldName);

  }

  existingValueSearch = (value) => {
    let input = '';
    if ( value === false ) {
      return null;
    }

    if ( !!value && ( value == true || value == 'true' ) ) {
      return { $ne: null }
    }

    return input;
  }

  submit = () => {
    let selector = {  };


    //if we are looking for prospect, we want to reach only the ones that haven't
    // been contacted yet
    if(this.props.collection === "prospect") {

      selector.contacted = { $type: 10 };
      selector.limit = this.state.limit;

    } else {
      //gets only the values usefull for the search
      selector = _.omit( this.state, _.isEmpty );

      // selector.limit = this.state.limit;

      //trims every string to make sure useless spaces don't affect the search
      for(const field in selector) {
        if(typeof selector[field] == "string") {
          selector[field] = selector[field].trim();
        }
      }

      if( !_.isUndefined( this.state.customerId ) ) {
        selector.customerId = this.existingValueSearch(this.state.customerId);
        if ( selector.customerId === '' ) {
          delete selector.customerId;
        }
      }



      //transform riding codes
      if ( _.isArray( this.state.ridingCode ) && !!this.state.ridingCode.length ) {
        selector['ridingCode'] = { $in: this.state.ridingCode };

      } else {
        delete selector['ridingCode'];
      }

      //gets the values that will impact he search and formats them for the API
      const contactPreferences = this.state.contactPreferences;

      for( const field in contactPreferences) {
        if(typeof contactPreferences[field] == 'boolean') {
          selector[`contactPreferences.${field}`] = contactPreferences[field]
        }
      }

      delete selector.contactPreferences;

      //removes the message object used only for the UI
      delete selector.message;
    }

    //if we pass the prop mobilePhoneRequired to the component
    //pass this filter to every request
    if(!!this.props.mobilePhoneRequired) {
      selector.mobilePhone = {$ne: null};
    }
    
    //sets the collection field for the API
    // selector.collection = this.props.collection;
    this.props.setQuery( selector )

    //adds limit and skip in order to display just a part of the query
    // search.limit = this.props.limit;
    // search.skip = this.props.skip;
    // this.props.searchSupporter(search);
    // event.preventDefault();
  }

  addRidingCode(code) {
    let codes = !!this.state.ridingCode.length && this.state.ridingCode || [];
    codes.push(code);
    this.setState({ ridingCode : _.uniq( codes ) });
  }

  removeRidingCode(code) {
    let codes = this.state.ridingCode;
    codes = codes.length > 1 && _.reject( codes, ( val ) => val == code ) || '';
    this.setState({ ridingCode : _.uniq( codes ) });
  }

  componentWillMount() {
    const ridingCodes = this.parseRidingCode( this.state.ridingCode );
    this.setState({ ridingCode: ridingCodes });
  }

  parseRidingCode( code ) {
    if ( !!code && _.isObject(code) && !!code.$in ) {
      return code.$in;
    }

    if ( !!code && _.isObject(code) && _.isArray(code) ) {
      return code;
    }

    return [];
  }

  parseNeObj( val ) {
    // alert('val is empty strinig ' + val == '');
    if ( val === '' ) {
      return val;
    }

    if ( _.isObject(val) &&  _.isNull(this.state.customerId.$ne) ) {
      return true;
    }
    if ( val === true ) {
      return true;
    }
    if ( val === false || val == 'false' || _.isNull( val ) ) {
      return false;
    }

    return '';

  }


  render() {

    const numberOfSupporters = `${this.props.count} ${this.props.collection}${this.props.count > 1 || this.props.count === 0 ? "s" : ""} match your criteria`;

    console.log(this.state);

    const disabled = this.props.collection === "prospect" ? true : false;

    //show those only if we are in the send blast view
    const prospectCheckbox = this.props.prospectOption ?
      <Fragment>
        <Col md="6">
          <FormCheckbox
            checked={this.props.collection === "prospect"}
            onChange={e => this.changeCollection(e)}
          >Prospect list
          </FormCheckbox>
        </Col>

        <Col md="6" className="form-group" disabled>
          <label htmlFor="limit">Limit</label>
          <FormInput
            id="limit"
            type="text"
            value={this.state.limit}
            placeholder="limit"
            onChange={e => this.setState({ limit : e.target.value })}
          />
        </Col>
      </Fragment> : "";




    return (
      <div>
      <CardHeader>
      {/* <h6 className="text-center"> Collection : {this.props.collection}</h6> */}
      <h6 className="text-center">{numberOfSupporters}</h6>
      </CardHeader>

      <div className="m-3">
        <QueryBuilder 
          collection={'supporters'}
          setSelector={this.props.setQuery}
          setSkip={()=>{}}
          defaultSelector={{mobilePhone: { $ne: null}, "contactPreferences.okContact" : true, "contactPreferences.okSMS" : true}}
          />
      </div>
      

      {/* <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>

                    {prospectCheckbox}

                  <Col md="4" className="form-group" disabled>
                    <label htmlFor="firstName">First Name</label>
                    <FormInput
                      id="firstName"
                      type="text"
                      value={this.state.firstName}
                      placeholder="First Name"
                      onChange={e => this.setState({ firstName : e.target.value })}
                      disabled={disabled || this.isDisabled('firstName') }
                    />
                  </Col>
                  <Col md="4">
                    <label htmlFor="lastName">Last Name</label>
                    <FormInput
                      id="feInputLastName"
                      type="text"
                      value={this.state.lastName}
                      placeholder="Last Name"
                      onChange={e => this.setState({ lastName : e.target.value })}
                      disabled={disabled || this.isDisabled('lastName')}
                    />
                  </Col>
                  <Col md="4" className="form-group" disabled>
                    <label htmlFor="PIN">PIN</label>
                    <FormInput
                      id="PIN"
                      type="text"
                      value={this.state.PIN}
                      placeholder="PIN"
                      onChange={e => this.setState({ PIN : e.target.value })}
                      disabled={disabled || this.isDisabled('PIN') }
                    />
                  </Col>
                </Row>

                <Row form>
                  <Col md="3" className="form-group" disabled>
                    <label htmlFor="feInputCity">City</label>
                    <FormInput
                      id="feInputCity"
                      type="text"
                      value={this.state.city}
                      placeholder="City"
                      onChange={e => this.setState({ city : e.target.value })}
                      disabled={disabled | this.isDisabled('city')}
                    />

                  </Col>
                  <Col md="3">
                    <label htmlFor="feInputProvince">Province</label>
                    <FormSelect
                      id="feInputProvince"
                      value={this.state.province}
                      onChange={e => this.setState({ province : e.target.value })}
                      disabled={disabled || this.isDisabled('province')}
                      >
                      <option></option>
                      <option>Alberta</option>
                      <option>British Columbia</option>
                      <option>Prince Edward Island</option>
                      <option>Manitoba</option>
                      <option>New Brunswick</option>
                      <option>Nova Scotia</option>
                      <option>Ontario</option>
                      <option>Quebec</option>
                      <option>Saskatchewan</option>
                      <option>Newfoundland and Labrador</option>
                      <option>Nunavut</option>
                      <option>Northwest Territories</option>
                      <option>Yukon</option>
                    </FormSelect>
                  </Col>
                  <Col md="3">
                    <label htmlFor="postalCode">Postal Code</label>
                    <FormInput
                      id="postalCode"
                      type="text"
                      value={this.state.postalCode}
                      placeholder="XXX XXX"
                      onChange={e => this.checkPostalCode(e.target.value)}
                      disabled={disabled || this.isDisabled('postalCode')}
                      // if an Error message exists, shows the invalid CSS and FormFeedBack component
                      invalid={!!this.state.message.postalCode}
                    />
                    <FormFeedback>{this.state.message.postalCode}</FormFeedback>
                  </Col>
                  <Col md="3">
                    <label htmlFor="feInputLanguage">Language</label>
                    <FormSelect
                      id="feInputLanguage"
                      value={this.state.language}
                      onChange={e => this.setState({ language : e.target.value })}
                      disabled={disabled || this.isDisabled('language')}
                      >
                      <option></option>
                      <option>English</option>
                      <option>French</option>
                    </FormSelect>
                  </Col>
                </Row>

                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="feInputEmailAddress">Email</label>
                    <FormInput
                      id="email"
                      type="text"
                      value={this.state['emails.address'] || ""}
                      placeholder="friend@email.ca"
                      onChange={e => this.setState({ 'emails.address' : e.target.value })}
                      disabled={disabled || this.isDisabled('emails.address')}

                    />

                  </Col>
                  <Col md="3">
                    <label htmlFor="homePhone">Home Phone</label>
                    <FormInput
                      id="homePhone"
                      type="text"
                      value={this.state.homePhone || ""}
                      placeholder="+1XXXXXXXXXX"
                      onChange={e => this.setState({ homePhone : e.target.value })}
                      disabled={disabled || this.isDisabled('homePhone')}

                    />
                  </Col>
                  <Col md="3">
                    <label htmlFor="customerId">Mobile Phone</label>
                    <FormInput
                      id="mobilePhone"
                      type="text"
                      value={this.state.mobilePhone || ""}
                      placeholder="+1XXXXXXXXXX"
                      onChange={e => this.setState({ mobilePhone : e.target.value })}
                      disabled={disabled || this.isDisabled('mobilePhone')}

                    />
                  </Col>
                </Row>

                <Row form>
                  <Col md="6">
                    <label htmlFor="feInputRidingName">Ridings</label>
                    <RidingTagSelect
                      addRidingCode={this.addRidingCode}
                      removeRidingCode={this.removeRidingCode}
                      value={ this.state.ridingCode }
                    />
                  </Col>
                  <Col md="3">
                    <label htmlFor="PACStatus">PAC Status</label>
                    <FormSelect
                      id="PACStatus"
                      value={this.state.PACStatus}
                      onChange={e => this.setState({ PACStatus : e.target.value })}
                      disabled={disabled}
                      >
                      <option></option>
                      <option>active</option>
                      <option>cancelled</option>
                      <option>lapsed</option>
                      <option>uncollectable</option>
                    </FormSelect>
                  </Col>
                  <Col md="3">
                    <label htmlFor="customerId">Has Stripe Account</label>
                    <FormSelect
                      id="customerId"
                      value={ this.parseNeObj(this.state.customerId) }
                      onChange={e => {
                        let returnVal = '';
                        // alert('inchange' + e.target.value);
                        if( e.target.value == 'true' ) {
                          returnVal = true;
                        }
                        if( e.target.value == 'false' ) {
                          returnVal = false;
                        }
                        this.setState({customerId : returnVal });
                      }}
                      disabled={disabled || this.isDisabled('customerId') }
                      >
                      <option></option>
                      <option>true</option>
                      <option>false</option>
                    </FormSelect>
                  </Col>
                </Row>
                <hr/>
                <Row form>
                {Object.keys(this.state.contactPreferences).map( key => {

                  const title = () => {
                    if( key.includes("optIn")) {
                      return key;
                    }
                    return `Allows ${key.replace( "ok", "")}`
                  }
                  const returnValue = {
                    "true" : true,
                    "false" : false,
                    "" : ""
                  }
                  return (
                    <Col md="3" key={[key]}>
                    <label htmlFor={key}>{title()}</label>
                      <FormSelect
                        value={this.state.contactPreferences[key]}
                        key={[key]}
                        onChange={e => {
                          const contactPreferences = this.state.contactPreferences;
                          contactPreferences[key] = returnValue[e.target.value];
                          this.setState({contactPreferences})}}
                        disabled={disabled || this.isDisabled(key) }
                        >
                        <option></option>
                        <option>true</option>
                        <option>false</option>
                      </FormSelect>
                  </Col>
                  )
                })}
                </Row>

                <Row className='my-2'>
                  <Col>
                      <Button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          return this.submit()}}>Search</Button>
                  </Col>
                </Row>

              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup> */}
      </div>
    )
  }
};

export default SearchSupporter;
