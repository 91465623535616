'use strict';
//LIBS
import React, { useState } from "react";
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'underscore';

//UI
import { toast } from 'react-toastify';
import {
  Container,
  Row
} from "shards-react";

//OUR COMPONENTS
import PageTitle from "../../components/common/PageTitle";

import SetPageMetas from '../../utils/SetPageMetas';
import SelectReportOptions from './select.js';
import DupeWizard from './DupeWizard.js';

//QUERIES AND MUTATIONS
import { GET_SUPPORTERS } from '../../friendsComponents/SupporterTable.js';
import { CLEAN_SUPPORTER_REPORT } from './gql.js';

const CleanSupporters = () => {

  //set the tab info
  SetPageMetas({ title: "Clean Supporters" });



  //store for filters
  const [ selection, setSelection ] = useState('duplicates');
  const [ startDate, setStartDate ] = useState( moment( new Date() ).subtract(1, 'month').startOf('month').toDate() ); // start at the beginning
  const [ endDate, setEndDate ] = useState( new Date() );
  const [ getSupporterReport, { data, loading, error }] = useLazyQuery(CLEAN_SUPPORTER_REPORT);
  const [ currentIndex, setCurrentIndex ] = useState(-1);
  const [ wizardTotal, setWizardTotal ] = useState(0);
  const [ allowError, setAllowError ] = useState(false);


  const total = !!data && !!data.getCleanSupportersReport && data.getCleanSupportersReport.count || 0;


  function handleStartDateChange(val, e) {
    if ( !!endDate && moment(endDate).isBefore( val ) ) {
      return alert('Start date cannot be before end date');
    }
    setStartDate(val);
  };
  function handleEndDateChange(val) {
    if ( !!startDate && moment(startDate).isAfter( val ) ) {
      return alert('Start date cannot be before end date');
    }
    setEndDate(val);
  };

  function runReport() {
    const reportOpts = {
      variables : {
        startDate  : startDate,
        endDate    : endDate,
        reportName : selection
      }
    };

    setAllowError(true);
    getSupporterReport(reportOpts);
 }

 if ( !!allowError && !!data && ( data.getCleanSupportersReport == null || !data.getCleanSupportersReport.count )  ) {
   toast.error("No records match your query");
   setAllowError(false);
 }


  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={!!wizardTotal && `Clean ${numeral(currentIndex + 1).format("0,00")} of ${numeral(wizardTotal).format("0,00")}` || "Clean"} subtitle='Supporters' className="text-sm-left"/>
      </Row>

      { !data || !data.getCleanSupportersReport || !data.getCleanSupportersReport.count ? <SelectReportOptions
                  selection={selection}
                  loading={loading}
                  setSelection={setSelection}
                  onSubmit={runReport}
                  startDate={startDate}
                  endDate = {endDate}
                  handleStartDateChange={ handleStartDateChange }
                  handleEndDateChange={ handleEndDateChange }
                /> : ""}

        { !!data && !!data.getCleanSupportersReport && !!data.getCleanSupportersReport.count > 0   ? <DupeWizard
            data={data.getCleanSupportersReport}
            setCurrentIndex={ setCurrentIndex }
            currentIndex={ currentIndex }
            setWizardTotal = { setWizardTotal }
            wizardTotal={ wizardTotal }
            reportName={ selection }

          /> : '' }

    </Container>
  );
}

export default CleanSupporters;
