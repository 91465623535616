import React, { useState } from "react";
import {Modal} from "react-bootstrap";
import CreateBankRun from "../friendsComponents/CreateBankRun";
import {
  Container,
  Row,
  Card,
  CardBody,
  CardHeader
} from "shards-react";
import { Link } from "react-router-dom";
import { Query } from 'react-apollo';
import PageTitle from "../components/common/PageTitle";
import SetPageMetas from '../utils/SetPageMetas';
import InlineTitleButton from "../friendsComponents/InlineTitleButton";
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import moment from 'moment';
import _ from "underscore";
import numeral from "numeral";


export const GET_BANKRUNS = gql`
  query getBankRuns($selector: Selector, $limit: Int, $skip: Int, $sort: GenericObject) {
    getBankRuns(selector: $selector, limit: $limit, skip: $skip, sort: $sort){
        count
        bankRuns {
          _id
          created
          runDate
          status
          bankRunCode
          count {
            expected
            processed
            declined
          }
          amount {
            expected
            processed
            declined
          }

        }
      }
    }
`;


const BankRuns = (props) => {

  SetPageMetas({ title: "Bank Runs" });


  //new bank run modal :
  const [createModal, setCreateModal] = useState(false);
  const handleCloseModal              = () => setCreateModal(false);
  const handleShowModal               = () => setCreateModal(true);

  const [limit, setLimit]             = useState(20);

  const [ loading, setLoading ]       = useState(false);
  const [ pages, setPages ]           = useState(0);

  const columns = [{
    Header    : 'Bank Run Code',
    className : "text-center",
    accessor  : 'bankRunCode'
  },
  {
    Header    : 'Status',
    className : "text-center",
    accessor  : 'status'
  },
  {
    Header    : 'Run Date',
    className : "text-center",
    accessor  : 'runDate',
    Cell: row => {
      return moment(row.original.runDate).format('ll')
    }
  },
  {
    Header    : 'Count',
    className : "text-center",
    accessor  : 'count.expected'
  },
  {
    Header    : 'Processed',
    className : "text-center",
    accessor  : 'count.processed',
    Cell: row => {
      if(!!row.original.count.processed) {
        return row.original.count.processed
      }
      return "-";
    }
  },
  {
    Header    : 'Declined',
    className : "text-center",
    accessor  : 'count.declined',
    Cell: row => {
      if(!!row.original.count.declined) {
        return row.original.count.declined
      }
      return "-";
    }
  },
  {
    Header    : 'Expected Amount',
    className : "text-center",
    accessor  : 'amount.expected',
    Cell: row => {
      return `$${numeral(row.original.amount.expected/100 ).format("0,0.00")}`
    }
  },
  {
    Header    : 'Final Amount',
    className : "text-center",
    accessor  : 'amount.processed',
    Cell: row => {
      if(!!row.original.amount.processed) {
        return `$${numeral(row.original.amount.processed/100 ).format("0,0.00")}`
      }
      return "-";
    }
  },
  {
    Header   : 'Actions',
    accessor : "actions",

    sortable : false,
    Cell     : row => {

      return <Link
        to={`/bankrun/${row.original._id}`}
        className='btn btn-white'
        >
        Details
      </Link>
    }
  }]

  return (

  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Bank Runs" subtitle="list" className="text-sm-left"/>
      <InlineTitleButton sm='8' onClick={() => {handleShowModal()}} text="+ New Bank Run"/>
    </Row>
    <Card small className="mb-4">

        <Query
          query={GET_BANKRUNS}
          variables={{
            limit,
            skip : 0,
            sort: { created: -1 }
          }}
          fetchPolicy="network-only"
          onError={(e) => {
            toast.error("There was a problem");
          }}
          >
          {({ data, loading, error, fetchMore }) => {
            if (error) return <p>ERROR: {error.message}</p>;

            return (
              <Card className="p-0">
                <Card className="p-0">
                  <CardHeader>
                  <h6 className="text-center">Bank Runs</h6>
                  </CardHeader>
                </Card>
                <CardBody className="p-0">
                  <ReactTable
                    data={ !!data && !!data.getBankRuns && data.getBankRuns.bankRuns || []}
                    columns={columns}
                    loading={loading}
                    defaultPageSize={20}
                    showPageJump={false}
                    manualPagination={true}
                    manual
                    noDataText={`No Bank Run to display`}
                    pages = { pages }
                    onFetchData={(state, instance) =>  {

                      //create sort object with default being createdDate desc
                      let sort = { created: -1 }
                      state.sorted.forEach( (sortField, i) => {
                        //if we have anything here, overwrite default
                        if ( i == 0 ) {
                          sort = {};
                        }
                        sort[sortField.id] = !!sortField.desc && -1 || 1;
                      });
                      //tell the table we're loading
                        setLoading(true);

                        //get the data
                        fetchMore({
                          variables: {
                            // skip: state.pageSize * (state.page + 1),
                            skip: state.pageSize * state.page,
                            limit: state.pageSize,
                            sort
                          },

                          updateQuery: (prev, { fetchMoreResult }) => {

                            //update the data
                            if (!fetchMoreResult.getBankRuns.bankRuns) {
                              setPages(Math.ceil(prev.getBankRuns.count / state.pageSize));
                              return prev;
                            }
                            setPages(Math.ceil(fetchMoreResult.getBankRuns.count / state.pageSize));
                            const newObj = {
                              getBankRuns: {
                                ...prev.getBankRuns,
                                bankRuns : fetchMoreResult.getBankRuns.bankRuns
                              }
                            };
                            //disable loading state
                            setLoading(false);
                            return Object.assign({}, prev, newObj);
                          }
                        })

                    }}

                  />
                </CardBody>
              </Card>
            );
          }}
        </Query>
    </Card>

     {/* Create BankRun Modal */}
     <Modal
            size="md"
            // centered
            show={createModal}
            onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>New Bank Run</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CreateBankRun
                  handleClose={handleCloseModal}
                />
              </Modal.Body>
            </Modal>

  </Container>
)};

export default BankRuns;
