import { routesPermissions } from "./users-roles-data";
import _ from "underscore";

const nav = [
  {
    title: 'Supporters',
    items: [
      {
        title: 'Search',
        htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
        to: '/supporter'
      },
      {
        title: 'Clean',
        htmlBefore: '<i class="material-icons">merge_type</i>',
        to: '/supporter/clean'
      }
    ]
  }, {
    title: 'Bank Runs',
    items: [
      {
        title: 'Bank Runs',
        htmlBefore: '<i class="material-icons">account_balance</i>',
        to: '/bankrun'
      }
    ]
  }, {
    title: 'Transactions',
    items: [
      {
        title: 'Search',
        htmlBefore: '<i class="material-icons">&#xe227;</i>',
        to: '/transactions'
      }
    ]
  }, {
    title: 'Communications',
    items: [
      {
        title: 'Inbox ',
        htmlBefore: '<i class="material-icons">&#xE625;</i>',
        to: '/sms/inbox',
        badgeInbox: true
      }, {
        title: 'SMS Blasts',
        htmlBefore: '<i class="material-icons">&#xe0dd;</i>',
        to: '/sms/blast'
      }
    ]
  }, {
    title: "Campaigns",
    items: [
      {
        title: 'Campaigns',
        htmlBefore: '<i class="material-icons">people</i>',
        open: false,
        to: '/campaign'
      }
    ]
  }, {
    title: "Admin",
    items: [
      {
        title: 'Users',
        htmlBefore: '<i class="material-icons">people</i>',
        open: false,
        to: '/admin/users'
      }, {
        title: 'Services',
        htmlBefore: '<i class="material-icons">map</i>',
        open: false,
        to: '/admin/services'
      }
    ]
  }
];

export default function() {

  const user = JSON.parse(localStorage.getItem('user'));

  const permittedNav = [];

  nav.forEach((route) => {
    let filteredRoute = route;

    //filter the nav items based on user's Roles
    const filteredItems = route.items.filter(item => {
      const path = item.to;
      const authorization = routesPermissions[path];
      return !!authorization && hasRightsToAccess({user, authorization})
    });

    //only return the group of nav items if there is at least one nav link to show
    if (!!filteredItems.length) {
      filteredRoute.items = filteredItems;
      permittedNav.push(filteredRoute);
    }
  });

  return permittedNav;
};

export const hasRightsToAccess = ({user, authorization}) => {
  if (!user) {
    return false;
  }

  if (authorization.service === "app") {
    return true;
  }
  if (authorization.service === "gateway") {
    return !!_.intersection([user.gatewayRole], authorization.roles).length;
  }

  const userRolesForService = user.roles[authorization.service];
  if (!userRolesForService) {
    return false;
  }
  return !!_.intersection(userRolesForService, authorization.roles).length;
};
