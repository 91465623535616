import React from "react";
import validator from "validator";
import postcode from "postcode-validator";
import reactTriggerChange from 'react-trigger-change';
import {
  Row,
  Col,
  FormGroup,
  FormInput,
  FormSelect,
  FormFeedback

} from "shards-react";

const removeSpaces = (input) => {
  return input.split("").map( str => str.replace(/\s/g, '')).join('');
}

class CanadaPostAddress extends React.Component {
  constructor(props) {
    super(props);
  }
  
  fireChangeEvents(address) {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
    const elems = document.querySelectorAll('.pcaControl');


    [...elems].forEach( node => {
      switch (node.id) {
        case "address1":
          node.value = address.Line1;
          break;
        case "address2":
          node.value = address.Line2;
          break;
        case "city":
          node.value = address.City;
          break;
        case "province":
          node.value = address.ProvinceCode;
          break;
        case "postalCode":
          node.value = address.PostalCode;
          break;
        default:
      }

      reactTriggerChange( node );
    });

    //canada post autocomplete footers still showing after set.
    //force close to simulate real blur
    const footers = document.querySelectorAll(".pcafooter");
    [...footers].forEach( footer => footer.style.display = 'none' );

    return true;
  }

  setMergeViewClass(fieldName) {
    let baseClass = !this.props.disableOverride && 'p-2' || '';
    let fieldSelected = this.props.selections && this.props.selections[fieldName] == this.props.supporter._id;
    let disableField  = !!this.props.fields && !this.props.fields.includes(fieldName);

    if ( !!fieldSelected && !disableField ) {
      baseClass += ' merge-selected';
    } else if (!!disableField) {
      baseClass += ' disable merge-unselected';
    } else {
      baseClass += ' merge-unselected';
    }

    return baseClass;
  }


  componentDidMount() {

    //initialize canada post autocomplete
      const fields = [
        { element: "address1", field: "Line1" },
        { element: "address2", field: "Line2", mode: window.pca.fieldMode.POPULATE },
        { element: "city", field: "City", mode: window.pca.fieldMode.POPULATE },
        { element: "province", field: "ProvinceCode", mode: window.pca.fieldMode.POPULATE },
        { element: "postalCode", field: "PostalCode", mode: window.pca.fieldMode.POPULATE }
      ];
      const options = {
          key: "mp78-gt82-gm99-jj87"
      };

      const addressComplete = new window.pca.Address(fields, options);

      addressComplete.listen('populate', this.fireChangeEvents);
  }

  render() {
   return (
     <div>
     <Row className="mb-3">
       <Col
         className={ !!this.props.mergeView && this.setMergeViewClass('address1') || '' }
         onClick={( (e) => {

         if(!this.props.onSelectField) {
           return;
         }
         if ( !!this.props.fields && !this.props.fields.includes('address1') ) {
           return;
         }
         this.props.onSelectField("address1", this.props.supporter.address1, this.props.supporter._id)}
        )}>
        <FormGroup >
         <label>Address</label>
         <FormInput
           id="address1"
           name="address1"
           className='pcaControl'
           type="text"
           value={!! this.props.supporter && this.props.supporter.address1 || ''}
           placeholder="Address"
           disabled={!!this.props.mergeView && !this.props.disableOverride }
           onChange={e => {
             this.props.onChange({input : e.target.value, field : "address1"})
           }}
         />
       </FormGroup>
     </Col>
     <Col
       className={ !!this.props.mergeView && this.setMergeViewClass('address2') || '' }
       onClick={( (e) => {

       if(!this.props.onSelectField) {
         return;
       }
       if ( !!this.props.fields && !this.props.fields.includes('address2') ) {
         return;
       }
       this.props.onSelectField("address2", this.props.supporter.address2, this.props.supporter._id)}
      )}>
       <FormGroup >
         <label htmlFor='address2'>Address Line 2</label>
         <FormInput
           id="address2"
           name="address2"
           type="text"
           className='pcaControl'
           value={!!this.props.supporter && this.props.supporter.address2 ? this.props.supporter.address2 : ""}
           placeholder="Address Line 2"
           disabled={!!this.props.mergeView && !this.props.disableOverride }
           onChange={e => {
             if ( !!this.props.mergeView && !!this.props.disableOverride ) {
               this.props.onSelectField("address2", e.target.value, this.props.supporter._id);
             } else {
               this.props.onChange({input : e.target.value, field : "address2"});
             }
           }}
         />
        </FormGroup>
      </Col>
     </Row>
     <Row className="mb-3">
       <Col
         className={ !!this.props.mergeView && this.setMergeViewClass('city') || '' }
         onClick={( (e) => {

         if(!this.props.onSelectField) {
           return;
         }
         if ( !!this.props.fields && !this.props.fields.includes('city') ) {
           return;
         }
         this.props.onSelectField("city", this.props.supporter.city, this.props.supporter._id)}
        )}>
        <FormGroup >
         <label>City</label>
         <FormInput
           id="city"
           type="text"
           className='pcaControl'
           value={this.props.supporter.city|| ""}
           placeholder="City"
           disabled={!!this.props.mergeView && !this.props.disableOverride }
           onChange={e => {
             if ( !!this.props.mergeView && !!this.props.disableOverride ) {
               this.props.onSelectField("city", e.target.value, this.props.supporter._id)
             } else {
               this.props.onChange({input : e.target.value, field : "city"})
             }
           }}
         />
       </FormGroup>
       </Col>
       <Col
         className={ !!this.props.mergeView && this.setMergeViewClass('postalCode') || '' }
         onClick={( (e) => {

         if(!this.props.onSelectField) {
           return;
         }
         if ( !!this.props.fields && !this.props.fields.includes('postalCode') ) {
           return;
         }
         this.props.onSelectField("postalCode", this.props.supporter.postalCode, this.props.supporter._id)}
        )}>
         <FormGroup >
         <label>Postal Code</label>
         <FormInput
           id="postalCode"
           name='postalCode'
           type="text"
           className='pcaControl'
           value={!!this.props.supporter && this.props.supporter.postalCode || ''}
           placeholder="Postal Code"
           disabled={!!this.props.mergeView && !this.props.disableOverride }
           onChange={e => {
             // alert('postal code on change triggered');
             const input = removeSpaces(e.target.value.toUpperCase());
             const validPostalCode = postcode.validate(input, 'CA');

             if ( !!this.props.mergeView && !!this.props.disableOverride ) {
               if( !!validPostalCode ) {
                 this.props.onSelectField("postalCode", e.target.value, this.props.supporter._id)
               }
             } else if (!!validPostalCode) {
               this.props.onChange({
                 input,
                 field : "postalCode",
                 error : ""
               })
             } else {
               if(!!input.length) {
                 this.props.onChange({
                   input,
                  field : "postalCode",
                  error: 'Postal Code is not valid'
                })
              } else {
                 this.props.onChange({
                   input,
                  field : "postalCode",
                  error: ''
                });
              }
            }
           }}
           invalid={!!this.props.errorMessages && !!this.props.errorMessages.postalCode}
         />
         <FormFeedback>{!!this.props.errorMessages && this.props.errorMessages.postalCode}</FormFeedback>
         </FormGroup>
       </Col>
       <Col
        className={ !!this.props.mergeView && this.setMergeViewClass('province') || '' }
         onClick={( (e) => {

         if(!this.props.onSelectField) {
           return;
         }
         if ( !!this.props.fields && !this.props.fields.includes('province') ) {
           return;
         }
         this.props.onSelectField("province", this.props.supporter.province, this.props.supporter._id)}
        )}>
         <FormGroup >
       <label>Province</label>
       <FormSelect
         id="province"
         className='pcaControl'
         defaultValue={this.props.supporter.province || ''}
         disabled={!!this.props.mergeView && !this.props.disableOverride }
         onChange={e => {
           if ( !!this.props.mergeView && !!this.props.disableOverride ) {
             this.props.onSelectField("province", e.target.value, this.props.supporter._id)
           } else {
             this.props.onChange({input : e.target.value, field : "province"})
           }
         }}
         >
         <option> </option>
         <option value="AB">Alberta</option>
         <option value="BC">British Columbia</option>
         <option value="PE">Prince Edward Island</option>
         <option value="MB">Manitoba</option>
         <option value="NB">New Brunswick</option>
         <option value="NS">Nova Scotia</option>
         <option value="ON">Ontario</option>
         <option value="QC">Québec</option>
         <option value="SK">Saskatchewan</option>
         <option value="NL">Newfoundland and Labrador</option>
         <option value="NU">Nunavut</option>
         <option value="NT">Northwest Territories</option>
         <option value="YT">Yukon</option>
       </FormSelect>
       </FormGroup>
       </Col>
     </Row>
   </div>
   );
  }
}


export default CanadaPostAddress;
