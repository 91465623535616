import React, {useState, Fragment} from "react";
import CommentAction from "./CommentAction";
import {
  Button,
  Form,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Col,
  Row,
  FormCheckbox,
  ButtonGroup
} from "shards-react";

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';

export const EDIT_CHEQUE = gql`
  mutation editCheque($cheque: GenericObject, $supporterId: String, $amount: Int, $bounced: Boolean, $opts: GenericObject) {
    editCheque(cheque: $cheque, supporterId : $supporterId, amount: $amount, bounced: $bounced, opts: $opts) {
      n
      nModified
      ok
    }
  }
`;

const EditCheque = ({cheque, chequeId, status, supporterId, handleClose}) => {
  console.log({cheque, status, supporterId, handleClose})
  const [showModal, setShowModal]       = useState(false);
  const [opts, setOpts]                 = useState({});

  const [updateCheque, setUpdateCheque] = useState( {
    amount  : (cheque.data.amount/100).toFixed(2),
    bounced : false
  })

  const [editCheque, {data, loading, error}] = useMutation(EDIT_CHEQUE, {
    onError : (e) => {
      for (const error of e.graphQLErrors) {
        //check if the error is within GraphQL or sent back from the server
        // in order to retirce the error message at the right place
        const errorMessage = error.extensions.response ?
        error.extensions.response.body.error.message :
        error.message;
        toast.error(errorMessage);
      }
    },
    onCompleted : ({editCheque}) => {
      toast.success("cheque edited");
      if(!!handleClose) {
        handleClose();
      }
    },
    refetchQueries: ['getSupporter', 'getActions']
  })

  return(
    <Form>
      <Card>
    
      <CardBody>
      <Row>
            <Col md="12" lg="6" >
            <label>Edit the amount :</label>
            <InputGroup className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
              <FormInput
                id="month"
                type="text"
                value={updateCheque.amount || ""}
                placeholder="00.00"
                onChange={e => setUpdateCheque({...updateCheque, amount : e.target.value})}
              />
              </InputGroup>
            </Col>
            <Col md="12" lg="6">
            <label>Mark as rejected:</label>
              <FormCheckbox
                checked={!!updateCheque.bounced}
                onChange={e => setUpdateCheque({...updateCheque, bounced : !updateCheque.bounced})}
              > Bounced
              </FormCheckbox>
            </Col>
          </Row>
          <Button
            onClick={()=> {

              const opts = {
                cheque, 
                supporterId,
                opts : {}
              }

        
              if(cheque.amount !== (updateCheque.amount * 100)) {
                opts.amount = updateCheque.amount * 100
              }
              if(!!updateCheque.bounced) {
                opts.bounced = true
              }
              
              setOpts({
                  variables: opts
              }
              );
              setShowModal(true);

            }}
            >
            Save changes
          </Button>
      </CardBody>
     
      </Card>
      <CommentAction
        action={`Edit cheque`}
        confirmation={true}
        mutation={editCheque}
        showModal={showModal}
        setShowModal={setShowModal}
        opts={opts} />
    </Form>
  )

}

export default EditCheque;