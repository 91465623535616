import React, { useState } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Container, Row, Col, Button, ButtonGroup } from "shards-react";

import Loading from "../friendsComponents/Loading";
import PageTitle from "../components/common/PageTitle";
// import RangeDatePicker from "../components/common/RangeDatePicker";
import SmallStats from "../components/common/SmallStats";
import moment from 'moment';


import AnalyticsOverview from "../components/analytics/Overview";
import PACAnalytics from "../components/analytics/PACAnalytics.js";
// import CampaignAnalytics from "../components/analytics/Campaigns";
import ChannelAnalytics from "../components/analytics/Channels";


import { contains } from "underscore";

import colors from "../utils/colors";

//import { dummyData } from '../components/analytics/data.js';


export const GET_ANALYTICS = gql`
  query getAnalytics($selector: Selector, $sort: GenericObject, $limit: Int) {
    getAnalytics(selector: $selector, sort: $sort, limit: $limit) {
      _id
      PACData
      campaignData
      channelData
      month
      year
      endDate
    }
  }
`;

const Analytics = ({ smallStats, match }) => {
  const [ activePanel, setActivePanel ] = useState('Overview');

  const user               = JSON.parse(localStorage.getItem("user"));
  const hasAnalyticsRights = user.gatewayRole === "super-admin";
  const isCaller           = !!contains(user.roles.campaign, "caller");

  const twelveMonthsAgo = moment( new Date() ).subtract(1, 'year').toDate();

  const queryOpts = {
    variables: {
      selector: {
        month: { $exists : true },
        year: { $exists : true }
      },
      sort: { endDate: -1 },
      limit : 12
    }
  };


  const {data, loading, error} = useQuery(GET_ANALYTICS, queryOpts);

  console.log({data})

  if ( !!loading ) {
    return <Loading />
  }

  if ( !!error ) {
    console.log(error)
    return <p>{error}</p>
  }

  const panels = [
    "Overview",
    "PAC",
    // "Campaigns",
    "Channels"
  ];

  if(!hasAnalyticsRights) {

    //if user is a caller redirect them directly to the campaign page;
    if(!!isCaller) {
      return <Redirect to={'/campaign'} />
    }
    return(
      <Container fluid className="main-content-container px-4">
        <h3 align="center">{`Welcome ${user.firstName}`}</h3>
      </Container>)
  }


  return (

  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      {/* Page Header :: Title */}
      <PageTitle title="Analytics" subtitle={activePanel} className="text-sm-left mb-3" />

      {/* Page Header :: Actions */}
      <Col xs="12" sm="4" className="col d-flex align-items-center">
        <ButtonGroup size="sm" className="d-inline-flex mb-3 mb-sm-0 mx-auto">
          { panels.map( p => {
            return <Button key={p}
                      theme={ activePanel === p && 'primary' || 'white' }
                      onClick={ (e) => {
                        setActivePanel(p);
                      }}
                    >{p}</Button>
          })}
        </ButtonGroup>
      </Col>

      {/* Page Header :: Datepicker */}
      {/* <Col sm="4" className="d-flex">
        <RangeDatePicker
          className="justify-content-end"
          startDate={ moment( new Date() ).startOf("month").toDate() }
          endDate={ moment( new Date() ).endOf("month").toDate() }
        />
      </Col> */}
    </Row>

    { activePanel === 'Overview' ? <AnalyticsOverview data={data.getAnalytics}/> : "" }
    { activePanel === 'PAC' ? <PACAnalytics data={data.getAnalytics}/> : "" }
    {/* { activePanel === 'Campaigns' ? <CampaignAnalytics /> : "" } */}
    { activePanel === 'Channels' ? <ChannelAnalytics data={data.getAnalytics}/> : "" }



    {/* Small Stats Blocks */}
    {/* <Row>
      // {smallStats.map((stats, idx) => (
      //   <Col key={idx} md="6" lg="3" className="mb-4">
      //     <SmallStats
      //       id={`small-stats-${idx}`}
      //       chartData={stats.datasets}
      //       chartLabels={stats.chartLabels}
      //       label={stats.label}
      //       value={stats.value}
      //       percentage={stats.percentage}
      //       increase={stats.increase}
      //       decrease={stats.decrease}
      //     />
      //   </Col>
      // ))}
    </Row> */}

    {/* <Row>

      <Col lg="8" md="12" sm="12" className="mb-4">
        <Sessions />
      </Col>


      <Col lg="4" md="6" sm="6" className="mb-4">
        <UsersByDevice />
      </Col>


      <Col lg="3" sm="6" className="mb-4">
        <TopReferrals />
      </Col>


      <Col lg="5" className="mb-4">
        <GoalsOverview />
      </Col>


      <Col lg="4" className="mb-4">
        <CountryReports />
      </Col>
    </Row> */}
  </Container>
)};

Analytics.propTypes = {
  /**
   * The small stats data.
   */
  smallStats: PropTypes.array
};

Analytics.defaultProps = {
  smallStats: [
    {
      label: "Users",
      value: "2,390",
      percentage: "12.4%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.primary.toRGBA(0.1),
          borderColor: colors.primary.toRGBA(),
          data: [9, 3, 3, 9, 9]
        }
      ]
    },
    {
      label: "Sessions",
      value: "8,391",
      percentage: "7.21%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.success.toRGBA(0.1),
          borderColor: colors.success.toRGBA(),
          data: [3.9, 4, 4, 9, 4]
        }
      ]
    },
    {
      label: "Pageviews",
      value: "21,293",
      percentage: "3.71%",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.warning.toRGBA(0.1),
          borderColor: colors.warning.toRGBA(),
          data: [6, 6, 9, 3, 3]
        }
      ]
    },
    {
      label: "Pages/Session",
      value: "6.43",
      percentage: "2.71%",
      increase: false,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: colors.salmon.toRGBA(0.1),
          borderColor: colors.salmon.toRGBA(),
          data: [0, 9, 3, 3, 3]
        }
      ]
    }
  ]
};

export default Analytics;
