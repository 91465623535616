import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  ButtonGroup,
  Button
} from "shards-react";

import _ from "underscore";

import RangeDatePicker from "../common/RangeDatePicker";

import colors from "../../utils/colors";
import Chart from "../../utils/chart";
import moment from "moment";
import numeral from 'numeral';

class DonationActionsChart extends React.Component {
  constructor(props) {
    super(props);

    let first = _.first( this.props.data );
    let rawStart = !!first && new Date(first.date) || new Date();

    this.state = {
      startDate : moment( rawStart ).subtract(1, 'weeks').toDate(),
      endDate   : moment( new Date() ).add(1, 'days').toDate(),
      unit      : 'days'
    };

    this.rawData = props.rawData;

    this.chartDefaults = {
      fill                      : "start",
      data                      : [5, 5, 10, 30, 10, 42, 5, 15, 5],
      backgroundColor           : colors[this.props.colour].toRGBA(0.1),
      borderColor               : colors[this.props.colour].toRGBA(1),
      pointBackgroundColor      : colors.white.toHex(),
      pointHoverBackgroundColor : colors.primary.toRGBA(1),
      borderWidth               : 1.5
    };

    this.legendRef = React.createRef();
    this.canvasRef = React.createRef();

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleUnitChange = this.handleUnitChange.bind(this);
  }

  componentDidMount() {
    const chartOptions = {
      ...{
        responsive: true,
        legend: false,
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.38
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                callback(tick, index) {
                  return index % 2 === 0 ? "" : tick;
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 45
              }
            }
          ]
        },
        tooltips: {
          enabled: false,
          mode: "index",
          position: "nearest"
        }
      },
      ...this.props.chartOptions
    };

    this.DonationActionsChart = new Chart(this.canvasRef.current, {
      type    : "line",
      data    : this.generateChartData({groupBy: this.state.unit, startDate: this.state.startDate, endDate: this.state.endDate}),
      options : chartOptions
    });

    // Generate the analytics overview chart labels.
    // this.legendRef.current.innerHTML = this.DonationActionsChart.generateLegend();

    // Render the chart.
    this.DonationActionsChart.render();
  }

  componentDidUpdate() {
    let newChartData = this.generateChartData({groupBy: this.state.unit, startDate : this.state.startDate, endDate : this.state.endDate})
    this.DonationActionsChart.data.datasets = newChartData.datasets;
    this.DonationActionsChart.data.labels = newChartData.labels;

    //redraw
    this.DonationActionsChart.update();
  }

  handleStartDateChange(value) {
    const newStart = new Date(value);
    this.setState({startDate: newStart });
  }

  getPeriodStartDates({ groupBy = 'days', startDate, endDate }) {
    const formats = {
      days   : "D MMM YYYY",
      weeks  : "WWW",
      months : "WWW"
    };

    let periodCount = moment( endDate ).diff( startDate, groupBy );
    let periodArray = _.range(0, periodCount + 1).map( num => {
      return moment(startDate).add(num, groupBy || this.state.unit).format("D MMM YYYY");
    });
    return periodArray;
  }


  generateChartData({ groupBy, startDate, endDate }) {
     let rawData = _.filter( this.props.data, function (action) {
      return moment(action.date).isBetween(startDate, endDate);
    });

    let returnData = [];

    //then group it
    let periods = this.getPeriodStartDates({groupBy, startDate, endDate}); //  calculte number of periods between start and end using designated unit

    for ( var i = 0; i < periods.length; i++ ) {
      const actionsThisPeriod = _.filter( rawData, function (action) {
        return moment(action.date).isBetween( new Date( periods[i] ), new Date( periods[i + 1] || endDate ));
      }) || [];

      const amountInPeriod = !!actionsThisPeriod.length &&  actionsThisPeriod.reduce( function (tally, action) {
        return tally + action.data.amount / 100;
      } , 0) || 0;
      returnData.push( amountInPeriod );
    }

    return {
      labels:  periods,
      datasets: [ _.extend( this.chartDefaults, { data: returnData } ) ]
    };

  }

  handleEndDateChange(value) {
    this.setState({
      ...this.state,
      ...{ endDate: new Date(value) }
    });
  }

  handleUnitChange(value) {
    let newState = {
      ...this.state,
      ...{ unit: value }
    };

    //ensure the date range matches our unit
    if ( value == 'weeks' ) {
      newState.startDate = moment( this.state.startDate ).weekday(1).toDate();
      newState.endDate = moment( this.state.endDate ).weekday(7).toDate();
    } else if ( value == 'months' ) {
      newState.startDate = moment( this.state.startDate ).date(1).toDate();
      newState.endDate = moment( this.state.endDate ).date(31).toDate();
    }

    this.setState(newState);
  }

  render() {
    const { title } = this.props;

    return (
      <Card small className="h-100">

        <CardBody className="pt-0 px-4">
          <Row className="border-bottom py-2 bg-light">
            {/* Time Interval */}
            <Col sm="6" className="col d-flex mb-2 mb-sm-0">
              <ButtonGroup>

                <Button theme="white" active={ this.state.unit == 'days' } onClick={ () => this.handleUnitChange('days') }>Day</Button>
                <Button theme="white" active={ this.state.unit == 'weeks' } onClick={ () => this.handleUnitChange('weeks') } >Week</Button>
                <Button theme="white" active={ this.state.unit == 'months' } onClick={ () => this.handleUnitChange('months') } >Month</Button>
              </ButtonGroup>
            </Col>

            {/* DatePicker */}
            <Col sm="6" className="col">
              <RangeDatePicker
                className = "justify-content-end"
                startDate = {this.state.startDate}
                endDate = {this.state.endDate}
                handleStartDateChange = {this.handleStartDateChange}
                handleEndDateChange = {this.handleEndDateChange}

                />
            </Col>
          </Row>

          <div ref={this.legendRef} />
          <canvas
            height="120"
            ref={this.canvasRef}
            style={{ maxWidth: "100% !important", marginTop: "1rem" }}
            className="analytics-overview-sessions"
          />
        </CardBody>
      </Card>
    );
  }
}

DonationActionsChart.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The Chart.js data.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js config options.
   */
  chartOptions: PropTypes.object
};

DonationActionsChart.defaultProps = {
  title: "Sessions",
  chartData: {
    labels: [
      "09:00 PM",
      "10:00 PM",
      "11:00 PM",
      "12:00 PM",
      "13:00 PM",
      "14:00 PM",
      "15:00 PM",
      "16:00 PM",
      "17:00 PM"
    ],
    datasets: [
      {
        label: "Today",
        fill: "start",
        data: [5, 5, 10, 30, 10, 42, 5, 15, 5],
        backgroundColor: colors.primary.toRGBA(0.1),
        borderColor: colors.primary.toRGBA(1),
        pointBackgroundColor: colors.white.toHex(),
        pointHoverBackgroundColor: colors.primary.toRGBA(1),
        borderWidth: 1.5
      },
      // {
      //   label: "Yesterday",
      //   fill: "start",
      //   data: ["", 23, 5, 10, 5, 5, 30, 2, 10],
      //   backgroundColor: colors.salmon.toRGBA(0.1),
      //   borderColor: colors.salmon.toRGBA(1),
      //   pointBackgroundColor: colors.white.toHex(),
      //   pointHoverBackgroundColor: colors.salmon.toRGBA(1),
      //   borderDash: [5, 5],
      //   borderWidth: 1.5,
      //   pointRadius: 0,
      //   pointBorderColor: colors.salmon.toRGBA(1)
      // }
    ]
  }
};

export default DonationActionsChart;
