import React, { useState } from "react";
import {
  Container,
  Row,
  Card,
  ButtonGroup,
  Button,
  CardBody,
  CardHeader
} from "shards-react";
import { Link } from "react-router-dom";
import { Query } from 'react-apollo';
import PageTitle from "../components/common/PageTitle";
import Loading from "../friendsComponents/Loading";
import InlineTitleButton from "../friendsComponents/InlineTitleButton";
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import moment from 'moment';


export const GET_BLASTS = gql`
  query getBlasts {
    getBlasts(limit: 10){
       count
        blasts {
          _id
          sentDate
          successCount
          failCount
        }
      }
    }
`;


const BlastList = (props) => {
  const [query, setQuery] = useState({selector: {}});
  const [limit, setLimit] = useState(0);
  const [count, setCount] = useState(0);
  const [skip, setSkip]   = useState(0);
  const [actualPageIndex, setActualPageIndex] = useState(0);

  const columns = [{
    Header    : 'Sent',
    className : "text-center",
    accessor  : 'sentDate',
    Cell : row => {
      return moment(row.original.sentDate).format("DD MMM YYYY, hh:mm a");
    }
  },
  {
    Header    : 'Success',
    className : "text-center",
    accessor  : 'successCount'
  },
  {
    Header    : 'Failed',
    className : "text-center",
    accessor  : 'failCount'
  }, {
    Header   : 'Actions',
    accessor : "actions",

    sortable : false,
    Cell     : row => {

      return <Link
        to={`/sms/blast/${row.original._id}`}
        className='btn btn-white'
        >
        Blast
      </Link>
    }
  }]

  return (

  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title="Find SMS Blasts" subtitle="Blasts" className="text-sm-left"/>
      <InlineTitleButton sm='8' href="/sms/blast/create" text="+ Create SMS Blast"/>
    </Row>
    <Card small className="mb-4">

        <Query
          query={GET_BLASTS}
          variables={{limit: 20}}
          fetchPolicy="network-only"
          onError={(e) => {
            console.log(e);
            toast.error("There was a problem");
          }}

          >


          {({ data, loading, error, refetch }) => {
            if (loading) return(
              <Loading />
            );
            if (error) return <p>ERROR: {error.message}</p>;

            return (
              <Card className="p-0">
                <Card className="p-0">
                  <CardHeader>
                  <h6 className="text-center">Message Blasts</h6>
                  </CardHeader>
                </Card>
                <CardBody className="p-0">
                  <ReactTable
                    data={data.getBlasts.blasts}
                    columns={columns}
                    defaultPageSize ={20}
                    showPageJump ={false}
                    manualPagination={true}
                    noDataText={`No blasts to display`}
                    pageCount={ Math.ceil( data.getBlasts.count / limit) }
                    onPageSizeChange={(pageSize, pageIndex) => {
                      setLimit(pageSize);
                    }}
                    onPageChange={(pageIndex) => {
                      if(pageIndex > actualPageIndex) {
                        setSkip( skip + limit )
                      }
                      if(pageIndex < actualPageIndex) {
                        setSkip(skip - limit)
                      }

                    }}
                  />
                </CardBody>
              </Card>
            );
          }}
        </Query>
    </Card>

  </Container>
)};

export default BlastList;
