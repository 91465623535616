import React from "react";
import classNames from "classnames";
import {
  InputGroup,
  DatePicker,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

class RangeDatePicker extends React.Component {


  render() {
    const { className } = this.props;
    const classes = classNames(className, "d-flex", "my-auto", "date-range");

    return (
      <InputGroup className={classes}>
        <DatePicker
          size="sm"
          selected={this.props.startDate}
          onChange={this.props.handleStartDateChange}
          placeholderText="Start Date"
          dropdownMode="select"
          className="text-center"
          maxDate={ this.props.maxDateStart }
          minDate={ this.props.minDateStart }
          showYearDropdown = { this.props.showYearDropdown }
          showMonthYearPicker = { this.props.showMonthYearPicker }
        />
        <DatePicker
          size="sm"
          selected={this.props.endDate}
          onChange={this.props.handleEndDateChange}
          placeholderText="End Date"
          dropdownMode="select"
          className="text-center"
          maxDate={ this.props.maxDateEnd }
          minDate={ this.props.minDateEnd }
          showYearDropdown = { this.props.showYearDropdown }
          showMonthYearPicker = { this.props.showMonthYearPicker }
        />
        <InputGroupAddon type="append">
          <InputGroupText>
            <i className="material-icons">&#xE916;</i>
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}

export default RangeDatePicker;
