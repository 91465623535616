'use strict';

//Main libs
import React from "react";
import {Route, Redirect} from "react-router-dom";
import gql from "graphql-tag";
import _ from "underscore";

// Layout Types
import {IconSidebar} from "./layouts";

// Route Views
import Analytics from "./views/Analytics";

import Login from "./views/Login";
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import TransactionHistory from "./views/TransactionHistory";
import Supporters from "./views/Supporters";
import CreateSupporter from "./views/CreateSupporter";
import CleanSupporters from "./views/CleanSupporters/index.js";
import Supporter from "./views/supporterProfile";
import Prospect from "./views/ProspectProfile";
import Messages from "./views/Messages";
import Inbox from "./views/Inbox";
import BlastList from "./views/BlastList";
import BlastDetails from "./views/BlastDetails";
import CreateUser from './views/CreateUser';
import Campaigns from './views/Campaigns';
import MergeSupporter from "./views/MergeSupporter/index.js";
import CreateCampaign from "./views/CreateCampaign";
import CampaignDetails from "./views/CampaignDetails/CampaignDetails.js";
import BankRuns from "./views/BankRuns";
import BankRunDetails from "./views/BankRunDetails";
import Exports from "./views/Exports";

import Users from "./views/Users";
import UserDetails from "./views/UserDetails";

const BlankIconSidebarLayout = ({children}) => (<IconSidebar noNavbar="noNavbar" noFooter="noFooter">
  {children}
</IconSidebar>);

// Local state queries
const CURRENT_USER = gql `
  query CurrentUser {
    currentUser @client
  }
`;

/*
  Define protected route that checks authorization before rendering private components.
  Redirects to login if user not present. Sets state before doing so, such that
  Login can redirect the user back to their intended destination if authentication succeeds
*/
export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => (<Route {...rest} render={(props) => {
    const {client, authorization} = rest;
    // TODO -- there must be some way of getting this from the cache instead???
    const userInStorage = localStorage.getItem('user');
    const user = !!userInStorage && !!userInStorage.length && JSON.parse(userInStorage) || null;
    const isAuthenticated = !!user && !!user.token;

    const redirectPath = !!isAuthenticated && '/' || '/login';

    if (hasRightsToAccess({user, authorization})) {
      return (<Component {...props}/>);
    }

    return (<Redirect to={{
        pathname: redirectPath,
        state: {
          from: props.location
        }
      }}/>);

  }}/>);

export const hasRightsToAccess = ({user, authorization}) => {
  if (!user) {
    return false;
  }

  if (authorization.service === "app") {
    return true;
  }
  if (authorization.service === "gateway") {
    return !!_.intersection([user.gatewayRole], authorization.roles).length;
  }

  const userRolesForService = user.roles[authorization.service];
  if (!userRolesForService) {
    return false;
  }
  return !!_.intersection(userRolesForService, authorization.roles).length;

}

//Here are the routes themselves. Differentiate between private and public
export const privateRoutes = [
  {
    path: "/",
    exact: true,
    component: Analytics,
    authorization: {
      service: "app"
    }
  },
  // SUPPORTERS
  {
    path: "/supporter",
    exact: true,
    component: Supporters,
    title: "Supporters",
    authorization: {
      service: "supporter",
      roles: ["basic", "admin", "super-admin"]
    }
  }, {
    path: "/supporter/create",
    exact: true,
    component: CreateSupporter,
    title: "Create Supporter",
    authorization: {
      service: "supporter",
      roles: ["admin", "super-admin"]
    }
  }, {
    path: "/supporter/clean",
    exact: true,
    component: CleanSupporters,
    title: "Clean Supporters",
    authorization: {
      service: "supporter",
      roles: ["admin", "super-admin"]
    }
  },{
    path: "/supporter/merge",
    exact: true,
    component: MergeSupporter,
    title: "Merge Supporters",
    authorization: {
      service: "supporter",
      roles: ["admin", "super-admin"]
    }
  }, {
    path: "/supporter/household",
    exact: true,
    component: MergeSupporter,
    title: "Merge Supporters",
    authorization: {
      service: "supporter",
      roles: ["admin", "super-admin"]
    }
  }, {
    path: "/supporter/:id",
    exact: true,
    component: Supporter,
    authorization: {
      service: "supporter",
      roles: ["basic", "admin", "super-admin"]
    }
  },
  // END SUPPORTERS
  //
  // PROSPECTS
  {
    path: "/prospect/:id",
    exact: true,
    component: Prospect,
    authorization: {
      service: "prospect",
      roles: ["basic", "admin", "super-admin"]
    }
  },
  // END PROSPECTS
  //
  // SMS
  {
    path: "/sms/inbox",
    exact: true,
    component: Inbox,
    title: "SMS Inbox",
    authorization: {
      service: "sms",
      roles: ["basic", "admin", "super-admin"]
    }
  }, {
    path: "/sms/blast/create",
    exact: true,
    component: Messages,
    title: "Create Blast",
    authorization: {
      service: "sms",
      roles: ["admin", "super-admin"]
    }
  }, {
    path: "/sms/blast",
    exact: true,
    component: BlastList,
    title: "SMS Blasts",
    authorization: {
      service: "sms",
      roles: ["admin", "super-admin"]
    }

  }, {
    path: "/sms/blast/:id",
    exact: true,
    component: BlastDetails,
    authorization: {
      service: "sms",
      roles: ["admin", "super-admin"]
    }
  },
  //END SMS
  //
  //ADMIN
  {
    path: '/admin/users',
    exact: true,
    component: Users,
    authorization: {
      service: "gateway",
      roles: ["admin", "super-admin"]
    }
  }, {
    path: '/admin/users/create',
    exact: true,
    component: CreateUser,
    authorization: {
      service: "gateway",
      roles: ["super-admin"]
    }
  }, {
    path: '/admin/users/:id',
    exact: true,
    component: UserDetails,
    authorization: {
      service: "gateway",
      roles: ["basic", "admin", "super-admin"]
    }
  },

  //CAMPAIGNS
  {
    path: '/campaign',
    exact: true,
    component: Campaigns,
    authorization: {
      service: "campaign",
      roles: ["caller", "basic", "admin", "super-admin"]
    }
  }, {
    path: '/campaign/create',
    exact: true,
    component: CreateCampaign,
    authorization: {
      service: "campaign",
      roles: ["super-admin"]
    }
  }, {
    path: '/campaign/:id',
    exact: true,
    component: CampaignDetails,
    authorization: {
      service: "campaign",
      roles: ["caller", "basic", "admin", "super-admin"]
    }
  },

  //BANK RUNS
  {
    path: '/bankrun',
    exact: true,
    component: BankRuns,
    authorization: {
      service: "payment",
      roles: ["super-admin"]
    }
  }, {
    path: '/bankrun/:id',
    exact: true,
    component: BankRunDetails,
    authorization: {
      service: "payment",
      roles: ["super-admin"]
    }
  },

  //REPORT
  {
    path: '/transactions',
    exact: true,
    component: Exports,
    authorization: {
      service: "payment",
      roles: ["super-admin"]
    }
  }
  // {
  //   path: '/admin/services',
  //   exact: true,
  //   component: Services
  // }

];

export const publicRoutes = [
  {
    path: "/login",
    component: Login,
    title: "Login"
  }, {
    path: "/forgot-password",
    component: ForgotPassword,
    title: "Forgot Password"
  }, {
    path: "/change-password",
    component: ChangePassword,
    title: "Change Password"
  }
];
