import React, { useState, Fragment } from 'react';
import _ from 'underscore';

import {
  Row,
  Col,
  FormFeedback,
  FormCheckbox,
  Collapse
} from "shards-react";



const FieldRow = ({keyName, operators, selector, setSelector, fields, triggerSubmit }) => {
  const [selectorInner, setSelectorInner] = useState([])
  //active is true if the field is currently used in selector. Second condition checks for nested keys
  const [ active      , toggleActive ] = useState( _.has( selector, keyName ) || !!_.keys( selector ).filter( k => k.includes(keyName + '.') ).length  );


  const activeField = (key) => {
      const fieldInSelector = _.findWhere(selectorInner, {key});
      return !!fieldInSelector;
  }

  const toggleField = (field) => {
    let actualState = activeField(field.key);
    console.log({actualState})
    if(!!actualState) {
       setSelectorInner(_.filter(selectorInner, (fieldSelection) => fieldSelection.key !== field.key))
       return setSelector(_.filter(selector, (fieldSelection) => fieldSelection.key !== field.key));
    }
    setSelectorInner([...selectorInner, field])
    return (setSelector( [...selector, field]));
  }

  return (
    <Row>
      <Col>
        <FormCheckbox
          style={{fontWeight: 'bold'}}
          checked={ active }
          className={ !active && 'mb-0' || 'bg-warning'  }
          onChange={ (e) => {
            //if inactive (i.e. we are activating), reset the selector to use currentValue if any
            if ( !active && !!selectorInner.length ) {
              setSelector([...selector, ...selectorInner]);
            } else if(!!active && !!selectorInner.length){
              setSelector(_.filter(selector, (selectorField) => !_.includes(selectorInner, selectorField)))
            }

            //set internal state for activity
            toggleActive( !active );
          }}>
           {keyName}
        </FormCheckbox>

        <Collapse open={active} timeout={0}>

          <div style={{'paddingLeft': '1rem'}}>
          {fields.map((field, i) => {
              return <span key={i + Math.random()}>
                <FormCheckbox
                  inline
                  checked={activeField(field.key)}
                  onChange={() => toggleField(field)}
                >{field.header}</FormCheckbox>
              </span>
          })}

          </div>
        </Collapse>
      </Col>

    </Row>
  );
}

export default FieldRow;
