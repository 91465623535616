import React from "react";

import { Card, CardHeader} from "shards-react";
import EditSupporterDetails from "../views/EditSupporterDetails";
import ShowSupporterDetails from "../views/ShowSupporterDetails";

class SupporterDetails extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      view: "show",
      message : {
        firstName  : '',
        lastName   : '',
        city       : '',
        province   : '',
        postalCode : '',
        emails     : [],
        language   : '',
        ridingName : '',
        ridingCode : ''
      },
      supporterData : {
        ...this.props.SupporterData
      },
      emails: this.props.SupporterData.emails,
      contactPreferences: this.props.SupporterData.contactPreferences
    }
  }


  //allows the edit child component to change values of
  //the different supporter fields and pass error messages
  //when needed
  onChange = ({input = null, field, error = ""}) => {
    //sets the value of the input
    this.setState({[`supporterData${[field]}`] : input});

    //stores the error messages to display
    const message = this.state.message;
    message[field] = error;
    this.setState({message})

  }


  // switched the view from show to edit and vice versa
  changeView = (view) => {
    this.setState({view: view });
  }


  render () {
    const componentToDisplay = (this.state.view === "show") ?
      <ShowSupporterDetails
        supporterData={this.state.supporterData}
        changeView ={this.changeView}
      /> :

      <EditSupporterDetails
          supporterData={this.state.supporterData}
          changeView={this.changeView}
          allowRedirect={true}
          refetch={this.props.refetch}
        />

  return (
  <Card small className="user-details mb-4">
    <CardHeader className="border-bottom">
      <h6 className='m-0'>Supporter Information</h6>
      <div className="block-handle" />
    </CardHeader>
   {componentToDisplay}
  </Card>
)}
};

export default SupporterDetails;
