'use strict';
import React from 'react';
import {
  FormRadio,
  Row,
  Col
} from "shards-react";

import _ from "underscore";


const QBBoolean = ({field, keyName, operators, operator, selector, setSelector, currentValue, setCurrentValue, isMinVal, isMaxVal, removeFromSelector }) => {
  return (
  <div className='ml-md-2'>
    <span>&nbsp;&nbsp;</span>
    <FormRadio
      inline
      checked={ !!currentValue }
      className='mr-2'
      name={`${keyName}-yes`}
      id={`${keyName}-yes`}
      onChange={ (e) => {
        const addToSelector = operators[operator].handler.apply(this, [keyName, true]);
        setCurrentValue(addToSelector[keyName])
        setSelector({ ...selector, ...addToSelector});

      }}>
      Yes
    </FormRadio>
    <FormRadio
      inline
      checked={ currentValue === false }
      name={`${keyName}-no`}
      id={`${keyName}-yes`}
      onChange={ (e) => {
        const addToSelector = operators[operator].handler.apply(this, [keyName, false]);
        setCurrentValue(addToSelector[keyName])
        setSelector({ ...selector, ...addToSelector});
      }}>
      No
    </FormRadio>
  </div>
  )
}

export default QBBoolean;
