import { useRef } from "react";
import { toast } from 'react-toastify';


const useToast = () => {

    const waitMessage = useRef(null);

    const loadingToast = (message) => {

        waitMessage.current = toast.info(message, {autoClose : false, closeButton: true, closeOnClick: true, draggable: false});

    };

    const completeToast = (message) => {
  
        if(!!waitMessage.current) {
            toast.update(waitMessage.current, {
                render : message,
                type : toast.TYPE.SUCCESS,
                autoClose: 5000,
                className: 'rotateY animated'
            });
            waitMessage.current = "";
        } else {
            toast.success(message);
        }
    };


    const errorToast = (message) => {

        if(!!waitMessage.current) {
            toast.update(waitMessage.current, {
                render : message,
                type : toast.TYPE.ERROR,
                autoClose: 5000,
                className: 'rotateY animated'
            });
            waitMessage.current = "";
        } else {
            toast.error(message);
           
        }
    };
    
    return {
        loadingToast,
        completeToast,
        errorToast
    }

}

export default useToast;