import React from "react";
import {useState} from "react";
import SupporterContactTags from "../SupporterContactTags";
import {Modal} from "react-bootstrap";
import {Button} from "shards-react";
import { clone } from 'underscore';
import CommentAction from "../CommentAction";
import useToast from "../../hooks/use-toast";

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const UPDATE_SUPPORTER = gql`
mutation updateOneSupporter( $selector: Selector, $modifier: Modifier, $opts: GenericObject ){
  updateOneSupporter(
      selector: $selector,
      modifier: $modifier,
      opts: $opts
  ) {
   ok
   n
   nModified
  }
}
`



const ContactPreferenceTags = ({supporter}) => {

  const { completeToast, errorToast} = useToast();
  
  const [originalTags, setOriginalTags] = useState(clone({...supporter.contactPreferences}));
  const [tags, setTags] = useState(clone({...supporter.contactPreferences}));
  const [modifier, setModifier] = useState({});
  

  //modal Edit
  const [showEdit, setShowEdit] = useState(false);

  const handleCloseEdit = (e) => {
    setShowEdit(false);
  };

  const handleShowEdit = (e) => setShowEdit(true);

  //this is for the comment action below
  const [ showModal, setShowModal ] = useState(false);
  

  //GQL Mutation to perform the update
  const [updateSupporter] = useMutation(UPDATE_SUPPORTER, {
    onCompleted : () => {
      completeToast("Contact Preferences updated");
      handleCloseEdit();
    },
    onError : (e) => {
      console.log(e);
      errorToast("Problem updating the contact preferences");
    },
    refetchQueries : ["getSupporter", "getSupporters"]
  });


  const onToggle = (field) => {
    const allPrefs  = tags;
    //switch 'em
    allPrefs[field] = !allPrefs[field];
    setTags(allPrefs);
    
    const updatedModifier = {... modifier};
    updatedModifier["contactPreferences." + field] = allPrefs[field];
    setModifier(updatedModifier);
  }
  

  return <div>

    <p
      className={"text-right border-top"}
      style={{cursor : "pointer", color : "#2A82E6"}}
      onClick={handleShowEdit}>Edit
    </p>
    <SupporterContactTags 
      tags={originalTags}
    />
    {/* This is the edit modal */}
    <Modal
      size="lg"
      backdrop={false}
      keyboard={false}
      show={showEdit}
      onHide={handleCloseEdit}
    >
      <Modal.Header
        closeButton
        >
        <Modal.Title>Edit Contact Preferences</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SupporterContactTags 
          edit = {true}
          tags = {tags}
          onToggle = {onToggle}
        />

        <Button
          onClick={() => setShowModal(true)}>
          Update
        </Button>
        <CommentAction
          action={"Update Contact Preferences"}
          confirmation={false}
          mutation={updateSupporter}
          showModal={showModal}
          setShowModal={setShowModal}
          opts={{
            variables  : {
              selector : {
                _id : supporter._id
              },
              modifier:  {
                $set : modifier
              },
              opts : {}
            },
            refetchQueries : ['getSupporter', 'getActions']
          }}
        />
      </Modal.Body>
    </Modal>
  </div>

}

export default ContactPreferenceTags;