import React, {
  Fragment, useState
}
from 'react';
import gql from 'graphql-tag';
import validator from 'validator';
import {
  toast
}
from 'react-toastify';
import {
  useMutation
}
from '@apollo/react-hooks';

import Loading from './Loading.js';

import {
  Button,
  InputGroup,
  InputGroupAddon,
  FormInput,
  FormSelect,
  FormFeedback,
  Row
}
from 'shards-react';


const SEND_CONFIRMATION_EMAIL = gql `
  mutation sendConfirmationEmail($email: String!, $firstName: String!, $lastName: String, $language: String, $userName: String, $gifts: [ GenericObject ]!) {
    sendConfirmationEmail(email: $email, firstName: $firstName, lastName: $lastName, language: $language, userName: $userName, gifts: $gifts ) {
  		message
      statusCode
  	}
  }
`;

const UPDATE_SUPPORTER = gql`
mutation updateOneSupporter( $selector: Selector, $modifier: Modifier){
  updateOneSupporter(
      selector: $selector,
      modifier: $modifier,
  ) {
   ok
   n
   nModified
  }
}
`;

const ConfirmationEmail = ({supporter, 
  address, firstName, gifts, setGifts, handleClose
}) => {


  const [supporterEmail, setSupporterEmail] = useState(address);
  const [language, setLanguage] = useState(supporter.language || "English");
  const [invalidEmail, setInvalidEmail] = useState();
  const [sending, setSending] = useState(false);

  const opts = {
    onError : () => {
      setSending(false);
      toast.error("Email was not sent")
    },
    onCompleted : () => {
      setSending(false);
    }
  };

  const [sendConfirmationEmail, {
    data, loading, error
  }] = useMutation(SEND_CONFIRMATION_EMAIL);
  const [updateSupporter] = useMutation(UPDATE_SUPPORTER);

  if (!!loading) {
    return <Loading /> ;
  }

  //dismiss existing alerts
  toast.dismiss();

  if (!!data && data.sendConfirmationEmail && data.sendConfirmationEmail[0] &&
    data.sendConfirmationEmail[0].statusCode === 200) {
    toast.success("Message sent!");
    if(!!handleClose) {
      handleClose();
    }
    return "";
   
  }

  if (!!error) {
    toast.error('Error sending confirmation email');
  }

  const saveEmail = () => {
    let newEmail = true;
    const existingEmails = !!supporter.emails && supporter.emails || [];
    if(!!existingEmails.length) {
      supporter.emails.forEach(email => {
        if(supporterEmail === email.address) {
          newEmail = false;
        }
      });
    }

    const emailsToSave = [...existingEmails,  {
      address : supporterEmail,
      verified : false,
      suppressed : false,
      isPrimary : !!supporter.emails.length && false || true

    } ];

    if(!!newEmail) {
      updateSupporter({
        variables : {
          selector : {
            _id : supporter._id
          },
          modifier : {
            $set : {
              emails : emailsToSave
            }
          }
        } 
      });
    }
  }
  return (
    <Fragment>
    
    {!!supporter.emails && !!supporter.emails.length > 0 && 
    <Fragment>
     <h6>Select from existing email</h6>
      <FormSelect
        onChange={(e) => setSupporterEmail(e.target.value)}>
        {supporter.emails.map(email => <option>{email.address}</option>)}
      </FormSelect>
      </Fragment>
      || ""
    }
    <h6>Confirm the language</h6>
    <FormSelect
      value={language}
      onChange={(e) => setLanguage(e.target.value)}>
      <option>English</option>
      <option>French</option>
    </FormSelect>
    <h6 className="mt-2">{!!supporter.emails && !!supporter.emails.length && "Or e" || "E"}nter a new email</h6>
    <InputGroup> 
    <FormInput placeholder =
    "email"
    value = {supporterEmail}
    onChange = {
      (e) => {
        const isValid = validator.isEmail(e.target.value);
        if (!isValid) {
          setInvalidEmail(true);
        }
        setSupporterEmail(e.target.value);
      }
    }
    /> <FormFeedback> {!!invalidEmail && "Invalid email"} </FormFeedback >
    <InputGroupAddon type = "append" >
    <Button 
      desactivated = {!!sending}
      onClick = {
      (e) => {
        e.preventDefault();
        const emailAddress = !!supporterEmail && supporterEmail || address;
        if (!emailAddress) {
          return toast.error("Cannot send to empty email address");
        }
        const conf = window.confirm(
          `Send confirmation email to ${supporterEmail}?`);
        if (!conf) return;
        
        saveEmail();

        sendConfirmationEmail({
          variables: {
            email: !!supporterEmail && supporterEmail || address,
            firstName,
            language,
            gifts
          }
        });
      }
    } > Send </Button> </InputGroupAddon> </InputGroup> </Fragment>
  );
}

export default ConfirmationEmail;
