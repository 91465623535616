import React, {useRef, useState, useEffect} from 'react';
import Tags from "./Tags.js";
import { useLazyQuery } from '@apollo/react-hooks';
import _ from "underscore";



// Demo "App" component that is using the Tagify React component (<Tags/>)
const SearchSelect = ({gqlOpts, gqlQuery, collectionName, keyName, defaultValue, setResult })  => {
  //get the name of the query from the query itself. Useful in many other contexts below, especially patching in new data
  const queryKey = gqlQuery.definitions[0].name.value;

  const [searchResult, setSearchResult] = useState([]);

  //need an extra local store so that callbacks have access to the most recent current value.
  const self = useRef({ searchResult });

  useEffect(() => {
    self.current.searchResult = searchResult;
  }, [searchResult]);

  const [getData, {data, error, loading}] = useLazyQuery(gqlQuery);
  
  const onInput = (e) => {
    const value = e.detail.value;
    const newOpts = _.clone(gqlOpts);
    newOpts.variables.selector = {[keyName] : { $regex: `^${value}` }};
    getData(newOpts);
  };

  
  const [settings, setSettings] = useState({
    mode             : "select",
    whitelist        : !!defaultValue && !_.isEmpty(defaultValue) && [defaultValue[keyName]] || [],
    enforceWhitelist : true,
    callbacks        : {
      add: (tag) => {
        const value = tag.detail.data.value;
        if ( !!value ) {
          setResult( _.findWhere(self.current.searchResult, {[keyName] : value}));
        }
        
      },
      remove: (tag) => {

        setResult({});

      },
      input: onInput
    }
  });

  if(!!data && !!data[queryKey] && !!data[queryKey].count && settings.whitelist.length !== data[queryKey][collectionName].length) {
    setSettings( {...settings, whitelist : _.pluck(data[queryKey][collectionName], [keyName])});
    
    setSearchResult(data[queryKey][collectionName]);
  }

  return (
      <Tags
      autofocus={true}
      mode='textarea'
      className='form-control'
      name='search'
      settings={settings}
      placeholder={'Type to search'}
      value={!!defaultValue && !_.isEmpty(defaultValue) && [defaultValue[keyName]] || []}
      />
  )

}

export default SearchSelect;
