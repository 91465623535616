import React, { Fragment } from "react";
import validator from "validator";
import _ from 'underscore';

import {
  Container,
  Row,
  Col,
  Button,
  FormInput,
  FormCheckbox,
  FormRadio,
  FormFeedback
} from "shards-react";


const EmailForm = ({emails = [], setEmails, onChangeEmail, messageEmails = [], addToModifier = null, mergeView =false}) => {
  
  if(!emails.length) {
    emails.push({
      address : "",
      suppressed : false,
      verified : false,
      isPrimary: true
    })
  }
  const removeSpaces = (input) => {
    return input.split("").map( str => str.replace(/\s/g, '')).join('');
  };
  const addRow = () => {
    const actualEmails = emails;
    const newEmailField = {
      address : "",
      suppressed : false,
      verified : false,
      isPrimary: false,
      optInEn : false,
      optInFr : false,
      okNewsletter : true,
      okFund : true
    }
    actualEmails.push(newEmailField);

    if(!!addToModifier) {
      addToModifier('emails', actualEmails);
    }
    setEmails(actualEmails);
  };

  const removeRow = (i) => {
    if (emails.length < 2) {
      return;
    }
    const filteredEmails = emails.filter((email, index) => {
      return i !== index
    });

    if(!!addToModifier) {
      addToModifier('emails', filteredEmails);
    }
    setEmails(filteredEmails);
  };

  const buttonToDisplay = (i) => {
    if(i + 1 < emails.length) {
      return <a
      style={{cursor : "pointer", color : "red"}}
      onClick={ (e) => removeRow(i) }
      >Delete</a>
    }
    return  <a
    style={{cursor : "pointer", color : "#2A82E6"}}
    onClick={() => addRow() }
    >Add New</a>
  }

  const setPrimary = (i) => {
    const actualEmails = emails;
    for(const [index, email] in emails ) {
      if(index == i) {
        actualEmails[index].isPrimary = true;
      } else {
        actualEmails[index].isPrimary = false;
      }
    }

    if(!!addToModifier) {
      addToModifier('emails', actualEmails);
    }

    setEmails(actualEmails);
  };

  const setBooleans = ({field, i}) => {
    const actualEmails = emails;
    actualEmails[i][field] = !emails[i][field];

    if(!!addToModifier) {
      addToModifier('emails', actualEmails);
    }
    setEmails(actualEmails);
  }

  //if we're in merge view, only show the primary email.
  if ( !!mergeView && !!emails.length ) {
    emails = [ _.findWhere( emails, { isPrimary: true } ) || emails[0] ];
  }

  return (
    <div>
      <Row>
        <Col>
          <span><strong>Emails</strong></span>
        </Col>
      </Row>
      {emails.map((email, i) => {
      return (
        <Row key={`email${i}`} className="mb-3">
          <Col sm="5">
            <FormInput
              id={`email${i}`}
              type="text"
              value={email.address}
              placeholder="Email"
              disabled={!!mergeView}
              onChange={e => {
                const input = removeSpaces(e.target.value);
                const isValid = validator.isEmail(input);
                if (!!isValid || input.length === 0) {
                  onChangeEmail({
                    input,
                    field : "address",
                    error : "",
                    index : i
                  })
                } else {
                  onChangeEmail({
                    input,
                    field : "address",
                    error : "Email address is incorrect",
                    index: i
                  })
                }
              }}
              invalid={!!messageEmails[i]}
            />
            <FormFeedback>{messageEmails[i]}</FormFeedback>
          </Col>
          {!mergeView ?
          <Fragment>
            <Col  className="d-flex" sm="7">
              <FormCheckbox
                inline
                checked={email.suppressed}
                // className="ml-auto my-auto"
                id="isSuppressed"
                onChange={ () => setBooleans({field : "suppressed", i}) }
              >Suppressed</FormCheckbox>
              <FormCheckbox
                inline
                checked={email.verified}
                // className="ml-auto my-auto"
                id="isVerified"
                onChange={ () => setBooleans({field : "verified", i}) }
              >Verified</FormCheckbox>
              <FormRadio
                inline
                checked={email.isPrimary}
                // className="ml-auto my-auto"
                id="isPrimary"
                onChange={ () => setPrimary(i) }
              >Primary</FormRadio>
              {!mergeView ?
                buttonToDisplay(i)
              : ""}
            </Col>
            <Row className="mx-3 mt-2">
            <FormCheckbox
                inline
                checked={email.optInFr}
                // className="ml-auto my-auto"
                id="isOptInFr"
                onChange={ () => setBooleans({field : "optInFr", i}) }
              >opt-in French</FormCheckbox>
              <FormCheckbox
                inline
                checked={email.optInEn}
                // className="ml-auto my-auto"
                id="isOptInEn"
                onChange={ () => setBooleans({field : "optInEn", i}) }
              >opt-in English</FormCheckbox>
              <FormCheckbox
                inline
                checked={email.okNewsletter}
                // className="ml-auto my-auto"
                id="isokNewsletter"
                onChange={ () => setBooleans({field : "okNewsletter", i}) }
              >Newsletter</FormCheckbox>
              <FormCheckbox
                inline
                checked={email.okFund}
                // className="ml-auto my-auto"
                id="isokFund"
                onChange={ () => setBooleans({field : "okFund", i}) }
              >okFund</FormCheckbox>
            </Row>


          </Fragment>
          : ""}

        </Row>
      )
    })}
  </div>
  )
}

export default EmailForm;
