import React, { useState, Fragment } from "react";
import CommentAction from "./CommentAction";
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import gql from 'graphql-tag';

export const REMOVE_CARD = gql`
  mutation removeCard($supporterId: String, $cardId: String, $customerId: String $opts: GenericObject) {
    removeCard(supporterId : $supporterId, cardId: $cardId, customerId: $customerId, opts: $opts) {
      id
      brand
    }
  }
`;

const RemoveCard = ({ supporterId, cardId, customerId }) => {

  const [showModal, setShowModal] = useState(false)
  const removeOpts = {
    variables : {
      supporterId,
      customerId,
      cardId,
      opts: {}
  },
    refetchQueries : ['getSupporter'],
    onCompleted :  () => toast.success("card deleted successfully"),
    onError : (e) => {
      for (const error of e.graphQLErrors) {
        //check if the error is within GraphQL or sent back from the server
        // in order to retirce the error message at the right place
        const errorMessage = error.extensions.response ?
        error.extensions.response.body.error.message :
        error.message;
        toast.error(errorMessage);
      }
    }
  };

  const [removeCard, { error }] = useMutation(REMOVE_CARD, removeOpts );
  if(!!error) {
    console.log({error})
  }

  return(
    <Fragment>
      <a
        className="mr-2"
        style={{color : "red", cursor: "pointer"}}
        onClick={()=> {
          setShowModal(true)
        }}
        >
        Delete
      </a>
      <CommentAction
        action={"delete card"}
        confirmation={true}
        mutation={removeCard}
        showModal={showModal}
        setShowModal={setShowModal}
        opts={removeOpts} />
      </Fragment>
  )
}

export default RemoveCard;
