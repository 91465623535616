'use strict';

import React from 'react';

const SetPageMetas = ({ title }) => {
  if ( !!title ) {
    document.title = title
  }
}

export default SetPageMetas;
