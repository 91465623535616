import React, { Component } from 'react';
import { Form, Col, Row, Popover, Button, OverlayTrigger } from 'react-bootstrap';
import MessageBox from './MessageBox';
import TemplateBox from "./TemplateBox";
import templateEngine from '../templateEngine';

class MessageEditor extends Component {
  

	render() {

		const popover = (
			<Popover id="popover-basic">
				<Popover.Title as="h3">Instructions</Popover.Title>
				<Popover.Content>
					<strong>Edit Message :</strong>
					<p>{`This field can use tags like <%firstName%> or <%city%> to repalce it by support's values in the text. The field on the right will show an example of the outcome of a text.`}</p>
          <strong>Link</strong>
					<p>{`Enter any url link you want to include in you initial message. Don't forget to add the tag <%link%> in the message where you want your link to appear.`}</p>
					<p>{`You can also add tags to pass for tracking puposes like : https://friends.ca/campaigns/strengthen-the-cbc-cbcfightfor50/?supporter_id=<%_id%>`}</p>
				</Popover.Content>
			</Popover>
		);

		return (
			<div>
				<Form className="p-3">
					<Form.Group as={Row} controlId="fromNumber">
						<Form.Label column sm={2}>
							From phone number
						</Form.Label>
						<Col sm={4}>
							<Form.Control
								type="text"
								placeholder=" 1 416 123 1234"
								value={this.props.from}
								onChange={e => this.props.handleChange(e)}
							/>
						</Col>
						<Col>
							<OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
								<Button variant="outline-secondary">Help ?</Button>
							</OverlayTrigger>
						</Col>
					</Form.Group>
          
					<Row>
						<MessageBox 
						  title={"Edit message"}
							message={this.props.message}
							handleChangeMessage={this.props.handleChangeMessage}
							
						/>
						<TemplateBox
							messageTemplate={this.props.messageTemplate}
						/>
					</Row>

					<Row>
						<Col  md="6" className="form-group" disabled>
							<Form.Label >
								Link
						</Form.Label>

							<Form.Control
								type="text"
								placeholder="http://url.ca"
								value={this.props.link}
								onChange={this.props.handleChangeLink}
							/>
						</Col>
						<Col  md="6" className="form-group" disabled>
							<Form.Label>Link rendered</Form.Label>
							<Form.Control
								type="text"
								value={templateEngine(this.props.link)}
								disabled={true}
							/>
						</Col>
					</Row>

					<Row>
						<MessageBox 
						  title={"Automatic positive answer"}
							message={this.props.positiveResponse}
							handleChangeMessage={this.props.setpositiveResponse}
							
						/>
						<MessageBox
						  title={"Automatic negative answer"}
							message={this.props.negativeResponse}
							handleChangeMessage={this.props.setnegativeResponse}
						/>
					</Row>
					<Row>
					<Col  md="6" className="form-group" disabled>
							<Form.Label >
								Positive link
						</Form.Label>

							<Form.Control
								type="text"
								placeholder="http://url.ca"
								value={this.props.positiveLink}
								onChange={e => this.props.setPositiveLink(e.target.value)}
							/>
						</Col>
					</Row>
 

				</Form>
			</div>
		);
	}
}

export default MessageEditor;
