'use strict';
import _ from 'underscore';
import moment from "moment";


//return array pair with minval first and max val second
export const computeMinMax = (currentValue, newValue, isMinVal, isMaxVal) => {

  //if there is nothing set yet, simply return what we have
  if ( !currentValue ) {
    if( !!isMinVal ) {
      return [newValue, null];
    } else if ( !!isMaxVal ) {
      return [ null, newValue ];
    }
  }

  //if there is an existing value, make sure we insert the new value into the correct position
  let returnArray = [ currentValue.$gte || null, currentValue.$lte || null ];
  if ( !!isMinVal ) {
    returnArray[0] = newValue;
  } else if ( !!isMaxVal ) {
    returnArray[1] = newValue;
  }

  return returnArray;
}

/*
  Takes a current value and returns the operator being used
*/
export const detectOperator = ( obj ) => {

  //equals is our default because it doesn't prepend anything
  if ( !obj || _.isEmpty(obj) || _.isObject( obj ) === false ) {
    return 'equals';
  }

  const entries = Object.entries(obj);

  //currently between is the only operator with two keys { $lgt: foo, $gte: bar }
  if ( entries.length === 2 ) {
    if(moment(entries[0][1]).isSame(entries[1][1], 'day')) {
      return "date_on";
    }
    return 'between';
  }


  //check the inner key
  switch (entries[0][0]) {
    case '$ne':
      return 'not_equals';
      break;
    case '$regex':
      if ( entries[0][1].indexOf("^") == 0 ) {
        return 'starts_with';
      }

      if ( entries[0][1].indexOf("$") == entries[0][1].length - 1 ) {
        return 'ends_with';
      }
      break;
    case '$in':
      return 'any';
      break;
    case '$nin':
      return 'none';
      break;
    case '$gte':
      return 'greater_or_equal';
      break;
    case '$lte':
      return 'less_or_equal';
      break;

    default:
      return 'equals';

  }

}
