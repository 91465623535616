import React, {useState} from "react";
import Loading from "./Loading";
import SubscriptionForm from "./SubscriptionForm";
import {Mutation} from "react-apollo";
import { toast } from 'react-toastify';

import gql from 'graphql-tag';

export const SUBSCRIBE_PLAN = gql`
  mutation subscribeToPlan($supporterId: String, $amount: Int, $bankAccountId: String, $opts: GenericObject) {
    subscribeToPlan(supporterId: $supporterId, amount: $amount, bankAccountId: $bankAccountId, opts: $opts) {
      id
      status
      plan {
        id
        nickname
        amount
      }
    }
  }
`;

const SubscribeToPlan = ({supporterId, bankAccounts, existingCreditCard, campaign, handleCloseAddSubscription, setGifts, handleShowEmailConfirmation, setPendingAmount, wizardRegisterGift}) => {
  
  const opts = {}
  if(!!campaign) {
    opts.campaign = campaign;
  }

 
  return (
    <Mutation
      mutation={SUBSCRIBE_PLAN}
      onError={e => {

        for (const error of e.graphQLErrors) {
          //check if the error is within GraphQL or sent back from the server
          // in order to retirce the error message at the right place
          const errorMessage = error.extensions.response ?
          error.extensions.response.body.error.message :
          error.message;
          toast.error(errorMessage);
        }
        }}
      onCompleted={({subscribeToPlan}) => {
        // toast.dismiss();
       
        if ( !!wizardRegisterGift ) {
          wizardRegisterGift({ type: 'monthly', amount: subscribeToPlan.plan.amount })
        }
        handleCloseAddSubscription();

        if(!!handleShowEmailConfirmation) {
          setGifts([{ type: 'monthly', amount: subscribeToPlan.plan.amount }]);
          handleShowEmailConfirmation();
        }
        toast.success(`subscribed to ${subscribeToPlan.plan.nickname}`, {delay : 1000});
      }}
      refetchQueries = {['getSupporter', 'getSupporters', 'getCampaign', 'getActions']}>
      {(subscribe, {data, loading, error}) => {

        if ( !!loading ) {
            return <Loading />
        }

        return (
          <SubscriptionForm
            mutation={subscribe}
            campaign={campaign}
            setGifts={setGifts}
            supporterId={supporterId}
            bankAccounts={bankAccounts}
            existingCreditCard={existingCreditCard}
            setPendingAmount={setPendingAmount}
          />
        )
      }}
    </Mutation>

  )
}

export default SubscribeToPlan;
