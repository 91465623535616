import React from 'react';
import { ButtonGroup, Button } from 'shards-react';

const CountTotalToggle = ({ activeItem, clickHandler }) => (
  <ButtonGroup className="">
    <Button theme="white" active={activeItem == 'count'} onClick={clickHandler}>Count</Button>
    <Button theme="white" active={activeItem == 'total'} onClick={clickHandler}>Total</Button>
  </ButtonGroup>
);

export default CountTotalToggle;
