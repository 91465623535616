import React from "react";
import {Query, Mutation} from "react-apollo";
import {
  Button
} from "shards-react";
import { toast } from 'react-toastify';
import Loading from "./Loading";

import gql from 'graphql-tag';

export const SMS_UNSUB = gql`
  mutation SMSUnsub($selector: Selector!, $messageId: ID!, $fromNumber: String!, $toNumber: String! ){
    SMSUnsub(selector: $selector, fromNumber: $fromNumber, toNumber: $toNumber, messageId: $messageId){
      n
      nModified
      ok
    }
  }
`;

const SMSUnsubscribeButton = ({ selector, messageId, toNumber, fromNumber, unsubbed }) => {
  return (
    <Mutation
      mutation={SMS_UNSUB}
      onCompleted={({unsub}) => {
        toast.success(`Supporter unsubscribed`);
      }}
      onError={e => {
   
        for (const error of e.graphQLErrors) {
          //check if the error is within GraphQL or sent back from the server
          // in order to retirce the error message at the right place
          const errorMessage = error.extensions.response ?
          error.extensions.response.body.error.message :
          error.message;
          toast.error(errorMessage);
        }
      }}
      >
      {(unsub, {data, loading, error}) => {
        {/* if( !!loading ) {
          return <Loading />
        } */}
        if( !!error ) {
          console.log(error);
          toast.error(error.message);
        }

        return <Button
          disabled={unsubbed}
          theme='danger'
          onClick={(e) => {
            const conf = window.confirm("Are you sure?");
            if( !conf ) {
              return toast.info("Unsub cancelled");
            }

            unsub({
              variables: {
                selector,
                fromNumber,
                toNumber,
                messageId
              }
            });
          }}
        >UNSUB</Button>
      }}
    </Mutation>
  )
}

export default SMSUnsubscribeButton;
