const getProvinceData = [
{ value: "AB" , label: "Alberta"},
{ value: "BC" , label: 'British Columbia'},
{ value: "PE" , label: 'Prince Edward Island'},
{ value: "MB" , label: 'Manitoba'},
{ value: "NB" , label: 'New Brunswick'},
{ value: "NS" , label: 'Nova Scotia'},
{ value: "ON" , label: 'Ontario'},
{ value: "QC" , label: 'Quebec'},
{ value: "SK" , label: 'Saskatchewan'},
{ value: "NL" , label: 'Newfoundland and Labrador'},
{ value: "NU" , label: 'Nunavut'},
{ value: "NT" , label: 'Northwest Territories'},
{ value: "YT" , label: 'Yukon'}
];


export default() => getProvinceData;
