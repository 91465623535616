import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  FormCheckbox,
  Badge
} from "shards-react";
import _ from 'underscore';
import {Link} from "react-router-dom";
import gql from 'graphql-tag';
import CollectionTable from '../friendsComponents/CollectionTable';
import { Fade } from "react-bootstrap";
import SetPageMetas from '../utils/SetPageMetas';
import BottomActionBar from "../friendsComponents/BottomActionBar";
import UploadFile from "../friendsComponents/UploadFile";

import PageTitle from "../components/common/PageTitle";

// import InlineTitleButton from "../friendsComponents/InlineTitleButton";
import { toast } from 'react-toastify';

import getSupporterColumns from "../data/supporter-columns";

export const gqlQuery = gql`
  query getSupporters($selector: Selector, $projector: GenericObject, $limit: Int, $skip: Int, $sort: GenericObject, $cacheBuster: Int) {
    getSupporters(selector: $selector, projector: $projector, limit: $limit, skip: $skip, sort: $sort, cacheBuster: $cacheBuster ) {
      count
      supporters {
        _id
        firstName
        lastName
        mobilePhone
        city
        PIN
        province
        language
        PACStatus
        customerId
        isDeceased
      }
    }
  }
`;



const gqlQueryOpts = {
  variables: {
    selector : { merged: false },
    projector : {
      _id         : 1,
      firstName   : 1,
      lastName    : 1,
      PIN         : 1,
      city        : 1,
      province    : 1,
      ridingName  : 1,
      mobilePhone : 1,
      PACStatus   : 1,
      isDeceased  : 1
    },
    limit    : 20,//(20 > query.limit) ? parseInt(query.limit) : 20,
    skip     : 0,
    sort     : { createdDate: -1 },
    //hack using riding codes to ensure this actually reruns when the codes change
    cacheBuster : _.random(1, 284973), //!!query.ridingCode && !!query.ridingCode.$in && query.ridingCode.$in.reduce( (tally, val) => tally + val, 0 ) || 0
  },
  fetchPolicy : "network-only",
  onError() {
    console.log('fuckin error')
  }
};


export const PACStatusMap = {
  "active"        : "success",
  "pending"       : "success",
  "lapsed"        : "warning",
  "uncollectable" : "danger",
  "cancelled"     : "secondary"
};


const Supporters = (props) => {

  SetPageMetas({title: 'Supporters'})

  //handles the supporters to select for merge and householding
  const [ selectedSupporters, setSelectedSupporters ] = useState([]);

  //this keeps track of the toast labels showing supporters selected
  const [labelSelectedSupporters, setLabelSelectedSupporters] = useState({});


//sets the columns to display for our supporters table
  const columns = [{
    Header: 'First Name',
    className: "text-center d-flex align-items-center",
    accessor: 'firstName'
  }, {
    Header: 'Last Name',
    className: "text-center d-flex align-items-center",
    accessor: 'lastName'

  }, {
    Header: 'PIN',
    className: "text-center",
    accessor: 'PIN'
  }, {
    Header: 'City',
    className: "text-center",
    accessor: 'city'
  },{
    Header: 'Province',
    className: "text-center",
    accessor: 'province'
  }, {
    Header: 'Mobile Phone',
    className: "text-center",
    accessor: 'mobilePhone'
  },{
    Header: 'PAC',
    className: "text-center",
    accessor: "PACStatus",
    Cell: row => {
      return <span>{!!row.original.PACStatus && <Badge pill theme={PACStatusMap[row.original.PACStatus]}>{row.original.PACStatus}</Badge>}</span>;
    }
  },{
    Header: 'Actions',
    accessor: "actions",
    maxWidth: 300,
    minWidth: 180,
    sortable: false,
    Cell: row => {
      return (
      <Fragment>

          <Link to={`/supporter/${row.original._id}?view=main`}

            className='btn btn-sm btn-white'>
            View Profile
          </Link>

          <FormCheckbox
            checked={_.contains(selectedSupporters, row.original._id)}
            className="ml-auto my-auto"
            id="isPrimary"
            onChange={ () => {
              if(!_.contains(selectedSupporters, row.original._id)) {
                setSelectedSupporters([...selectedSupporters, row.original._id ]);
                setLabelSelectedSupporters({
                  ...labelSelectedSupporters,
                  [row.original._id] : toast.info(`${row.original.firstName} ${row.original.lastName} selected `, {autoClose : false, closeButton: false, closeOnClick: false, draggable: false})})
              } else {
                setSelectedSupporters(selectedSupporters.filter(_id => _id !== row.original._id))
                toast.dismiss(labelSelectedSupporters[row.original._id]);
              }
            } }>
          </FormCheckbox>



        {/* {
          actions.includes('useForCampaign') ?
          <Button
            className='btn btn-primary'
            onClick={ e => {
              setCampaignSupporter( row.original );
            }}
          >
            Select
          </Button>
          : ""
        } */}
      </Fragment>
      )
    }
  }];

  //if a supporter is deceased, grey out the line
  const getTrProps = (state, rowInfo, instance) => {
    const deceased = !!rowInfo && !!rowInfo.original.isDeceased || null;

    if (!!rowInfo) {
      return {
        style: {
          background : (!!deceased) ? 'Gainsboro' : '',
          color      : (!!deceased) ? 'grey' : ''
        }
      }
    }
    return {};
  }


  return (
    <Fragment>
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Search" subtitle="Supporters" className="text-sm-left" />
          {/* <InlineTitleButton sm='8' href="/supporter/create" text="+ Create Supporter"/> */}
        </Row>
        {/* TODO : for when we will find a way to send the CSV*/}
        {/* <UploadFile /> */}
        <Card small className="mb-4">
          <CollectionTable
            collectionName='supporters'
            gqlQuery={gqlQuery}
            gqlQueryOpts={gqlQueryOpts}
            columns={columns}
            viewProps={props}
            getTrProps={getTrProps}
            selector={{merged: false}}
            displayButtons={["filter", "new", "upload", "export"]}
            projector={{
              _id         : 1,
              firstName   : 1,
              lastName    : 1,
              PIN         : 1,
              city        : 1,
              province    : 1,
              ridingName  : 1,
              mobilePhone : 1,
              PACStatus   : 1,
              isDeceased  : 1
            }}
            sort={{createdDate : -1}}
            exportColumns={ getSupporterColumns() }
          />
        </Card>

      </Container>

      <Fade
        in={selectedSupporters.length >= 2}
        out={(selectedSupporters.length <= 1).toString()}
      >
        <BottomActionBar show={selectedSupporters.length >= 2}>
          <Link
            to={{
              pathname : `/supporter/household`,
              state : {
                supportersId : selectedSupporters,
                view         : "houseHold"
              }
            }}
            onClick ={ (e) => {
            if(selectedSupporters.length < 2) {
              e.preventDefault()
            }}}
            className='btn btn-primary'>
            Create Household
          </Link>
          <Link
            to={{
              pathname : `/supporter/merge`,
              state : {
                supportersId : selectedSupporters,
                view         : "merge"
              }
            }}
            onClick ={ (e) => {
            if(selectedSupporters.length < 2) {
              e.preventDefault()
            }}}
            className='btn btn-primary'>
            Merge Supporters
          </Link>
        </BottomActionBar>
      </Fade>
  </Fragment>
)};

export default Supporters;
