import React, {useState, memo, Fragment} from "react";
import {Modal} from "react-bootstrap";
import { toast } from 'react-toastify';import { useQuery, useMutation } from '@apollo/react-hooks';
import SearchSelect  from "./SearchSelect.js"
import {
  Button,
  FormCheckbox,
  FormTextarea
} from "shards-react";
import gql from 'graphql-tag';
import _ from "underscore";

export const GET_CAMPAIGNS = gql`
  query getCampaigns($selector: Selector, $projector: GenericObject, $limit: Int, $skip: Int, $sort: GenericObject) {
    getCampaigns(selector: $selector, projector: $projector, limit: $limit, skip: $skip, sort: $sort){
        count
        campaigns {
          _id
          nickname
          description
          mode
          createdDate
        }
      }
    }
`;

export const UPDATE_ACTION = gql`
mutation updaAction($selector: Selector, $modifier: Modifier) {
  updateAction(selector: $selector, modifier: $modifier){
    n
    nModified
    ok
    }
  }
`;



const EditCampaign = memo(({ discrete, action }) => {
  const [showModal, setShowModal] = useState(false);
  const [campaign, setCampaign] = useState({});
  
  const setResult = (value) => setCampaign(value);

  const handleClose = () => {
    setShowModal(false);
  };

  const gqlOpts = {
    variables: {
      selector : {},
      sort     : { createdDate: -1 }
    }
  };

  const optsMutation = {
    onCompleted :  () => {
      toast.success(`Campaign updated`)

      setCampaign({});

    },
    onError : (e) => {
      for (const error of e.graphQLErrors) {
        //check if the error is within GraphQL or sent back from the server
        // in order to retirce the error message at the right place
        const errorMessage = error.extensions.response ?
        error.extensions.response.body.error.message :
        error.message;
        toast.error(errorMessage);
      }
    }
  };


  const [updateAction, { data, loading, error }] = useMutation(UPDATE_ACTION , optsMutation );

  return (
      <Fragment>
         {!!discrete && 
           <span className="btn-block" onClick={() => {setShowModal(true)}}>
             Edit Campaign
           </span>
         ||
         <Button
            size="sm"
            onClick={ () => {
              setShowModal(true);
            }}>
            Edit Campaign
          </Button>
        }
          
          <Modal
    size="md"
    // centered
    show={showModal}
    onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit / Reassign Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <h6> Please select or remove the campaign : </h6>

      <p>You can search and select for a new campaign or leave this field blank to remove the campaign attached to the transaction</p>
      <SearchSelect

        collectionName={"campaigns"}
        keyName={"nickname"}
        setResult={setResult}
        gqlQuery={GET_CAMPAIGNS}
        gqlOpts={gqlOpts}
      />

      <div className="my-1 d-flex justify-content-between">
        <Button
          onClick={() => {

            const conf = window.confirm(`Are you sure you want to edit the campaign?`);

            if ( !conf )  {
            return toast.warning(`Cancelled`);
            }

            updateAction({
              variables : {
                selector : {_id : action._id},
                modifier : {campaign}
              },
              refetchQueries : ["getActions"]
            });

            handleClose()}}
          >Continue
        </Button>
        <Button
          outline
          theme="danger"
          onClick={() => {
            toast.warning(`Cancelled`)
            handleClose()}}
          >Cancel
        </Button>
      </div>
      </Modal.Body>
      </Modal>
      </Fragment>
    
  )

});

export default EditCampaign;
