import React from "react";
import {Elements} from 'react-stripe-elements';

import CardForm from "./AddCardForm";

const AddCard = ({supporter, handleCloseCard, customerId}) => (
  <Elements>
    <CardForm
      supporter ={supporter}
      handleCloseCard={handleCloseCard}
      />
  </Elements>
);

export default AddCard;
