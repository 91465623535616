import React, { useState, Fragment } from "react";
import Loading from "./Loading";
import CommentAction from "./CommentAction";
import { 
    DatePicker,
    InputGroup,
    InputGroupAddon
} from "shards-react";
import { Modal } from "react-bootstrap";
import {
  Button
} from "shards-react";

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';

export const UPLOAD_FILE = gql`
mutation uploadFile($file: Upload, $type: String) {
  uploadFile(file: $file, type: $type) {
    filename
    mimetype
    encoding
  }
}
`;


const UploadFile = ({subscription, supporterId}) => {
  
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);

  const [file, setFile] = useState();
  console.log(file);
  const handleShowUpload = () => setShowUploadModal(true);
  const handleCloseUpload = () => setShowUploadModal(false);
  const [uploadFile, {data, loading, error}] = useMutation(UPLOAD_FILE, {
    onCompleted : ({uploadFile}) => {
      setFile();
      toast.success("File has been uploaded");
      handleCloseUpload();
    },
    onError : (e) => {
      console.log(e)
    },
    refetchQueries : ["getSupporter", "getSupporters"]
  })
  
  if(!!loading) {
    return <Loading />
  }

  if(error) {
    console.log("error when uploading the file: ", error)
    toast.error("unable to upload the file")
  }


  return (
    <Fragment>
    <Button
      className="btn-block"
      onClick = {handleShowUpload}
      >
      Upload Supporters
    </Button>
    <Modal 
      size="md"
      show={showUploadModal}
      onHide={handleCloseUpload}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Supporters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Please select when the subscription should resume.</strong></p>
        <InputGroup className="m-auto">
          <form onSubmit={(e) => {console.log("Submitted")}} encType={'multipart/form-data'}>
              <input name={'document'} type={'file'} onChange={({target: { files }}) => {
                console.log(files)
                  if(!!files.length && files[0].type === "text/csv") {
                    return setFile(files[0])
                  } 
                  toast.warning("You must select a csv file")      
              }}/>
            </form>  
             <InputGroupAddon type="append">
            <Button
              disabled = {!file}
              onClick = {() => {
                console.log("file before the mutation happens : ",file)
                uploadFile({ variables: {
                  file,
                  type : "Care2"
                  } 
                })
              }}
              >UPLOAD</Button>
            </InputGroupAddon>
        </InputGroup>
      </Modal.Body>
    
    </Modal>
    
  </Fragment>
   
  )
}

export default UploadFile;