import React, {useState, createContext} from "react";

let ChargesContext;
const {Provider, Consumer} = ChargesContext = createContext();

const ChargesProvider = (props) => {
    //this saves and sets the state of the Charges collapse
    const [active, toggleActive ] = useState(props.openOnLoad);

    return <Provider 
      value = {{
          active, 
          toggleActive
        }
      }
      >
      {props.children}
      </Provider>
    
}


export {ChargesProvider, Consumer as ChargesConsumer, ChargesContext};