import React, { useState } from 'react';
import { Link } from "react-router-dom";
import numeral from 'numeral';
import moment from 'moment';
import { compact, range, uniq, first} from 'underscore';

import { Row, Button, FormSelect, Col, Card, CardHeader, CardBody, CardFooter } from "shards-react";

import SmallStats   from "../common/SmallStats";
import ColumnChart   from "../common/ColumnChart";

import CountTotalToggle from './CountTotalToggle.js';

//TODO -- pass data from parent
const PACAnalytics = ({ data }) => {

  //save state for toggles
  const [ bankCreditGrouping, setBankCreditGrouping ] = useState('total');
  const [ gainLossGrouping  , setGainLossGrouping ] = useState('total');
  const [ lastChartUpdated  , setLastChartUpdated ] = useState(null);

  const [selectedMonth, setSelectedMonth ] = useState(0);

  const now              = new Date();
  const thisMonth        = !!data && data[0] || [];
  const lastMonth        = !!data && data[1] || [];
  const { PACData }      = thisMonth;
  const lastMonthPACData = lastMonth.PACData;

  if(!data || !data.length) {
    return <p>No Data to show</p>
  }
  
  const dropdownMonths = () => {
    return <FormSelect
     id="monthSelection"
     defaultValue={selectedMonth}
     onChange={ e => {
       setSelectedMonth(e.target.value)
     }}
  
    >
    {data.map((entry, i) => {
  
      return <option key={"" + entry.year + entry.month} value={i}>
        {`${moment("" + entry.month).format("MMMM")} ${entry.year}`}
      </option>
    })}
  
    </FormSelect>
  }

  return (
    <div>
      {/* <Row className='mb-3'>
        <Col className='mb-2'>
          <SmallStats
            id="runDate"
            variation="1"
            label="Active PAC"
            value={ numeral( lastMonthPACData.active.total / 100).format("$0,00") }
            percentage={ numeral( lastMonthPACData.active.count ).format('0,0') }
          />
        </Col>
        <Col className='mb-2'>
          <SmallStats
            id="runDate"
            variation="1"
            label="Avg. PAC"
            value={ numeral( lastMonthPACData.active.average  / 100 ).format("$0,00.00") }
          />
        </Col>
        <Col className='mb-2'>
          <SmallStats
            id="runDate"
            variation="1"
            label="Net New PAC"
            value={ numeral( (lastMonthPACData.gained.total - lastMonthPACData.cancelled.total )  / 100 ).format("$0,00") }
            percentage={ numeral( lastMonthPACData.gained.count - lastMonthPACData.cancelled.count ).format('0,0') }
          />
        </Col>
        <Col className='mb-2'>
          <SmallStats
            id="runDate"
            variation="1"
            label="Lapses"
            value={ numeral( ( PACData.lapsed.total )  / 100 ).format("$0,00") }
            percentage={ numeral( PACData.lapsed.count ).format('0,0') + " " + ( numeral( PACData.lapsed.count / ( PACData.lapsed.count + PACData.active.count ) ).format("0,0.00%") )  }
          />
        </Col>
        <Col className='mb-2'>
          <SmallStats
            id="runDate"
            variation="1"
            label="Lapses Recovered"
            value={ numeral( ( PACData.recovered.total )  / 100 ).format("$0,00") }
            percentage={ numeral( PACData.recovered.count ).format('0,0') + " " + ( numeral( PACData.recovered.count / ( PACData.lapsed.count + PACData.recovered.count) ).format("0,0.00%") ) }
          />
        </Col>
      </Row> */}
      <Row className='mb-3'>
        <Col>

          <ColumnChart
            title="Bank vs. Credit"
            height={200}
            showFilters={false}
            chartName='bankCredit'
            lastChartUpdated={ lastChartUpdated }
            filters={<CountTotalToggle activeItem={bankCreditGrouping} clickHandler={() => {
                const currentState = bankCreditGrouping;
                const nextState    = currentState == 'count' && 'total' || 'count';
                setBankCreditGrouping(nextState);
                setLastChartUpdated('bankCredit')
              }}/>}
            valueType={ bankCreditGrouping == 'total' && 'money' || 'number' }
            chartData={{
                //reverse because data is sorted by date descending and graphs are rendered the other way around.
                labels: data.map( report => report.year + "-" + report.month ).reverse(),
                datasets: [{
                label: "Bank",
                fill: "start",
                //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                data: compact(range(0, 12).map( i => {
                  //total is in cents but count is absolute
                  const divideBy = bankCreditGrouping == 'total' && 100 || 1;
                  return !!data[i] && data[i].PACData.active.bank[bankCreditGrouping] / divideBy || 0;
                })).reverse(),
                backgroundColor: "rgba(106,90,205, 1)",
                borderColor: "rgba(106,90,205, 1)",
                pointBackgroundColor: "#FFFFFF",
                pointHoverBackgroundColor: "rgba(0, 123, 255, 1)",
                borderWidth: 1.5
              },
              {
                label: "Card",
                fill: "start",
                //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                data: compact(range(0, 12).map( i => {
                  //total is in cents but count is absolute
                  const divideBy = bankCreditGrouping == 'total' && 100 || 1;
                  return !!data[i] && data[i].PACData.active.card[bankCreditGrouping] / divideBy || 0;
                })).reverse(),

                //data: compact(range(0, 12).map( i => !!data[i] && data[i].PACData.active.card[bankCreditGrouping] / 100 || 0 ) ).reverse(),
                backgroundColor: "rgba(72, 160, 255, 1)",
                borderColor: "rgba(72, 160, 255, 1)",
                pointBackgroundColor: "#FFFFFF",
                pointHoverBackgroundColor: "rgba(0, 123, 255, 1)",
                borderWidth: 1.5
              }
            ]
          }}/>
        </Col>
        <Col className='mt-sm-3 mt-md-0'>
          <ColumnChart
            title="Gains vs. Losses"
            height={200}
            chartName='gainLoss'
            showFilters={false}
            lastChartUpdated={ lastChartUpdated }
            filters={<CountTotalToggle activeItem={gainLossGrouping} clickHandler={() => {
              const currentState = gainLossGrouping;
              const nextState    = currentState == 'count' && 'total' || 'count';
              setGainLossGrouping(nextState);
              setLastChartUpdated('gainLoss');
              }}/>}
            valueType={ gainLossGrouping == 'total' && 'money' || 'number' }
            chartData={{
              labels: data.map( report => report.year + "-" + report.month ).reverse(),
              datasets: [{
                label: "Gained",
                fill: "start",
                //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                data: compact(range(0, 12).map( i => {
                  //total is in cents but count is absolute
                  const divideBy = gainLossGrouping == 'total' && 100 || 1;
                  return !!data[i] && data[i].PACData.gained[gainLossGrouping] / divideBy || 0;
                })).reverse(),
                backgroundColor: "rgba(0, 123, 255, 1)",
                borderColor: "rgba(0, 123, 255, 1)",
                pointBackgroundColor: "#FFFFFF",
                pointHoverBackgroundColor: "rgba(0, 123, 255, 1)",
                borderWidth: 1.5
              },
              {
                label: "Lost",
                fill: "start",
                //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                data: compact(range(0, 12).map( i => {
                  //total is in cents but count is absolute
                  const divideBy = gainLossGrouping == 'total' && -100 || -1;
                  return !!data[i] && data[i].PACData.cancelled[gainLossGrouping] / divideBy || 0;
                })).reverse(),
                backgroundColor: "#c4183c",
                borderColor: "#c4183c",
                pointBackgroundColor: "#FFFFFF",
                pointHoverBackgroundColor: "rgba(0, 123, 255, 1)",
                borderWidth: 1.5
              }
            ]
          }}/>
        </Col>
      </Row>
      {/* <Row className='mb-3'>
        <Col>
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Monthly PAC Retention By Cohort</h6>
            </CardHeader>
            <CardBody>
              <table className='table' size="sm" responsive="md">
                <thead>
                    <tr>
                      <th>Cohort</th>
                      { range(1, 13).map( num => {
                        return <th key={`retention-header-${num}`}>{num}</th>
                      }) }
                  </tr>
                </thead>
                <tbody>
                  { range(0, 12).map( (rowIndex, i ) => {
                    return (
                      <tr key={ `retention-body-${rowIndex}` }>
                        <td>{moment( new Date() ).subtract(rowIndex, 'months').format("MMM YYYY") }</td>
                        { range(0, 12).map( (cellIndex ) => {
                          return <td key={ `retention-cell-${cellIndex}-${i}` }>{ rowIndex >= cellIndex ? numeral( Math.random() ).format("0.0%") : "" }</td>
                        }) }
                      </tr>
                    )
                  }) }
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
      <Row className='mb-3'>
        <Col md='6'>
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Top Campaigns</h6>
              {dropdownMonths()}
            </CardHeader>
            <CardBody className='py-2'>
              <div className='table-responsive'>
                <table className='table table-hover '>
                  <thead>
                    <tr>
                      <th className='border-top-0'>Name</th>
                      <th className='border-top-0'>Type</th>
                      <th className='border-top-0'>Gains</th>
                      <th className='border-top-0'>Upgrades</th>
                    </tr>
                  </thead>
                  <tbody>
                    { first(data[selectedMonth].campaignData.sort( (a, b) => b.PAC.gained.total - a.PAC.gained.total ), 5 ).map( record => {
                        const url = record._id !== 'unlinked' && 'campaign/' + record._id || "#";
                        return (
                          <tr key={record._id}>
                            <td><Link to={url}>{record.nickname || 'unlinked'}</Link></td>
                            <td>{record.mode}</td>
                            <td>{numeral(record.PAC.gained.total / 100).format("$0,00")} ({record.PAC.gained.count})</td>
                            <td>{numeral(record.PAC.upgrades.total / 100).format("$0,00")} ({record.PAC.upgrades.count})</td>
                          </tr>
                        );
                      }) }
                  </tbody>
                </table>
              </div>
            </CardBody>
            <CardFooter className="border-top">
              <Link theme="primary" className='btn btn-primary' to="/campaign" size='sm'>View all</Link>
            </CardFooter>
          </Card>
        </Col>
        <Col md='6'>
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Top Channels</h6>
            </CardHeader>
            <CardBody className='py-2'>
              <div className='table-responsive'>
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th className='border-top-0'>Name</th>
                      <th className='border-top-0'>Gains</th>
                      <th className='border-top-0'>Upgrades</th>
                    </tr>
                  </thead>
                  <tbody>
                  { data[0].channelData.sort( (a, b) => b.PAC.gained.total - a.PAC.gained.total ).map( record => {
                      const selector = record.mode != 'unlinked' && { mode: { $in: [ record.mode ] } } || null;
                      const url = !!selector && '/campaign?selector=' + window.btoa( JSON.stringify( selector ) ) + "&skip=0" || '#';
                      return (
                        <tr key={record.mode}>
                          <td><Link to={url}>{record.mode || 'unlinked'}</Link></td>
                          <td>{numeral( record.PAC.gained.total / 100 ).format("$0,00")} ({record.PAC.gained.count})</td>
                          <td>{numeral( record.PAC.upgrades.total / 100).format("$0,00")} ({record.PAC.upgrades.count})</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
            <CardFooter className="border-top">
              <Link className='btn btn-primary' theme="primary" to='/campaign' size='sm'>View all</Link>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PACAnalytics;
