'use strict';

import gql from 'graphql-tag';

export const GET_USER = gql`
  query getSupporter($_id: ID) {
    getUser(_id: $_id) {
      _id
      firstName
      lastName
      emails {
        _id
        address
        isPrimary
      }
      roles
      gatewayRole
    }
  }
`;

export const CLEAN_SUPPORTER_REPORT = gql`
  query getCleanSupportersReport($reportName: String, $startDate: Date, $endDate: Date) {
    getCleanSupportersReport(reportName: $reportName, startDate: $startDate, endDate: $endDate) {
      count
      results
    }
  }`;
