import React from "react";
import classNames from "classnames";

const BottomActionBar = ({ children, show }) => {
  const classes = classNames({ show }, 'bottom-action-bar');
    return (
        <div className={classes} >
          <div className='action-bar-inner'>
              { children }
          </div>

        </div>

    );

}

export default BottomActionBar;
