import React, { useState, Fragment } from "react";
import ReactTable from 'react-table';
import PageTitle from "../components/common/PageTitle";
import MessagesList from "./MessagesList";
import SMSUnsubscribeButton from './SMSUnsubscribeButton';
import Loading from "./Loading";
// import MARK_AS_READ from './Messages';
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ListGroup,
  Button,
  ButtonGroup,
  FormInput,
  FormSelect
} from "shards-react";
import moment from 'moment';
import { toast } from 'react-toastify';

import {Mutation} from "react-apollo";
import gql from 'graphql-tag';

export const MARK_AS_READ = gql`
  mutation updateMessage($selector: Selector, $modifier: Modifier){
    updateMessage(selector: $selector, modifier: $modifier){
      n
      nModified
      ok
    }
  }
`;


const InboxTable = ({messages, limit, setLimit, status, setStatus}) => {


  const columns = [{
    Header: 'From',
    className: "text-center",
    // maxWidth: 100,
    accessor: 'from',
    Cell: row => {
      if(!!row.original.supporter && row.original.direction == "incoming" ) {
        return `${row.original.supporter.firstName} ${row.original.supporter.lastName}`
      }
      return row.original.from
    }
  }, {
    Header: 'Message',
    className: "text-center",
    // maxWidth: 120,
    accessor: 'message'

  }, {
    Header: 'When',
    className: "text-center",
    // maxWidth: 100,
    accessor: 'date',
    Cell: row => {
      return moment(row.original.date).fromNow();
    }
  },
  {
    Header: 'Status',
    className: "text-center",
    maxWidth: 100,
    accessor: 'status'
  },{
    Header: 'Actions',
    accessor: "actions",
    sortable: false,
    Cell: row => {
    
      const markAsRead = (row.original.status == 'unread') ?
        <Mutation
          mutation={MARK_AS_READ}
          onCompleted={({markAsRead}) => {
            toast.success(`Message marked as read`);
          }}
          onError={e => {
            for (const error of e.graphQLErrors) {
              //check if the error is within GraphQL or sent back from the server
              // in order to retirce the error message at the right place
              const errorMessage = error.extensions.response ?
              error.extensions.response.body.error.message :
              error.message;
              toast.error(errorMessage);
            }
          }}
          >
          {(markAsRead, {data, loading, error}) => {
            {/* if ( !!loading ) {
                return <Loading />
            } */}

            return(
              <Button 
                className='btn btn-white'
                onClick={()=> {
                  const conf = window.confirm("Are you sure?");
                  if( !conf ) {
                    return toast.info("Mark as read cancelled");
                  }
                markAsRead({
                  variables : {
                    selector : {
                      _id : row.original._id
                    },
                    modifier : {
                      status : "read"
                    }
                  },
                  refetchQueries : ['getInbox', 'getInboxCount']
                });
              }}>mark as read</Button>
            )
          }}

        </Mutation>
        : '';
     
        const unsubbed = (!!row.original.supporter && row.original.supporter.contactPreferences.okSMS == false || !!row.original.prospect && row.original.prospect.askedSTOP == true);
      
        const unsubButton = (!!row.original.prospect || !!row.original.supporter) ?
          <SMSUnsubscribeButton
            selector={ !!row.original.prospect && { prospectId: row.original.prospect._id } || { supporterId: row.original.supporter._id } }
            unsubbed={unsubbed}
            messageId={row.original._id}
            toNumber={row.original.from}
            fromNumber={row.original.to}
          /> : "";
      return (
      <div>
        <ButtonGroup size="sm" className="d-block mx-auto">
          {!!row.original.supporter && !!row.original.supporter._id && <Link to={`/supporter/${row.original.supporter._id}?view=sms`} className='btn btn-white'>View Profile</Link>}
          {!!row.original.prospect && !!row.original.prospect._id && <Link to={`/prospect/${row.original.prospect._id}?view=sms`} className='btn btn-white'>View Prospect</Link>}
          {markAsRead}
          {unsubButton}
         </ButtonGroup>
       </div> 
     );
    }
  }]
  
  return (
        <div className="my-2">
        <Row className="mx-1">
        <Col md="3" className="form-group">
          <label htmlFor="name">Limit</label>
          <FormSelect
            id="limit"
            value={limit}
            onChange={e => setLimit(parseInt(e.target.value))}
          > 
            <option>10</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
          </FormSelect>
        </Col>
        <Col md="3" className="form-group">
          <label htmlFor="name">Status</label>
          <FormSelect
            id="status"
            value={status}
            onChange={e => setStatus(e.target.value)}
          >
            <option></option>
            <option>read</option>
            <option>unread</option>
            <option>answered</option>
          </FormSelect>
        </Col>
        </Row>
          <ReactTable
            data={messages}
            columns={columns}
            defaultPageSize={limit}
            showPageJump={false}
            manualPagination={true}
            noDataText={`No messages to display`}
          />
        </div>
  )
};

export default InboxTable;
