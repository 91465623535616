'use strict';

import React, { useRef, useEffect } from 'react';
import Tagify from '@yaireo/tagify';
import _ from 'underscore';
import Tags from "../../Tags";

import { detectOperator } from '../util.js';


// //This is a tag component. See RidingTagSelect for use
// //needs to be provided with options in config
// //and needs onAddTag and onRemoveTag handlers
// class Tags extends React.Component{
//     constructor( props ){
//         super(props);
//         this._handleRef = this._handleRef.bind(this);
//     }

//     componentDidMount(){
//         this.tagify = new Tagify(this.component, this.props.settings || {});
//     }

//     shouldComponentUpdate(nextProps, nextState){
//         // check if value has changed
//         if( nextProps.value && nextProps.value.join() != this.props.value.join() ){
//             this.tagify.loadOriginalValues(nextProps.value);
//             // this.tagify.addTags(nextProps.value, true, true)
//         }

//         this.tagify.settings.whitelist = nextProps.settings.whitelist;

//         if( nextProps.showDropdown )
//             this.tagify.dropdown.show.call(this.tagify, nextProps.showDropdown);

//         // do not allow react to re-render since the component is modifying its own HTML
//         return false;
//     }

//     _handleRef(component){
//         this.component = component;
//     }

//     render(){
//         const attrs = {
//             ref         : this._handleRef,
//             name        : this.props.name,
//             className   : this.props.className,
//             placeholder : this.props.class,
//             autoFocus   : this.props.autofocus
//         }

//         return React.createElement(this.props.mode, Object.assign({}, attrs, {defaultValue: this.props.value || null }));
//     }
// }

// Tags.defaultProps = {
//     value: [],
//     mode: "input"
// }


//operators are $in and $nin
const QBMultiSelect = ({field, keyName, operators, operator, selector, setSelector, currentValue = [], setCurrentValue, removeFromSelector }) => {

  //need an extra local store so that callbacks have access to the most recent current value.
  const self = useRef({ currentValue, operator, selector });
  useEffect(()=>{
    self.current.tags = currentValue;
    self.current.operator = operator;
    self.current.selector = selector;
  }, [currentValue, selector])

  const onAdd = tag => {
    const val = tag.detail.data.id;
    const actualCurrentValue = self.current.tags || { [keyName]: { [self.current.operator]: [] } };
    const raw = operators[self.current.operator].rawValue( actualCurrentValue ) || [];
    const addToSelector = operators[self.current.operator].handler.apply(this, [ keyName, [ ...raw, val ] ]);
    setCurrentValue( addToSelector[keyName] );
    setSelector({ ...self.current.selector, ...addToSelector });
  }

  const onRemove = tag => {
    const val = tag.detail.data.id;
    const actualCurrentValue = self.current.tags;
    const raw = operators[self.current.operator].rawValue( actualCurrentValue );
    const addToSelector = operators[self.current.operator].handler.apply(this, [ keyName, _.without( raw, val ) ]);
    setCurrentValue( addToSelector[keyName] );
    setSelector({ ...self.current.selector, ...addToSelector });
  }

  const settings = {
    // whitelist        : _.pluck( field.options, 'label' ),
    whitelist        : field.options.map( option => { return { value: option.label, id: option.value  } } ),
    enforceWhitelist : true,
    transformTag(val) {
      return val.id;
    },
    dropdown : {
        enabled: 0,
        position: "text",
        highlightFirst: true  // automatically highlights first sugegstion item in the dropdown
    },
    callbacks : {
      add    : onAdd,
      remove : onRemove
    }
  };

  return <Tags
    autofocus={true}
    mode='textarea'
    className='form-control ml-md-2'
    name={keyName}
    settings={settings}
    placeholder='Select one or more'
    value={ !!selector[keyName] && operators[ detectOperator( selector[keyName] ) ].rawValue( selector[keyName] ).map( val => { const match = _.findWhere( field.options, { value: val } ); return !!match && match.label || '' } ) || [] }
  />
}

export default QBMultiSelect;
