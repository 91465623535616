import React, { useState, Fragment } from "react";
import { Mutation } from "react-apollo";
import {
  Card,

  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormCheckbox,
  Button,
  ButtonGroup
} from "shards-react";

import { toast } from "react-toastify";
import gql from 'graphql-tag';

export const RESET_PASSWORD = gql`
  mutation resetPassword($_id: ID, $newPassword: String) {
    resetPassword(_id: $_id, newPassword: $newPassword){
      n
      nModified
      ok
    }
  }
`;

const ResetPassword = ({user, userInStorage}) => {

  const [password, setPassword] = useState("");

  return (
    <Mutation
      mutation={RESET_PASSWORD}
      onError={(e) => {
        console.log({e})
      }}
      onCompleted={() => {
        toast.success("Password updated!");
      }}
      >
      {(resetPassword, {data, loading, error}) => {
        return (
          <Fragment>
            
          <Row form className="border-top pt-2 mt-4">
            <Col md="6" className="form-group">
              <label htmlFor="feResetPassword">Set a new password</label>
              <FormInput
                type="password"
                id="feResetPassword"
                placeholder="Password"
                value={password || '' }
                onChange={(e) => {setPassword(e.target.value)}}
              />
              
            </Col>
            </Row>
            <Button
              outline
              onClick={()=> {
                if(userInStorage._id !== user._id && userInStorage.gatewayRole !== "super-admin") {
                  return toast.warning("You don't have the rights to update this password");
                }
                if(password.length < 6) {
                  return toast.warning("Your password must contain at least 6 characters")
                }
                resetPassword({
                  variables : {
                    _id : user._id,
                    newPassword : password
                  }
                })
              }}>
              Reset Password
            </Button>
            
          </Fragment> 
        )
      }}
      
    </Mutation>
  )

};

export default ResetPassword;