import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar, NavbarBrand } from "shards-react";
import NavbarSearchResults from "./NavbarSearchResults.js";
import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";
import _ from "underscore";

import { LAYOUT_TYPES } from "../../../utils/constants";

const MainNavbar = ({ layout, stickyTop }) => {
  const isHeaderNav = layout === LAYOUT_TYPES.HEADER_NAVIGATION;
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  const [ searchTerm, setSearchTerm ] = useState(null);
  

  const user = JSON.parse(localStorage.getItem("user"));
  const hasSupporterRights = !!_.contains(user.roles.campaign, "basic") || !!_.contains(user.roles.campaign, "admin") || !!_.contains(user.roles.campaign, "super-admin") || false;
  
  if(!hasSupporterRights) {
    return (
      <div className={classes}>
      <Container fluid={!isHeaderNav || null} className="p-0">
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
          <div className="main-navbar__search w-100 d-none d-md-flex d-lg-flex" style={{'zIndex': 1051}}>

          </div>
          <NavbarNav />
          <NavbarToggle />
        </Navbar>
      </Container>
    </div>
    )
  }


  return (
    <div className={classes}>
      <Container fluid={!isHeaderNav || null} className="p-0">
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
          {/* {isHeaderNav && (
            <NavbarBrand href="#" style={{ lineHeight: "25px" }}>
              <div className="d-table m-auto">
                <img
                  id="main-logo"
                  className="d-inline-block align-top mr-1 ml-3"
                  style={{ maxWidth: "25px" }}
                  src={require("../../../images/shards-dashboards-logo.svg")}
                  alt="Shards Dashboard"
                />
                <span className="d-none d-md-inline ml-1">
                  Shards Dashboard
                </span>
              </div>
            </NavbarBrand>
          )} */}
          <NavbarSearch
            searchTerm={searchTerm}
            setSearchTerm={ setSearchTerm }
          />
          <NavbarSearchResults searchTerm={searchTerm} setSearchTerm={setSearchTerm}/>
          <NavbarNav />
          <NavbarToggle />
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
