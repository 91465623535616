
import React, { useState, Fragment } from "react";
import CommentAction from "./CommentAction";
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import {
  Card,
  CardHeader,
  CardBody,
  Col,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button
} from "shards-react";

const validator = require("validator");


export const PROCESS_CHARGE = gql`
  mutation processCharge($supporterId: String, $type: String, $amount: Int, $opts: GenericObject) {
    processCharge(supporterId: $supporterId, type: $type, amount: $amount, opts: $opts) {
      id
    }
  }
`;


const CampaignRegisterCheque = ({supporterId, campaign = null, wizardRegisterGift, setPendingAmount}) => {

  //this modal is for the optional comment
  const [showModal, setShowModal] = useState(false);

  const[cheque, setCheque ] = useState({
    chequeNumber : "",
    amount : "",
    amountInCents : ""
  })

  const opts = {
    onCompleted :  () => {
      toast.success(`$${(cheque.amountInCents / 100).toFixed(2)} donation Cheque registered succesfully`)
      if ( !!wizardRegisterGift ) {
        wizardRegisterGift({ type: 'otg', amount: cheque.amountInCents, method: 'cheque' });
      }
      setCheque({
        chequeNumber : "",
        amount : "",
        amounInCents : ""
      });

    },
    onError : (e) => {
      for (const error of e.graphQLErrors) {
        //check if the error is within GraphQL or sent back from the server
        // in order to retirce the error message at the right place
        const errorMessage = error.extensions.response ?
        error.extensions.response.body.error.message :
        error.message;
        toast.error(errorMessage);
      }
    },
    refetchQueries : ['getSupporter', 'getSupporters', 'getCampaign', 'getActions']
  };

  const [processCharge, { data, loading, error }] = useMutation(PROCESS_CHARGE , opts );
  if(error) {
    console.log({error})
  }

  const formatAmount = (amount) => {
    const regex = /^[0-9.,]+$/;

    if(!!regex.test(amount) || !amount) {
      return setCheque({...cheque, amount : amount })
    }
    return null;
  }

  return(
    <Fragment>
      <Col lg="6" sm="12" className="mb-4">
      <Card small className="go-stats mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Register a Cheque</h6>
          <div className="block-handle" />
        </CardHeader>
        <CardBody>
        <label>Please enter the cheque number (Optional) :</label>
          <FormInput
            className="mb-3"
            id="chequeNumber"
            type="text"
            value={cheque.chequeNumber}
            onChange={e => setCheque({...cheque, chequeNumber : e.target.value})}
            />
          <label>Enter the amount (Required):</label>
            <InputGroup className="mb-3">
              <InputGroupAddon type="prepend">
                <InputGroupText>$</InputGroupText>
              </InputGroupAddon>
              <FormInput
                id="amount"
                type="text"
                placeholder="00.00"
                value={cheque.amount}
                onChange={e => formatAmount(e.target.value)}
                />
            </InputGroup>
        <Button
          onClick={() => {
            const validAmount = validator.isCurrency(cheque.amount + "", {
              allow_negatives : false,
            });
            if(!validAmount) {
              toast.warning("Please enter a valid amount.");
            } else {
              setCheque({...cheque, amountInCents : Math.ceil(parseFloat(cheque.amount) *100)});
              setShowModal(true);
            }
          }}>
          Register
        </Button>
        <CommentAction
          action={`register $${cheque.amount} Cheque`}
          confirmation={true}
          mutation={processCharge}
          showModal={showModal}
          setShowModal={setShowModal}
          opts={
            {
              variables      : {
                supporterId,
                type : "cheque",
                amount         : cheque.amountInCents,
                opts           : {
                  campaign,
                  chequeNumber : !!cheque.chequeNumber && cheque.chequeNumber || null
                }}
            }
          } />
        </CardBody>
      </Card>
    </Col>
    </Fragment>
  )
}

export default CampaignRegisterCheque;
