import React, {useState, Fragment} from "react";
import CommentAction from "./CommentAction";
import { toast } from 'react-toastify';
import {
  CardBody,
  Button,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Row
} from "shards-react";
import MailTrackingCodeSelector from "./MailTrackingCodeSelector";
import validator from "validator";



const SubscriptionForm = ({subscription, mutation, supporterId, bankAccounts, existingCreditCard, campaign, setGifts, setPendingAmount, update}) => {
  //if there is a subscription passed, use the elements for the potential update
  const existingAmount = !!subscription && !!subscription.plan && (subscription.plan.amount / 100).toFixed(2) || '';
  const existingPaymentMethod = !!subscription && subscription.bankAccountId || !!subscription && "credit_card" || null;
  
  //if the campaign passed is a mail campaign, we want to show the mailTrackingCode input in the form
  const mailTrackForm = !!campaign && !!campaign.mode && campaign.mode === "house mail" || !!campaign && !!campaign.mode && campaign.mode === "prospect mail";

  const [mailTrackingCode, setMailTrackingCode] = useState("");

  const [amount, setAmount] = useState(existingAmount);

  const [showModal, setShowModal] = useState(false);
  const [bankAccountId, SetBankAccountId] = useState();
  const [subAmount, setSubAmount] = useState(0);

  const[submitting, setSubmitting] = useState(false);


  //if it's an update, show the actual payment method or if there is an existing card on the account, preselect it
  const [paymentMethod, setPaymentMethod] = useState(!!existingPaymentMethod && existingPaymentMethod || !!existingCreditCard && "credit_card" || !!bankAccounts[0]._id && bankAccounts[0]._id)

  const opts = {}
  if(!!campaign) {
    opts.campaign = campaign
  }
  if(!!update) {
    opts.update = update;
  }
  

  const cardMethod = !!existingCreditCard || existingPaymentMethod == "credit_card" ?
    <option
      value={"credit_card"}>
      Credit Card - Primary
    </option>
    : "";

  const bankAccountSelection = !!bankAccounts && bankAccounts.map((account, i) => {
    return (
      <option
        key={i}
        value={account._id}>
          {`Bank Account ${account.bank} ****${account.last4}`}
        </option>
    )
  })

  const [comment, setComment] = useState('')

  const handleClose = () => {
    setShowModal(false)
  };

  //only show a choice if there are bankAccounts attached to the supporter
  //by default it will be credit card
  const displayPaymentMethods = !!bankAccounts.length ?
    <Fragment>
      <label>Please, select the payment method to use :</label>
      <FormSelect
        className={"mb-4"}
        id={"paymentMethods"}
        defaultValue={paymentMethod}
        onChange={(e) => {
          setPaymentMethod(e.target.value)
        }}
      >
      {cardMethod}
      {bankAccountSelection}
      </FormSelect>
    </Fragment>
      : "";

  
  const displayMailTrackForm = !!mailTrackForm && <Row>
    <MailTrackingCodeSelector
      campaign={campaign}
      mailTrackingCode={mailTrackingCode}
      setMailTrackingCode={setMailTrackingCode}
    />
    </Row>
 || "";

  return (

    <CardBody>
    <p>Monthly donations are charged on the Primary card. If the supporter has multiple cards, please make sure that the correct one is setup as Primary</p>
    <hr/>
    {displayMailTrackForm}
    {displayPaymentMethods}
    <label>Enter the amount in dollars (only use numbers) :</label>
      <InputGroup className="mb-3">
        <InputGroupAddon type="prepend">
          <InputGroupText>$</InputGroupText>
        </InputGroupAddon>

        <FormInput
          value={amount}
          type="text"
          placeholder="00.00"
          onChange={e => {
            let amountToPay = e.target.value;
            if(!!setPendingAmount) {
              setPendingAmount(!!amountToPay);
            }
            setAmount(amountToPay);

          }}
          />
        <InputGroupAddon type="append">
          <Button
          disabled={ !!submitting }
          onClick={() => {

            if(!!mailTrackForm && !mailTrackingCode.length) {
              const conf = window.confirm("You did not enter a Mail Tracking Code, are you sure you want to continue and register this PAC?");

              if ( !conf )  {
                return null;
              }
            }
            const validAmount = validator.isCurrency(amount + "", {
              allow_negatives : false,
            })
            if( !validAmount ) {
              toast.error("Please enter a valid amount.");
              setAmount('');
            } else {
              if(!!setGifts) {
                setGifts([{
                amount : Math.ceil(parseFloat(amount) *100),
                type   : "monthly"
              }])
              }
              setSubmitting(true);
              //convert amount in cents for sub
              setSubAmount(Math.ceil(parseFloat(amount) *100));
              if(paymentMethod !== "credit_card") {
                SetBankAccountId(paymentMethod);
              }
              setShowModal(true);
            }
          }
          }>
          {!!subscription && "Update" || "Subscribe"}</Button>
        </InputGroupAddon>
      </InputGroup>
      <CommentAction
        action={"subscribe"}
        confirmation={false}
        mutation={mutation}

        showModal={showModal}
        cancelAction={() => setSubmitting(false)}
        // waitingMessage={`Please wait while the system registers the monthly donation`}
        setShowModal={setShowModal}
        opts={{
          variables      : {
            supporterId,
            subscriptionId : !!subscription && subscription.id && subscription.id || null,
            amount       : subAmount,
            bankAccountId,
            type : !!subscription && !!subscription.bankAccountId && "bank_account" || null,
            opts : !!mailTrackingCode.length && {...opts, mailTrackingCode} || opts
          }
        }}/>
    </CardBody>

)

}

export default SubscriptionForm;
