'use strict';

import React from 'react';
import _ from 'underscore';
import moment from 'moment';
import { DatePicker } from "shards-react";
import { computeMinMax, detectOperator } from '../util.js';

//DATE CAN BE USED WITH BETWEEN
//SO USE THE BETWEEN CHANGE HANDLER AND REMEMBER THAT HTE VALUE MUST BE new Date(val)

const QBDate = ({field, keyName, operators, operator, selector, setSelector, currentValue, setCurrentValue, removeFromSelector, isMinVal, isMaxVal }) => {

  //set the initial value
  const setValue = () => {
    if ( !!selector[keyName] ) {
      const raw = operators[ operator ].rawValue( selector[keyName] );
      if ( operator !== 'between') {
        return moment(raw).toDate();
      } else if ( !!isMinVal ) {
        return currentValue["$gte"] && new Date(currentValue["$gte"]) || '';
      } else if ( !!isMaxVal ) {
        return currentValue["$lte"] && new Date(currentValue["$lte"]) || '';
      }
    }

    //nothing is default
    return '';
  }

  return (<DatePicker
      size="sm"
      selected={ setValue() }
      maxDate={ moment( new Date() ).endOf('day').toDate() }
      onChange={ (val, e) => {
        if ( operator !== 'between' && !val ) {
          return removeFromSelector();
        }

        //compute new value, either a single value or array pair
        const newVal = operator === 'between' && computeMinMax( currentValue, new Date(val), isMinVal, isMaxVal ) || new Date(val);

        //remove from selector if both values are unset
        if ( operator == 'between' && _.isNull( newVal[0] ) && _.isNull( newVal[1] ) ) {
          return removeFromSelector();
        }

        //format properly given the operator
        let argsToPass = [keyName];

        //if min and mix, we pass them in order
        if ( _.isArray(newVal) ) {
          argsToPass = [ keyName, ...newVal ]; //[ keyName, minVal, maxVal ]
        } else {
          //otherwise pass a single val
          argsToPass.push( newVal ); // [ keyName, val ]
        }

        const addToSelector = operators[operator].handler.apply(this, argsToPass);
        //set the current value locally, without the operator
        setCurrentValue( addToSelector[keyName] );
        //modify top level selector
        setSelector({ ...selector, ...addToSelector});
      }}
      placeholderText="Date"
      dropdownMode="select"
      className="text-center"
      showYearDropdown={true}
    />);
}

export default QBDate;
