import React from 'react';
import {Form, Col} from 'react-bootstrap';



const TemplateBox = ({messageTemplate}) => {

    return (
      <Col>
        <Form.Label>Message rendered</Form.Label>
        <Form.Control disabled as="textarea" value={messageTemplate} />  
      </Col>
  
    );

}

export default TemplateBox;