import React, { useState, Fragment } from "react";
import Loading from "./Loading";
import CommentAction from "./CommentAction";
import { 
    DatePicker,
    InputGroup,
    InputGroupAddon
} from "shards-react";
import { Modal } from "react-bootstrap";
import {
  Button
} from "shards-react";

import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { toast } from 'react-toastify';

export const PAUSE_SUBSCRIPTION = gql`
  mutation pauseSubscription($subscriptionId: String, $modifier: GenericObject, $supporterId: String, $amount: Int, $opts: GenericObject) {
    pauseSubscription(subscriptionId : $subscriptionId, amount: $amount, supporterId : $supporterId, modifier: $modifier, opts: $opts){
      id
      status
      plan {
        id
        nickname
      }
    }
  }
`;


const PauseSubscription = ({subscription, supporterId}) => {
  console.log({subscription})
  //setup the variables 

  const [resumeDate, setResumeDate] = useState(Date.now());

  const [showPauseModal, setShowPauseModal] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);

  const handleShowPause = () => setShowPauseModal(true);
  const handleClosePause = () => setShowPauseModal(false);

  const [pauseSubscription, {data, loading, error}] = useMutation(PAUSE_SUBSCRIPTION, {
    onCompleted : ({pauseSubscription}) => {
      toast.success("Subscription has been successfully updated")
      handleClosePause();
    },
    refetchQueries : ["getSupporter", "getSupporters"]
  })
  
  if(!!loading) {
    return <Loading />
  }

  if(error) {
    console.log("error in pause subscription : ", error)
    toast.error("unable to pause subscription")
  }

  //converts the date in unix 
  const convertDate = () => {
    let unixDate = Math.floor(new Date(resumeDate).getTime() / 1000);
    return unixDate;
  }


  const setPauseOpts = () => {
    if(subscription.status !== "paused") {
      return {
        variables : {
          supporterId,
          subscriptionId : subscription.id,
          amount : !!subscription.plan && subscription.plan.amount || null,
          opts : {},
          modifier : {
            pause_collection : {
              behavior : "void",
              resumes_at : convertDate()
            }
            
          }  
        }
      };
    }
    return {
      variables : {
        supporterId,
        subscriptionId : subscription.id,
        amount : !!subscription.plan && subscription.plan.amount || null,
        opts : {},
        modifier : {
          pause_collection : ""
        }  
      }
    };
    
  };
  

  return (
    <Fragment>
    <a
      className="ml-2"
      style={{cursor : "pointer", color : "#2A82E6", paddingTop: "11px"}}
      onClick={()=> {
        if(subscription.status !== "paused") {
          handleShowPause()
        } else {
          setShowCommentModal(true)
        }
      }}>
      {subscription.status === "paused" && "Resume" || "Pause"}
    </a>
    <Modal 
      size="md"
      show={showPauseModal}
      onHide={handleClosePause}>
      <Modal.Header closeButton>
        <Modal.Title>{subscription.status === "paused" && "Resume subscription" || "Pause subscription"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p><strong>Please select when the subscription should resume.</strong></p>
        <InputGroup className="m-auto">
            <DatePicker
                size="sm"
                selected={resumeDate}
                onChange={setResumeDate}
                placeholderText="Resume Date"
                dropdownMode="select"
                className="text-center"
                minDate={ Date.now() }
                // showYearDropdown = { this.props.showYearDropdown }
                // showMonthYearPicker = { this.props.showMonthYearPicker }
                />
             <InputGroupAddon type="append">
            <Button
              onClick={() => setShowCommentModal(true)}
              >{subscription.status === "paused" && "Resume subscription" || "Pause subscription"}</Button>
            </InputGroupAddon>
        </InputGroup>
      </Modal.Body>
    
    </Modal>
    <CommentAction
        action={subscription.status === "paused" && "Resume subscription" || "Pause subscription"}
        confirmation={true}
        mutation={pauseSubscription}
        showModal={showCommentModal}
        setShowModal={setShowCommentModal}
        opts={setPauseOpts()} />
  </Fragment>
   
  )
}

export default PauseSubscription;