import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { compact, range, first } from 'underscore';
import numeral from 'numeral'

import ActivePACWidget from './sharedWidgets/ActivePAC';

import { Card, CardHeader, CardBody, CardFooter, Row, Col } from 'shards-react';

import ColumnChart   from "../common/ColumnChart";
import CountTotalToggle from './CountTotalToggle.js';

function calculateCampaignTotal(campaignData) {
  const annualizedPAC = ( campaignData.PAC.gained.total + campaignData.PAC.upgrades.total - campaignData.PAC.downgrades.total ) * 12;
  const OTGTotal = campaignData.OTG.cheque.total + campaignData.OTG.card.total;
  return ( annualizedPAC + OTGTotal ) / 100;
}

function calculateCampaignCount(campaignData) {
  const PACCount = campaignData.PAC.gained.count + campaignData.PAC.upgrades.count - campaignData.PAC.downgrades.count;
  const OTGCount = campaignData.OTG.cheque.count + campaignData.OTG.card.count;
  return PACCount + OTGCount;
}

const AnalyticsOverview = ({ data }) => {

  const [ revenueSummaryGrouping, setRevenueSummaryGrouping ] = useState('total');
  const [ lastChartUpdated  , setLastChartUpdated ] = useState(null);
  if(!data || !data.length) {
    return <p>No Data to show</p>
  }

  // data = data.reverse();
  return (
    <div className='mb-4'>
      {/* <Row>
        <Col className="col-lg mb-4" md="6" sm="6">
          <ActivePACWidget />
        </Col>
      </Row> */}

      <Row>
        <Col>
          <ColumnChart
            title="Revenue Summary"
            height={200}
            showFilters={false}
            chartName='allRevenue'
            valueType={ revenueSummaryGrouping == 'total' && 'money' || 'number' }
            filters={<CountTotalToggle activeItem={revenueSummaryGrouping} clickHandler={() => {
                const currentState = revenueSummaryGrouping;
                const nextState    = currentState == 'count' && 'total' || 'count';
                setRevenueSummaryGrouping(nextState);
                setLastChartUpdated('allRevenue');
              }}/>}
            lastChartUpdated = { lastChartUpdated }
            chartData={{
                //reverse because data is sorted by date descending and graphs are rendered the other way around.
                labels: data.map( report => report.year + "-" + report.month ).reverse(),
                datasets: [{
                  label: "PAC",
                  fill: "start",
                  //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                  data: compact(range(0, 12).map( i => {
                    const divideBy = revenueSummaryGrouping == 'total' && 100 || 1;
                    return !!data[i] && data[i].PACData.active[revenueSummaryGrouping] / divideBy || 0;
                  })).reverse(),
                  backgroundColor: "rgba(0, 123, 255, 1)",
                  borderColor: "rgba(0, 123, 255, 1)",
                  pointBackgroundColor: "#FFFFFF",
                  pointHoverBackgroundColor: "rgba(0, 123, 255, 1)",
                  borderWidth: 1.5
                },
              {
                label: "OTG",
                fill: "start",
                //get data for each month, remove null values and then reverse because data is sorted by date descending and graphs are rendered the other way around.
                data: compact(range(0, 12).map( i => {
                  const divideBy     = revenueSummaryGrouping == 'total' && 100 || 1;
                  const campaignData = !!data[i] && data[i].campaignData || [];
                  return campaignData.reduce( (tally, val) => { return tally + val.OTG.cheque[revenueSummaryGrouping] + val.OTG.card[revenueSummaryGrouping] }, 0) / divideBy;
                })).reverse(),

                backgroundColor: "rgba(78, 205, 196, 1)",
                // backgroundColor: "rgba(72, 160, 255, 1)",
                borderColor: "rgba(78, 205, 196, 1)",
                pointBackgroundColor: "#FFFFFF",
                pointHoverBackgroundColor: "rgba(72, 160, 255, 1)",
                borderWidth: 1.5
              }
            ]
          }}/>
        </Col>
        <Col>
          <Card>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Top Campaigns This Month</h6>
            </CardHeader>
            <CardBody className='py-2'>
              <div className='table-responsive'>
                <table className='table table-hover '>
                  <thead>
                    <tr>
                      <th className='border-top-0'>Name</th>
                      <th className='border-top-0'>Type</th>
                      <th className='border-top-0'>PAC</th>
                      <th className='border-top-0'>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    { first(data[0].campaignData.sort( (a, b) => {
                       return calculateCampaignTotal(b) - calculateCampaignTotal(a)
                     } ), 5 ).map( record => {
                        const url = record._id !== 'unlinked' && 'campaign/' + record._id || "#";
                        return (
                          <tr key={record._id}>
                            <td><Link to={url}>{record.nickname || 'unlinked'}</Link></td>
                            <td>{record.mode}</td>
                            <td>{numeral((record.PAC.gained.total + record.PAC.upgrades.total - record.PAC.downgrades.total ) / 100).format("$0,00")} ({record.PAC.gained.count + record.PAC.upgrades.count - record.PAC.downgrades.count})</td>
                            <td>{numeral(calculateCampaignTotal(record)).format("$0,00")} ({calculateCampaignCount(record)})</td>
                          </tr>
                        );
                      }) }
                  </tbody>
                </table>
              </div>
            </CardBody>
            <CardFooter className="border-top">
              <Link theme="primary" className='btn btn-primary' to="/campaign" size='sm'>View all</Link>
            </CardFooter>
          </Card>
        </Col>
      </Row>
  </div>
  )

}

export default AnalyticsOverview;
