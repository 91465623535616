import React, { useState } from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";
import { DebounceInput } from 'react-debounce-input';
import './NavbarSearch.scss';

import classNames from 'classnames';

export default ({ searchTerm, setSearchTerm }) => {

  const searchCloseClasses = classNames(
    {'d-none': !searchTerm },
    "material-icons",
    'close-search'
  );

  //stash value locally so it can be used on submit if nothing new is entered
  const [ localSearchVal, setLocalSearchVal ] = useState(null);
  return (
  <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex" style={{'zIndex': 1051}} onSubmit={(e) => {
    setSearchTerm(localSearchVal);
    e.preventDefault();
    return false;
  }}>
    <InputGroup seamless className="ml-3">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">search</i>
        </InputGroupText>
      </InputGroupAddon>

      <DebounceInput
          element={ FormInput }
          className="navbar-search"
          placeholder="Search for supporters..."

          //ensure that search is triggered on clicking enter
          //Helpful for restarting a search after already closed.
          forceNotifyByEnter={true}

          //don't submit request if input.length < 3
          minLength={3}

          //don't submit search request more than every 300ms
          debounceTimeout={300}

          // Clear input on escape
          onKeyDown={(e) => {
            if ( e.keyCode == 27 ) {
              setSearchTerm(null);
            }
          }}

          onChange={(e) => {
            setLocalSearchVal( e.target.value );
            setSearchTerm( e.target.value );
          }}
        />
      <InputGroupAddon type="append">
        <InputGroupText>
          <i className={searchCloseClasses} onClick={ (e) => {
            setSearchTerm(null);
          }}>close</i>
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  </Form>
)};
