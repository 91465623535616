import gql from 'graphql-tag';

import {
  SUPPORTER_DETAILS,
  CUSTOMER_INFO,
  CHARGES,
  BANK_ACCOUNT
} from "../../fragments/graphqlFragments";


export const GET_SUPPORTERS = gql`
  query getSupporters($selector: Selector, $limit: Int) {
    getSupporters(selector: $selector, limit: $limit) {
      supporters {
        ...SupporterDetails
      }
    }
  }
  ${SUPPORTER_DETAILS}
`;

export const GET_SUPPORTER = gql`
  query getSupporter($supporterId: String, $chargeLimit: Int, $actionSelector: Selector, $actionLimit: Int, $actionSkip: Int, $actionSort: GenericObject) {
    getSupporter(supporterId: $supporterId) {
      ...SupporterDetails
      householdMembers {
        _id
        firstName
        lastName
      }
      actions(actionSelector: $actionSelector, actionSkip: $actionSkip, actionLimit : $actionLimit, actionSort : $actionSort) {
        count
        actions {
          _id
          date
          supporterId
          prospectId
          note
          comment
          service
          campaign
          data
          type
          user
          description
          method
        }
      }
      customerInfo {
        ...CustomerInfo
        charges(supporterId: $supporterId, limit: $chargeLimit) {
          ...Charges
        }
      }
      bankAccounts {
        ...BankAccounts
      }
    }
  }
  ${SUPPORTER_DETAILS}
  ${CUSTOMER_INFO}
  ${BANK_ACCOUNT}
  ${CHARGES}
`;

export const GET_SUPPORTER_PAYMENT = gql`
  query getSupporter($supporterId: String) {
    getSupporter(supporterId: $supporterId) {
      _id
      firstName
      lastName
      address1
      address2
      city
      province
      postalCode
      PIN
      customerInfo {
        ...CustomerInfo
        charges(supporterId: $supporterId) {
          ...Charges
        }
      }
      bankAccounts {
        ...BankAccounts
      }
    }
  }
  ${CUSTOMER_INFO}
  ${BANK_ACCOUNT}
  ${CHARGES}
`;

export const GET_SUPPORTER_PAYMENT_LIGHT = gql`
  query getSupporter($supporterId: String) {
    getSupporter(supporterId: $supporterId) {
      _id
      firstName
      lastName
      address1
      address2
      city
      province
      postalCode
      PIN
      customerInfo {
        ...CustomerInfo
        charges(supporterId: $supporterId) {
          ...Charges
        }
        
      }
    }
  }
  ${CUSTOMER_INFO}
  ${CHARGES}
`;
