import React, { useState, Fragment } from "react";

import { toast } from 'react-toastify';
import { useMutation } from '@apollo/react-hooks';
import {
  Button
} from "shards-react";
import useToast from "../hooks/use-toast";

import gql from 'graphql-tag';


export const RETRY_SUBSCRIPTION = gql`
  mutation retrySubscription($invoiceId: String, $supporterId: String, $opts: GenericObject) {
    retrySubscription(invoiceId : $invoiceId, supporterId : $supporterId, opts: $opts){
      id
      status
    }
  }
`;

const RetryInvoice = ({ discrete, supporterId, invoiceId }) => {
  
  const { loadingToast, completeToast, errorToast} = useToast();
  const [retry, {data, error, loading}] = useMutation(RETRY_SUBSCRIPTION, {
    onCompleted : ({retrySubscription}) => {

      completeToast("Payment for subscription resubmitted");
    },
    onError : e => {

      for (const error of e.graphQLErrors) {
        //check if the error is within GraphQL or sent back from the server
        // in order to retirce the error message at the right place
        const errorMessage = error.extensions.response ?
        error.extensions.response.body.error.message :
        error.message;
        errorToast(errorMessage);
      }
    },
    refetchQueries : ['getSupporter', 'getActions']
  });
  const [opts, setOpts] = useState({});

  if(!!discrete) {
    return (<span 
            onClick={() => {
              retry({
                variables : {
                  supporterId,
                  invoiceId,
                  opts : {}
                }
              })
        }}>retry</span>);
  }
  return(
      <Button
        className="btn-block"
        onClick={() => {
          loadingToast("Retrying the subscription...");
          retry({
            variables : {
              supporterId,
              invoiceId,
              opts : {}
            }
          })
        }}>
        retry
      </Button>
  )

}

export default RetryInvoice;
