import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button } from "shards-react"
import { Col } from "shards-react";
import { Link } from "react-router-dom";

const InlineTitleButton = ({ href, text, sm, className, disabled, onClick,  ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "mb-sm-0",
    'mt-3',
    'mt-sm-1',
    'align-bottom',
  );
  if(!!onClick) {
    return (
      <Col xs="12" sm={sm} className={classes} { ...attrs }>
        <Button disabled={!!disabled} className='btn btn-primary float-sm-right' onClick={onClick}>{text}</Button>
      </Col>
    )
  }

  return (
    <Col xs="12" sm={sm} className={classes} { ...attrs }>
      <Link className='btn btn-primary float-sm-right' to={href}>{text}</Link>
    </Col>
  )
};

export default InlineTitleButton;
