const getSupporterColumns = [
  { key: 'PIN', header: 'PIN' },
  { key: 'firstName', header: 'First Name' },
  { key: 'lastName', header: 'Last Name' },
  { key: 'address1', header: 'Adddress 1' },
  { key: 'address2', header: 'Adddress 2' },
  { key: 'city', header: 'City' },
  { key: 'province', header: 'Province' },
  { key: 'postalCode', header: 'Postal Code' },
  { key: 'emails.0.address', header: 'Primary Email' },
  { key: 'homePhone', header: 'Home Phone' },
  { key: 'mobilePhone', header: 'Mobile Phone' },
  { key: 'PACStatus', header: 'PAC Status' },
  { key: 'lastSuccessPAC', header: 'Last PAC Success'},
  { key: 'PACAmount', header: 'Last PAC Amount'},
  { key: 'createdDate', header: 'Created' },
  { key: 'lastUpdated', header: 'Last Updated' },
  { key: 'isDeceased', header: 'Deceased'}
];


export default() => getSupporterColumns;
